// Core Imports
import React, { useState, useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import GridItem from '../components/Grid/GridItem.js'
import GridContainer from '../components/Grid/GridContainer.js'
// Custom Component Imports
import Layout from '../core/Layout'

import { getRanking } from '../services/APICalls'
import { useTranslation } from 'react-i18next'

// TODO: Explanation
const CryptocurrenciesDetail = () => {
  const { t } = useTranslation()
  // URL Parameters
  const { idCrypto } = useParams()
  const [Crypto, setCryptoValues] = useState({})

  //funcion que va a traer los detalles
  const getDetailsRanking = async () => {
    try {
      // en lo que la promesa se resuelve (respuesta de la api) saco del arreglo
      const info = await getRanking()
      const coins = info?.dataValue?.data?.coins
      const selectedCoin = coins.find(coin => coin?.uuid === idCrypto)
      setCryptoValues(selectedCoin)
      console.log('🚀 ~ file: CryptocurrenciesDetail.js:25 ~ getDetailsRanking ~ selectedCoin', selectedCoin)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (idCrypto !== undefined) {
      getDetailsRanking()
    }
  }, [])

  // TODO: Is it printingTypeId hard-coded?
  return (
    <Layout
      title={t('cryptocurrencies.detail.title')}
      description={t('cryptocurrencies.detail.title')}
      showBreadcrumb={true}
      currentPage={t('cryptocurrencies.detail.title')}>
      <Fragment>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              {Crypto?.iconUrl && <img style={{ width: '45px', height: '45px' }} src={Crypto?.iconUrl} />}
              <Typography variant="h5">{`${t('cryptocurrencies.detail.name')}: ${Crypto?.name}`}</Typography>
              <Typography variant="h5">{`${t('cryptocurrencies.detail.position')}: #${Crypto?.rank}`}</Typography>
              <Typography variant="h5">{`${t('cryptocurrencies.detail.symbol')}: ${Crypto?.symbol}`}</Typography>
              <Typography variant="h5">{`${t('cryptocurrencies.detail.price')}: ${Crypto?.price}`}</Typography>
            </GridItem>
          </GridContainer>
        </Box>
      </Fragment>
    </Layout>
  )
}

export default CryptocurrenciesDetail
