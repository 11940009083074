import React from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '.'

export const LoadingWrapped = ({ children }) => {
  const { isLoading, loadingMsg } = useSelector(state => state.ui)

  return (
    <>
      {isLoading && <Loading msg={loadingMsg} />}
      {children}
    </>
  )
}
