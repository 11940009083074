import { createTheme } from '@mui/material/styles'
import { lightGreen, amber } from '@mui/material/colors'

import { esES as pickersesES } from '@mui/x-date-pickers'
import { esES as coreesES } from '@mui/material/locale'

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: lightGreen[700],
        light: lightGreen[400],
        dark: lightGreen[800]
      },
      secondary: {
        main: amber[700],
        light: amber[400],
        dark: amber[800]
      }
    }
  },
  // esES, // x-data-grid translations
  pickersesES, // x-date-pickers translations
  coreesES
)
