import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTranslation } from 'react-i18next'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white", // Color
  border: "2px solid gray",
  borderRadius: 4,
  boxShadow: 24,
  p: 4
};

const HelperModal = ({ pictureName, texBeforeButton, title, description, iconFigureBootstrap, variant }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation()

  return (
    <>
      {/*<Button onClick={handleOpen}>Open modal</Button>*/}
      {texBeforeButton.length > 1 ? `${texBeforeButton} ` : ''}
      <Button title={t('helperModal.title')} 
      variant={variant} 
      onClick={handleOpen}      
      >{<HelpOutlineOutlinedIcon/>}
      </Button>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ backdropFilter: "blur(5px)" }}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <img
              style={{ width: '35px', height: '35px' }}
              src={window.location.origin + '/images/helpermodal/' + pictureName + '.png'}
              alt="loading"
            />
            &nbsp;
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {description}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}


export default HelperModal;