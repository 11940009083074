import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Chip, Grid, TextField, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TableContainer from '@mui/material/TableContainer'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'

//Icons Imports
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// Custom Component Imports
import Layout from '../../core/Layout'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

// API Impports
import { getCapabilitiesAPIList, deleteCapabilities } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Container } from '@mui/system'

const AdminCapabilities = () => {
  //Initial States
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let history = useHistory()
  const [data, setData] = useState([])
  const [detailsCapabilities, setDetailsCapabilities] = useState({})
  const [open, setOpen] = React.useState(false)

  const { register, watch } = useForm()

  const handleOpenDeleteModal = Capabilities => {
    setDetailsCapabilities(Capabilities)
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const openModalDeleteCapabilities = async detailsCapabilities => {
    const { id: IdCapabilities } = detailsCapabilities
    await deleteCapabilities(IdCapabilities)
    handleCloseDeleteModal()
    getCapabilitiesList()
  }

  const getCapabilitiesList = () => {
    dispatch(showSpinner('Consultando Lista de Capacidades'))

    getCapabilitiesAPIList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          // if (data?.status >= 400 || data?.status <= 599) {
          //   //TODO
          //   //useEffect(() => {
          //   //  changeToastInfo({ msg: data.message, severity: 'info' })
          //   //}, [])
          // }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const filteredCapabilities = useMemo(() => {
    return watch('searchInput')?.trim() === ''
      ? data
      : data?.filter(
          capability =>
            capability?.description &&
            capability?.description?.toUpperCase()?.includes(watch('searchInput')?.toUpperCase()),
        )
  }, [data, watch('searchInput')])

  const columns = [
    {
      field: 'description',
      headerName: t('capability.table.description'),
      width: 130,
      flex: 1,
      renderCell: params => <>{params.value}</>,
    },
    {
      field: 'active',
      headerName: t('capability.table.status'),
      width: 130,
      flex: 1,
      renderCell: params => (
        <>
          {params?.value ? (
            <Chip label={t('capability.table.activeStatus')} color="success" variant="outlined" />
          ) : (
            <Chip label={t('capability.table.inactiveStatus')} color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: t('capability.table.actions'),
      width: 130,
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title={t('capability.table.editButton')} arrow>
                <IconButton
                  aria-label={t('capability.table.editButton')}
                  onClick={() => goToPageCapabilitiesDetails(params.row.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={t('capability.table.deleteButton')}
                arrow
                onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton aria-label={t('capability.table.deleteButton')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const goToPageCapabilitiesDetails = idCapabilities => {
    history.push(`/admin/capabilities/${idCapabilities}`)
  }

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getCapabilitiesList()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
      title={t('capability.title')}
      description={t('capability.title')}
      showBreadcrumb={true}
      currentPage={t('capability.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Container maxWidth="lg">
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
              <Tooltip title={t('capability.back')} arrow>
                <IconButton aria-label="back icon" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{t('capability.subtitle')}</Typography>
              <Tooltip title={t('capability.add')} arrow>
                <IconButton
                  aria-label="add icon"
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/capabilities/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction="row" py="1rem">
              <TextField
                focused={watch('searchInput') !== ''}
                {...register('searchInput')}
                placeholder={t('capability.searchButton')}
                type="text"
                variant="standard"
                sx={{ width: '100%', maxWidth: { xs: '100%', md: '30vw', lg: '20vw' } }}
              />
            </Stack>
            <TableContainer component={Paper}>
              <DataGrid
                rows={filteredCapabilities}
                columns={columns}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5, page: 0, pageCount: 1 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                getRowId={row => row?.id}
                autoHeight
              />
            </TableContainer>
          </Grid>
        </Container>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{t('capability.deleteModal.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('capability.deleteModal.text')}
            <br />
            {`${detailsCapabilities?.description}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>{t('capability.deleteModal.cancelButton')}</Button>
          <Button variant="contained" onClick={() => openModalDeleteCapabilities(detailsCapabilities)}>
            {t('capability.deleteModal.saveButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminCapabilities
