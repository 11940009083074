import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import Layout from '../../core/Layout'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

import { getCriptoCurrency, createCriptoCurrency, updateCriptoCurrency } from '../../services/APICalls'

const initValues = {
  id: '',
  acronym: '',
  name: '',
  algorithm: '',
  active: '',
}

const FormCriptoCurrency = () => {  
   // Initial State
   const dispatch = useDispatch()
   const history = useHistory()
  //params que vienen por la url
  const paramsCriptoCurrencyForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const getDetailsCriptoCurrency = async () => {
    dispatch(showSpinner('Obteniendo Información de Criptomoneda'))
    try {
      const { 0: criptocurrency } = await getCriptoCurrency(paramsCriptoCurrencyForm?.idCriptoCurrency)
      setDefaultValues(criptocurrency)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.log(error)
      dispatch(hiddeSpinner())
    }
  }

  const onSubmitForm = criptocurrency => {
    if (paramsCriptoCurrencyForm?.idCriptoCurrency !== undefined) {
      SendUpdateCriptoCurrency(criptocurrency)
      goToCriptoCurrencyList()
      return
    }
    sendCreateCriptoCurrency(criptocurrency)
    goToCriptoCurrencyList()
    return
  }

  const SendUpdateCriptoCurrency = async criptocurrency => {
    try {
      dispatch(showSpinner('Actualizando Información de Criptomoneda'))
      await updateCriptoCurrency(criptocurrency.id, criptocurrency)
      dispatch(hiddeSpinner())
      return
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const sendCreateCriptoCurrency = async criptocurrency => {
    try {
      dispatch(showSpinner('Registrando Información de Criptomoneda'))
      await createCriptoCurrency(criptocurrency)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToCriptoCurrencyList = () => {
    history.push('/admin/criptocurrency')
  }

  useEffect(() => {
    //en caso de que exista un id, se pone en modo editar
    if (paramsCriptoCurrencyForm?.idCriptoCurrency !== undefined) {
      getDetailsCriptoCurrency()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsCriptoCurrencyForm?.idCriptoCurrency !== undefined ? 'EDICION' : 'REGISTRO'} DE CRIPTOMONEDA`}
      description={`${
        paramsCriptoCurrencyForm?.idCriptoCurrency !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de CriptoMoneda`}
      showBreadcrumb={true}
      currentPage={`${
        paramsCriptoCurrencyForm?.idCriptoCurrency !== undefined ? 'EDICION' : 'REGISTRO'
      } DE CRIPTOMONEDA`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton title="Volver" aria-label="volver" onClick={() => goToCriptoCurrencyList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      active: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormCriptoCurrency