// Core Imports
import React, { useState, useEffect } from 'react'

//MUI AND TEMPLATE
import { Box, Typography } from '@mui/material'
import GridContainer from '../components/Grid/GridContainer.js'
import GridItem from '../components/Grid/GridItem.js'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeIcon from '@mui/icons-material/Home'
import { useParams } from 'react-router-dom'

import TransactionsDataTable from './TransactionsDataTable'

// Custom Component Imports
import Layout from '../core/Layout'

// Function Imports
import { authenticate, isAuthenticated, signout } from '../auth/functions'

// API Impports
import { updateUser, getUser, saveCountry } from '../services/APICalls'
import { useTranslation } from 'react-i18next'

// TODO: Explanation
const Transactions = ({ history }) => {
  const { t } = useTranslation()
  const paramsDataTable = useParams({})
  // Initial state
  const { user, token, kyc } = isAuthenticated()

  const [gender, setGender] = useState(kyc.kyc_gender_id)

  const [address, setAddress] = useState(kyc.address)

  const [bod, setBod] = useState(new Date(kyc.bod))
  const [city, setCity] = useState(kyc.city)
  const [postalCode, setPostalCode] = useState(kyc.zip)
  const [country, setCountry] = useState(0)

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorKyc, setErrorKyc] = useState(false)
  const [successKyc, setSuccessKyc] = useState(false)
  const [state, setState] = useState(kyc.state_cod)
  ////FOR UPLOAD FILE
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [newCountryName, setNewCountryName] = useState('')
  const [statusCountry, setStatusCountry] = useState('true')

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getUser(user.id, token).then(data => {
      if (data.error) {
        setError(data.error)
      } else {
        localStorage.setItem('currentUser', JSON.stringify(data))
      }
    })
  }, [user, token])

  useEffect(() => {
    setErrorKyc(false)
    setSuccessKyc(false)
  }, [address, gender, city, bod, postalCode, country, state])

  // END React Effects ---------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  // For Save New Country
  const clickSaveNewCountry = countryToSave => {
    saveCountry(countryToSave, token).then(data => {
      if (data.error) {
        setError('Error al procesar, intente mas tarde nuevamente')
      } else {
        setSuccess('Solicitud Procesada Exitosamente')
      }
    })
  }

  const closeAlert = e => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
  }

  const clickSignout = event => {
    event.preventDefault()
    localStorage.removeItem('currentUser')
    signout(() => history.push('/'))
  }

  // END Events ----------------------------------------------------------------------------------

  // Render Methods ------------------------------------------------------------------------------

  const alertError = error => (
    <div
      className="alert alert-danger col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: error ? '' : 'none' }}>
      {error}
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )

  const alertSuccess = success => (
    <div
      className="alert alert-info col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: success ? '' : 'none' }}>
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
      {success}
    </div>
  )

  // TODO: Move states to a constant
  const userInfo = () => {
    return (
      <div className="container ptb--40">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <div className="row">
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
                    <Tooltip title="Volver" arrow>
                      <IconButton
                        aria-label="Volver"
                        onClick={() => history.push(`/user/orders/${paramsDataTable?.cripto_id}`)}>
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                    <Stack direction="column" justifyContent="space-between" alignItems="center" spacing={2}>
                      <Typography variant="h5" component="h2" textAlign='center'>
                        {t('transactions.title')}
                      </Typography>
                      <Typography variant="h5" component="h3" textAlign='center'>
                      {`${t('transactions.nOrder')} ${paramsDataTable?.order_id}`}
                      </Typography>
                    </Stack>
                    <Tooltip title="Inicio" arrow>
                      <IconButton
                        aria-label="add icon"
                        sx={{ mr: 2 }}
                        color="primary"
                        onClick={() => history.push('/')}>
                        <HomeIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>

                  <TransactionsDataTable userInput={user.id} />
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
        {alertSuccess(successKyc)}
        {alertError(errorKyc)}
      </div>
    )
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Layout
      title="Administrar País"
      description="Exchange"
      showBreadcrumb={false}
      currentPage="Dashboard Administrador">
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {userInfo()}
      </Box>
    </Layout>
  )
}

export default Transactions
