import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Chip, Grid, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TableContainer from '@mui/material/TableContainer'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid, esES } from '@mui/x-data-grid'

//Icons Imports
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// Custom Component Imports
import Layout from '../../core/Layout'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

// API Imports
import { getCaveatsAPIList, deleteCaveat } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'

const AdminCaveat = () => {
  // Initial State
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()
  const [data, setData] = useState([])
  const [detailsCaveat, setDetailsCaveat] = useState({})
  const [open, setOpen] = React.useState(false)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const handleOpenDeleteModal = caveat => {
    setDetailsCaveat(caveat)
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const openModalDeleteCaveat = async detailsCaveat => {
    const { id: CaveatId } = detailsCaveat
    await deleteCaveat(CaveatId)
    handleCloseDeleteModal()
    getCaveatList()
  }

  const getCaveatList = () => {
    dispatch(showSpinner('Consultando Lista de Advertencias'))
    getCaveatsAPIList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const columns = [
    { field: 'name', headerName: t('warnings.table.name'), width: 130, flex: 1, renderCell: params => <>{params.value}</> },
    {
      field: 'description',
      headerName: t('warnings.table.description'),
      width: 130,
      flex: 1,
      renderCell: params => <>{params.value}</>,
    },
    { field: 'module_name', headerName:  t('warnings.table.module'), width: 130, flex: 1, renderCell: params => <>{params.value}</> },
    {
      field: 'module_description',
      headerName: t('warnings.table.moduleDescription'),
      width: 130,
      flex: 1,
      renderCell: params => <>{params.value}</>,
    },
    {
      field: 'active',
      headerName: t('warnings.table.status'),
      width: 130,
      flex: 1,
      renderCell: params => (
        <>
          {params?.value ? (
            <Chip label={t('warnings.table.activeStatus')} color="success" variant="outlined" />
          ) : (
            <Chip label={t('warnings.table.inactiveStatus')} color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: t('warnings.table.actions'),
      width: 130,
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title="Editar" arrow>
                <IconButton aria-label="Editar" onClick={() => goToPageCaveatDetails(params.row.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar" arrow onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton aria-label="Eliminar">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const goToPageCaveatDetails = idCaveat => {
    history.push(`/admin/caveat/${idCaveat}`)
  }

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getCaveatList()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
      title={t('warnings.title')}
      description={t('warnings.title')}
      showBreadcrumb={true}
      currentPage={t('warnings.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}></Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
            <Tooltip title={t('warnings.back')} arrow>
                <IconButton aria-label="back icon" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{t('warnings.subtitle')}</Typography>
              <Tooltip title={t('warnings.add')} arrow>
                <IconButton
                  aria-label="add icon"
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/caveat/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <TableContainer component={Paper}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={row => row.id}
                autoHeight
                // localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              />
            </TableContainer>
          </GridItem>
        </GridContainer>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {t('warnings.deleteModal.text')} <br></br>
            {detailsCaveat?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => openModalDeleteCaveat(detailsCaveat)}>
          {t('warnings.deleteModal.saveButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminCaveat
