import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Administration from './admin/Administration'
import logo from './assets/img/logo-cripto2.png'
import Auth from './auth/Main'
import PrivateRoute from './auth/PrivateRoute'
import Cryptocurrencies from './capitalMarkets/Cryptocurrencies'
import CryptocurrenciesDetail from './capitalMarkets/CryptocurrenciesDetail'
import Cryptomarkets from './capitalMarkets/Cryptomarkets'
import Navbar from './components/Navbars/Navbar.js'
import Sidebar from './components/Sidebar/Sidebar.js'
import Home from './core/Home'
import BriefCase from './user/BriefCase'
import BuyCrypto from './user/BuyCrypto'
import UserDashboard from './user/Dashboard'
import DirectConvertions from './user/DirectConvertions'
import EmailVerify from './user/EmailVerify'
import EmailVerifyAddressWlist from './user/EmailVerifyAddressWlist'
import Orders from './user/Orders'
import ReceiveCrypto from './user/ReceiveCrypto'
import SendCrypto from './user/SendCrypto'
import usersignout from './user/Signuot'
import Transactions from './user/Transactions'

import TimeTimeouts from './admin/timeTimeouts/TimeTimeouts.js'
import FormTimeTimeouts from './admin/timeTimeouts/FormTimeTimeouts'

import CommisionTaxes from './admin/comissionTaxes/ComissionTaxes'
import FormComissionTaxes from './admin/comissionTaxes/FormComissionTaxes'

import AdminUsers from './admin/users/AdminUsers'
import FormUsers from './admin/users/FormUsers'

import AdminCountry from './admin/country/AdminCountry'
import AdminState from './admin/state/AdminState'

import AdminCaveat from './admin/caveat/AdminCaveat'
import FormCaveat from './admin/caveat/FormCaveat'

import AdminNotification from './admin/notification/AdminNotification'
import FormNotification from './admin/notification/FormNotification'

import AdminLegalTerms from './admin/legalTerms/AdminLegalTerms'
import FormLegalTerm from './admin/legalTerms/FormLegalTerm'

import AdminBList from './admin/blist/AdminBList'
import FormBList from './admin/blist/FormBList'

import AdminCapabilities from './admin/capabilities/AdminCapabilities'
import FormCapabilities from './admin/capabilities/FormCapabilities'

import AdminTypeEvents from './admin/typeEvents/AdminTypeEvents'
import FormTypeEvents from './admin/typeEvents/FormTypeEvents'

import AdminModules from './admin/modules/AdminModules'
import FormModules from './admin/modules/FormModules'

import AdminCriptoCurrency from './admin/criptoCurrency/AdminCriptoCurrency'
import FormCriptoCurrency from './admin/criptoCurrency/FormCriptoCurrency'

import AdminProfiles from './admin/profiles/AdminProfiles'
import FormProfiles from './admin/profiles/FormProfiles'

import AdminTypeNotifications from './admin/typeNotifications/AdminTypeNotifications'
import FormTypeNotifications from './admin/typeNotifications/FormTypeNotifications'

import AdminWList from './user/wlist/AdminWList'
import FormWList from './user/wlist/FormWList'

import AdminIncidents from './admin/incidents/AdminIncidents'
import FormIncidents from './admin/incidents/FormIncidents'

import AdminIncidentsCategory from './admin/incidentsCategory/AdminIncidentsCategory'
import FormIncidentsCategory from './admin/incidentsCategory/FormIncidentsCategory'

import AdminResolutionArea from './admin/resolutionArea/AdminResolutionArea'
import FormResolutionArea from './admin/resolutionArea/FormResolutionArea'

import AdminTechnicalSupport from './admin/technicalSupport/AdminTechnicalSupport '
import FormTechnicalSupport from './admin/technicalSupport/FormTechnicalSupport '

// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import ExitToApp from '@material-ui/icons/ExitToApp'
import Person from '@material-ui/icons/Person'
import CallReceivedIcon from '@mui/icons-material/CallReceived'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore'
import SendIcon from '@mui/icons-material/Send'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import WorkIcon from '@mui/icons-material/Work'
import bgImage from './assets/img/backside1.png'
import styles from './assets/jss/material-dashboard-react/layouts/adminStyle.js'

import { useTranslation } from 'react-i18next'
import { isAuthenticated } from '../src/auth/functions'

const { user } = isAuthenticated()

const useStyles = makeStyles(styles)
let ps

const Routes = () => {
  // styles
  const classes = useStyles()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef()
  const { t } = useTranslation()
  const dashboardRoutes = [
    {
      path: '/markets',
      name: t('sideBar.markets'),
      rtlName: t('sideBar.arkets'),
      icon: ShowChartIcon,
      layout: '/public',
    },
    {
      path: '/cryptocurrencies',
      name: t('sideBar.cryptocurrencies'),
      rtlName: t('sideBar.cryptocurrencies'),
      icon: CurrencyExchangeIcon,
      layout: '/public',
    },
    {
      path: '/briefcase',
      name: t('sideBar.MyPortfolio'),
      rtlName: t('sideBar.MyPortfolio'),
      icon: WorkIcon,
      layout: '/user',
    },
    {
      path: '/convertions',
      name: t('sideBar.convert'),
      rtlName: t('sideBar.convert'),
      icon: ChangeCircleIcon,
      layout: '/user',
    },
    {
      path: '/buy',
      name: t('sideBar.buy'),
      rtlName: t('sideBar.buy'),
      icon: LocalGroceryStoreIcon,
      layout: '/user',
    },
    {
      path: '/send',
      name:  t('sideBar.send'),
      rtlName:  t('sideBar.send'),
      icon: SendIcon,
      layout: '/user',
    },
    {
      path: '/receive',
      name: t('sideBar.receive'),
      rtlName: t('sideBar.receive'),
      icon: CallReceivedIcon,
      layout: '/user',
    },
    /*{
      path: '/dashboard',
      name: t('sideBar.userProfile'),
      rtlName: t('sideBar.userProfile'),
      icon: Person,
      layout: '/user',
    },*/
    {
      path: '/signout',
      name: t('sideBar.logout'),
      rtlName: t('sideBar.logout'),
      icon: ExitToApp,
      layout: '/user',
    },
  ]
  //console.log('🚀 ~ file: Routes.js:166 ~ Routes ~ mainPanel', mainPanel)
  // states and functions
  const [image, setImage] = React.useState(bgImage)
  const [color, setColor] = React.useState('blue')
  const [fixedClasses, setFixedClasses] = React.useState('dropdown show')
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      })
      document.body.style.overflow = 'hidden'
    }
    window.addEventListener('resize', resizeFunction)
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
      }
      window.removeEventListener('resize', resizeFunction)
    }
  }, [mainPanel])
  console.log('COLOR%%%%%%%%%:', color)
  return (
    <BrowserRouter>
      <div className={classes.wrapper}>
        <Sidebar
          routes={dashboardRoutes}
          logoText={'CoinMax'}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <Navbar routes={dashboardRoutes} handleDrawerToggle={handleDrawerToggle} />
          <Switch>
            <Route path="/user/email/verify/:verifyid" exact component={EmailVerify} />
            <Route path="/wlist/email/verify/:verifyid" exact component={EmailVerifyAddressWlist} />
            <Route path="/public/cryptocurrencies" exact component={Cryptocurrencies} />
            <Route path="/public/crypto/:idCrypto" exact component={CryptocurrenciesDetail} />
            <Route path="/user/signout" exact component={usersignout} />
            <Route path="/public/markets" exact component={Cryptomarkets} />
            <Route path="/" exact component={Home} />
            <Route path="/user/auth" exact component={Auth} />
            <Route path="/auth" exact component={Auth} />
            <PrivateRoute path="/user/dashboard" exact component={UserDashboard} />
            <PrivateRoute path="/user/convertions" exact component={DirectConvertions} />
            <PrivateRoute path="/user/briefcase" exact component={BriefCase} />
            <PrivateRoute path="/user/orders/:cripto_id" exact component={Orders} />
            <PrivateRoute path="/user/transactions/:order_id/currencies/:cripto_id" exact component={Transactions} />
            <PrivateRoute path="/user/buy" exact component={BuyCrypto} />
            <PrivateRoute path="/user/receive" exact component={ReceiveCrypto} />
            <PrivateRoute path="/user/send" exact component={SendCrypto} />
            <PrivateRoute path="/user/send/:address_to_send" exact component={SendCrypto} />
            <PrivateRoute path="/admin" exact component={Administration} />

            <Route path="/admin/time_timeouts" exact component={TimeTimeouts} />
            <Route path="/admin/time_timeouts/new" exact component={FormTimeTimeouts} />
            <Route path="/admin/time_timeouts/:idTime" exact component={FormTimeTimeouts} />
            
            <Route path="/admin/commission_taxes" exact component={CommisionTaxes} />
            <Route path="/admin/commission_taxes/new" exact component={FormComissionTaxes} />
            <Route path="/admin/criptocurrency" exact component={AdminCriptoCurrency} />
            <Route path="/admin/criptocurrency/new" exact component={FormCriptoCurrency} />
            <Route path="/admin/criptocurrency/:idCriptoCurrency" exact component={FormCriptoCurrency} />
            <Route path="/admin/commission_taxes/:idTax" exact component={FormComissionTaxes} />
            <Route path="/admin/country" exact component={AdminCountry} />
            <Route path="/admin/state" exact component={AdminState} />

            <Route path="/admin/caveat" exact component={AdminCaveat} />
            <Route path="/admin/caveat/new" exact component={FormCaveat} />
            <Route path="/admin/caveat/:idCaveat" exact component={FormCaveat} />
            <Route path="/admin/notification" exact component={AdminNotification} />
            <Route path="/admin/notification/new" exact component={FormNotification} />
            <Route path="/admin/notification/:idNotification" exact component={FormNotification} />
            <Route path="/admin/legal_terms" exact component={AdminLegalTerms} />
            <Route path="/admin/legal_terms/new" exact component={FormLegalTerm} />
            <Route path="/admin/legal_terms/:id" exact component={FormLegalTerm} />
            <Route path="/admin/blacklist" exact component={AdminBList} />
            <Route path="/admin/blacklist/new" exact component={FormBList} />
            <Route path="/admin/blacklist/:idBList" exact component={FormBList} />
            <Route path="/admin/capabilities" exact component={AdminCapabilities} />
            <Route path="/admin/capabilities/new" exact component={FormCapabilities} />
            <Route path="/admin/capabilities/:idCapabilities" exact component={FormCapabilities} />
            <Route path="/admin/type_events" exact component={AdminTypeEvents} />
            <Route path="/admin/type_events/new" exact component={FormTypeEvents} />
            <Route path="/admin/type_events/:idTypeEvent" exact component={FormTypeEvents} />
            <Route path="/admin/modules" exact component={AdminModules} />
            <Route path="/admin/modules/new" exact component={FormModules} />
            <Route path="/admin/modules/:idModule" exact component={FormModules} />
            <Route path="/admin/users" exact component={AdminUsers} />
            <Route path="/admin/users/new" exact component={FormUsers} />
            <Route path="/admin/users/:idUser" exact component={FormUsers} />
            <Route path="/admin/profiles" exact component={AdminProfiles} />
            <Route path="/admin/profiles/new" exact component={FormProfiles} />
            <Route path="/admin/profiles/:idProfile" exact component={FormProfiles} />
            <Route path="/admin/type_notifications" exact component={AdminTypeNotifications} />
            <Route path="/admin/type_notifications/new" exact component={FormTypeNotifications} />
            <Route path="/admin/type_notifications/:idTypeNotification" exact component={FormTypeNotifications} />

            <Route path="/admin/technical_support" exact component={AdminTechnicalSupport} />
            <Route path="/admin/technical_support/new" exact component={FormTechnicalSupport} />
            <Route path="/admin/technical_support/:idSupport" exact component={FormTechnicalSupport} />

            <Route path="/admin/incidents" exact component={AdminIncidents} />
            <Route path="/admin/incidents/new" exact component={FormIncidents} />
            <Route path="/admin/incidents/:idIncident" exact component={FormIncidents} />

            <Route path="/admin/incidents_category" exact component={AdminIncidentsCategory} />
            <Route path="/admin/incidents_category/new" exact component={FormIncidentsCategory} />
            <Route path="/admin/incidents_category/:idIncidentCategory" exact component={FormIncidentsCategory} />

            <Route path="/admin/resolution_area" exact component={AdminResolutionArea} />
            <Route path="/admin/resolution_area/new" exact component={FormResolutionArea} />
            <Route path="/admin/resolution_area/:idResolutionArea" exact component={FormResolutionArea} />

            <Route path="/user/white_list" exact component={AdminWList} />
            <Route path="/user/white_list/new" exact component={FormWList} />
            <Route path="/user/white_list/:idWList" exact component={FormWList} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  )
}

export default Routes
