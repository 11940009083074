// Core Imports
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Grid } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ToastContext } from '../context'
import { useTranslation } from 'react-i18next'

// Function Imports
//import { signout } from '../auth/functions'




// TODO: Explanation
const Signuot = () => {
  const { t } = useTranslation()
  // Initial state
  const [isLoading, setIsLoading] = useState(true)

  const { changeToastInfo } = useContext(ToastContext)

  const history = useHistory()
  //signout()
  localStorage.removeItem('jwt');
  history.push('/')
  useEffect(() => {
    changeToastInfo({ msg: t('byeMsg'), severity: 'info' })
  }, [])
  


  return (
    <form>
      <Grid container>
        <Grid item xs={12}>
          <LoadingButton
            variant='contained'
            fullWidth
            loading={isLoading}
            type='submit'
            disabled={false}
          >
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )




}

export default Signuot
