import React from 'react'
import { useHistory } from 'react-router-dom'

// core components
import { Link } from 'react-router-dom'
import Button from '../../components/CustomButtons/Button.js'

import { Popper } from '@material-ui/core'
import { Person } from '@material-ui/icons'
import { Language } from '@mui/icons-material'
import { Avatar, ClickAwayListener, Divider, Grow, MenuItem, MenuList, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { isAuthenticated } from '../../auth/functions.js'
import Config from '../../config.js'

export default function AdminNavbarLinks() {
  const { kyc } = isAuthenticated()
  let history = useHistory()

  const { i18n } = useTranslation()

  const [languageInformation, setLanguageInformation] = React.useState(null)
  const [openProfile, setOpenProfile] = React.useState(null)

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }

  const toggleLanguage = e => {
    
    if (languageInformation && languageInformation.contains(e.target)) {
      setLanguageInformation(null)
    } else {
      setLanguageInformation(e.currentTarget)
    }
  }
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }
  const handleCloseLanguage = () => {
    setLanguageInformation(null)
  }

  const onChangeLanguage = langSelected => {
    i18n.changeLanguage(langSelected)
    handleCloseLanguage()
  }

  const clickSignout = () => {
    history.push(`/user/signout`)
  }
  return (
    <Box>
      <Button
        color={window.innerWidth > 959 ? 'transparent' : 'white'}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={languageInformation ? 'profile-menu-list-grow' : null}
        aria-haspopup="true"
        onClick={toggleLanguage}>
        <Language />
        <Typography sx={{ display: { xs: 'nlock', md: 'none' } }}>Perfil</Typography>
      </Button>
      <Popper open={Boolean(languageInformation)} anchorEl={languageInformation} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list-grow"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseLanguage}>
                <MenuList role="menu">
                  <MenuItem onClick={() => onChangeLanguage('en')}>En</MenuItem>
                  <MenuItem onClick={() => onChangeLanguage('es')}>Es</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Button
        color={window.innerWidth > 959 ? 'transparent' : 'white'}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openProfile ? 'profile-menu-list-grow' : null}
        aria-haspopup="true"
        onClick={handleClickProfile}>
        {!!kyc?.url ? (
          <Avatar alt={'detalles del perfil'} src={`${Config.API}${kyc?.url}`} sx={{ width: 24, height: 24 }} />
        ) : (
          <Person />
        )}
        <Typography sx={{ display: { xs: 'nlock', md: 'none' } }}>Perfil</Typography>
      </Button>
      <Popper open={Boolean(openProfile)} anchorEl={openProfile} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list-grow"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                <MenuList role="menu">
                  <Link to={`/user/dashboard`}>
                    <MenuItem>
                      <Typography
                        color="inherit"
                        textAlign="center"
                        fontSize="inherit">{`${kyc?.name} ${kyc?.lastName}`}</Typography>
                      <Typography color="inherit" textAlign="center" fontSize="inherit">{`${kyc?.email}`}</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleCloseProfile}>Perfil</MenuItem>
                  </Link>
                  <Divider light />

                  <MenuItem onClick={clickSignout}>Salir</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}
