// Core Imports
import DropIn from 'braintree-web-drop-in-react'
import React, { useContext, useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useHistory } from 'react-router-dom'
//import FileUpload from "../components/FileUploadDownload/FileUpload";

// Custom Component Imports
import Layout from '../core/Layout'

// Function Imports
import { isAuthenticated, signout } from '../auth/functions'
import { hiddeSpinner, showSpinner } from '../store/slices/uiSlice'

import Switch from '@mui/material/Switch'

// API Impports
import {
  getBalanceAccount,
  getBraintreeClientToken,
  getCurrencyPrice,
  getLegalTerm,
  getTax,
  newBuy,
  processPayment,
} from '../services/APICalls'

import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { TermsAndConditionsModal } from '../components/Modals/ModalTerms'
import { ToastContext } from '../context'

import HelperModal from './components/HelperModal/HelperModal'

// TODO: Explanation
const BuyCrypto = () => {

  const { t } = useTranslation()

  // Initial state
  const dispatch = useDispatch()
  let history = useHistory()
  const { user, token, kyc } = isAuthenticated()
  const [login, setLogin] = useState(user.login)
  const { changeToastInfo } = useContext(ToastContext)
  const [criptoSource, setCriptoSource] = useState(0)
  const [disabled, setDisabled] = useState(true)
  const [disabled2, setDisabled2] = useState(false)
  const [error, setError] = useState(false)
  const [errorUserInactive, setErrorUserInactive] = useState(false)
  const [finalSuccesActive, setFinalSuccesActive] = useState(true)
  const [success, setSuccess] = useState(false)
  const [errorKyc, setErrorKyc] = useState(false)
  const [successKyc, setSuccessKyc] = useState(false)
  const [successBranitree, setSuccessBraintree] = useState(false)
  const [rate, setRate] = useState(0)
  const [buyType, setBuyType] = useState(1)
  const [cryptoBalanceInAccount, setCryptoBalanceInAccount] = useState(0)
  const [usdBalanceInAccount, setUsdBalanceInAccount] = useState(0)
  const [secondsTocloseModal, setSecondsTocloseModal] = useState(0)
  const [isOpenModalSec, setIsOpenModalSec] = useState(false)
  const [isOpenModalCommission, setIsOpenModalCommission] = useState(false)
  const [isOpenModalAntiWashing, setIsOpenModalAntiWashing] = useState(false)
  const [commission, setCommission] = useState([])
  const [anti_washing, setAntiWashingTerm] = useState([])
  const [priceOnSource, setPriceOnSource] = useState(0)
  const [checked, setChecked] = React.useState(false);

  /////BRAINTREE
  const [dataBraintree, setDataBraintree] = useState({ clientToken: null, instance: {} })

  /// FOR DELAY
  const [query, setQuery] = useState('')
  const [displayAmountUsd, setDisplayAmountUsd] = useState(0)

  ////FOR AUTOCLOSE MODAL
  const hideBlockUIModalRef = React.useRef(null)

  ////FOR MODAL SUCCES
  let showBlockUIModalRefFinalSucces = React.useRef(true)
  let hideBlockUIModalRefFinalSucces = React.useRef(null)
  const [quantityForWalletOfFee, setQuantityForWalletOfFee] = useState(0)

  const goToPageBriefCase = () => {
    history.push(`/user/briefcase`)
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  console.log('user:++=', user)

  //Comisiones
  const getDetailsCommission = async () => {
    dispatch(showSpinner())
    getLegalTerm(2)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {

          setCommission(data.data[0])
          //console.log('Este es el termino', data.data[0])
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
    dispatch(hiddeSpinner())
  }

  //Antilavado
  const getDetailsAntiWashing = async () => {
    dispatch(showSpinner())
    getLegalTerm(3)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setAntiWashingTerm(data.data[0])
          //console.log('Este es el termino', data.data[0])
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
    dispatch(hiddeSpinner())


  }

  // React Effects -------------------------------------------------------------------------------
  useEffect(() => {
    getDetailsCommission()
    getDetailsAntiWashing()
  }, [])
  /////FOR DELAY

  // END React Effects ---------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  const handleChange = (event) => {
    setChecked(event.target.checked);
    getPriceRefresh(criptoSource, query, event.target.checked);
  };

  //Braintree
  const getToken = () => {
    dispatch(showSpinner(''))
    getBraintreeClientToken().then(data => {
      if (data.status == 401) {
        goToLogin()
        return
      }
      if (data.error) {
        setDataBraintree({ ...data, error: data.error })
      } else {
        setDataBraintree({ clientToken: data.clientToken })
      }
    })
    dispatch(hiddeSpinner())
  }

  const closeAlert = e => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
    setErrorKyc(false)
    history.push('/')
  }

  const saveTermsAndCondition = () => {
    setIsOpenModalCommission(false)
    //setValue("terms", true)
  }

  const saveTermsAntiWashing = () => {
    setIsOpenModalAntiWashing(false)
    //setValue("terms", true)
  }

  const declineTerms = () => {
    setIsOpenModalCommission(false)
    setIsOpenModalAntiWashing(false)
    //setValue("terms", false)
  }

  const getPriceRefresh = (criptoSource, query, newChecked) => {
    console.log('newChecked DENTRO getPriceRefresh::::', newChecked, 'Valor de Checked::', checked)
    //GET RATE FOR TYPE TAX 8 = "COMISION COMPRAR CRIPTO CON TARJETA DEBITO"
    if (query > 0) {
      dispatch(showSpinner())

      getTax(8).then(data0tax => {
        console.log('data0tax REFRESH', data0tax)
        if (data0tax.status == 401) {
          goToLogin()
        }
        let rate_from_db = data0tax.data[0].rate
        setRate(rate_from_db)
        let plus_one_rate = data0tax.data[0].rate / 100 + 1
        console.log('data0tax::', data0tax.data[0].rate)

        getCurrencyPrice(criptoSource).then(data1 => {
          if (data1.status == 401) {
            goToLogin()
          }
          if (data1.status >= 400 && data1.status <= 599) {
            setError(data1.message)

          } else {
            console.log('data1', data1)
            /////////convert query to usd amount
            let newQueryConverted = newChecked == true ? (query * data1.data.usdCurrencyValue).toFixed(2) : (query / data1.data.usdCurrencyValue).toFixed(8);

            setQuery(newQueryConverted)
            let quantity_without_fee = newChecked == true ? newQueryConverted / data1.data.usdCurrencyValue : newQueryConverted;
            console.log('++++++quantity_without_fee+++++', quantity_without_fee)
            let quantity_crypto_with_fee = quantity_without_fee * plus_one_rate
            let quantity_for_wallet_of_fee = quantity_crypto_with_fee - quantity_without_fee
            setQuantityForWalletOfFee(quantity_for_wallet_of_fee)
            let price_crypto = data1.data.usdCurrencyValue * 1.015
            let total = price_crypto * quantity_crypto_with_fee
            setDisplayAmountUsd(total.toFixed(2))

            if (total.toFixed(2) < 1) {
              setErrorKyc(t('buyCriptoCurrencies.errorMessages.amountLessThanOne'))
              const timeOutId = setTimeout(() => setErrorKyc(false), 3500)
              dispatch(hiddeSpinner())
              return () => clearTimeout(timeOutId)
            }

          }
        })

      })
      dispatch(hiddeSpinner())
    }
  }

  const getPrice = (criptoSource, query) => {
    //GET RATE FOR TYPE TAX 8 = "COMISION COMPRAR CRIPTO CON TARJETA DEBITO"

    dispatch(showSpinner())

    getTax(8).then(data0tax => {
      if (data0tax.status == 401) {
        goToLogin()
      }
      let rate_from_db = data0tax.data[0]?.rate
      setRate(rate_from_db)
      let plus_one_rate = data0tax.data[0].rate / 100 + 1
      console.log('data0tax::', data0tax.data[0].rate)

      getCurrencyPrice(criptoSource).then(data1 => {

        if (data1.status >= 400 && data1.status <= 599) {
          setError(data1.message)

        } else {
          console.log('data1', data1)
          let quantity_without_fee = checked == true ? query / data1.data.usdCurrencyValue : query;
          console.log('++++++quantity_without_fee+++++', quantity_without_fee)
          let quantity_crypto_with_fee = quantity_without_fee * plus_one_rate
          let quantity_for_wallet_of_fee = quantity_crypto_with_fee - quantity_without_fee
          setQuantityForWalletOfFee(quantity_for_wallet_of_fee)
          let price_crypto = data1.data.usdCurrencyValue * 1.015
          let total = price_crypto * quantity_crypto_with_fee
          setDisplayAmountUsd(total.toFixed(2))

          if (total.toFixed(2) < 1) {
            setErrorKyc(t('buyCriptoCurrencies.errorMessages.amountLessThanOne'))
            const timeOutId = setTimeout(() => setErrorKyc(false), 3500)
            dispatch(hiddeSpinner())
            return () => clearTimeout(timeOutId)
          }

        }
      })

    })
    dispatch(hiddeSpinner())
  }

  const onClickPay = () => {
    console.log('PASE PAY')
    let nonce
    if (displayAmountUsd < 1) {
      setErrorKyc(t('buyCriptoCurrencies.errorMessages.amountLessThanOne'))
      const timeOutId = setTimeout(() => setErrorKyc(false), 3500)
      return () => clearTimeout(timeOutId)
    } else {
      getTax(8).then(data0tax => {
        if (data0tax.status == 401) {
          goToLogin()
          return
        }
        let plus_one_rate = data0tax.data[0].rate / 100 + 1
        const forCriptoSource = criptoSource
        getCurrencyPrice(forCriptoSource).then(data1 => {
          if (data1.status == 401) {
            goToLogin()
            return
          }
          if (data1.status == 400 && data1.status <= 599) {
            setDisplayAmountUsd(0)
            setQuery('')
            setCriptoSource(0)
            setError(data1.message)
          } else {
            console.log('data1', data1)
            let newQuery = checked == true ? query / data1.data.usdCurrencyValue : query;
            let price_crypto = data1.data.usdCurrencyValue * 1.01 ////SE APLICA 1% SOBRE LA TASA
            let total = (price_crypto * newQuery).toFixed(2)
            let totalRequeridForBeginTransaction = total * 1.03 //SUMAMOS 3% Extra////MONTO REQUERIDO POR EL CLIENTE
            //PARA GARANTIZAR COMISION... Por Ejemplo Paypal COBRA EN ESPAÑA 3.4% + 0.35 EUROS SI EL
            //MONTO ES INFERIOR A 2500 EUROS, SI ES MAYOR A ALTOS VOLUMENES DE VENTA LA
            //TASA ES 1.9% + 0.35 EUROS
            console.log(
              'usdBalanceInAccount:',
              usdBalanceInAccount,
              'totalRequeridForBeginTransaction',
              totalRequeridForBeginTransaction,
            )
            console.log('%%%%%%%%%usdBalanceInAccount: ', usdBalanceInAccount, ' totalRequeridForBeginTransaction: ', totalRequeridForBeginTransaction)
            if (usdBalanceInAccount < totalRequeridForBeginTransaction) {
              ///CORPORACION NO TIENE FONDOS PARA VENDERLE AL CLIENTE
              setDisplayAmountUsd(0)
              setQuery('')
              setCriptoSource(0)
              setDisabled2(true)
              setDisabled(true)
              setErrorKyc(t('buyCriptoCurrencies.errorMessages.insufficientFunds'))
            } else {
              dataBraintree.instance
                .requestPaymentMethod()
                .then(data => {
                  // console.log(data);
                  nonce = data.nonce
                  // once you have nonce (card type, card number) send nonce as 'paymentMethodNonce'
                  // and also total to be charged
                  const paymentData = {
                    paymentMethodNonce: nonce,
                    amount: displayAmountUsd,
                  }

                  processPayment(paymentData) ////APICALL METHOD
                    .then(response => {
                      if (response.success === true) {
                        console.log('Aprobada')
                        let dataForBuy = {
                          response,
                          user,
                          cryptocurrency: { criptoSource, quantity: newQuery, quantityForWalletOfFee },
                        }
                        ////TODO Generar asiento en la transaccional
                        newBuy(dataForBuy, user.id).then(response0 => {
                          if (response0.status == 401) {
                            goToLogin()
                            return
                          }
                          if (response0.status >= 400 && response0.status <= 499) {
                            setDisplayAmountUsd(0)
                            setQuery('')
                            setCriptoSource(0)
                            setSuccess(response0.message)
                            setErrorKyc(response0.message)
                            setSuccessBraintree(true)
                          }
                          if (response0.status >= 200 && response0.status <= 299) {
                            showBlockUIModalRefFinalSucces.current.click()
                            setSuccessBraintree(true)
                            setQuantityForWalletOfFee(0)
                            setQuery('')
                          }
                        })
                      }
                    })
                    .catch(error => {
                      setErrorKyc(t('buyCriptoCurrencies.errorMessages.processPayment'))
                    })
                })
                .catch(error => {
                  ///TODO
                  console.log('dropin error: ', error)
                  setErrorKyc(t('buyCriptoCurrencies.errorMessages.conexionGateway'))
                })
            }
          }
        })
      })
    }
  }

  // END Events ----------------------------------------------------------------------------------

  // Methods -------------------------------------------------------------------------------------

  const closeModal = () => {
    console.log('🚀 ~ file: BuyCrypto.js:253 ~ closeModal ~ holi')
    setDisabled2(false)
    setSecondsTocloseModal(0)
    hideBlockUIModalRef.current.click()
  }

  useEffect(() => {
    getToken()
  }, [])

  useEffect(() => {
    let criptoSource0 = criptoSource * 1
    let query0 = query * 1

    dispatch(showSpinner(''))
    if (criptoSource0 > 0) {
      getBalanceAccount('6', criptoSource).then(data => {
        if (data.status == 401) {
          goToLogin()
          return
        }
        //if (data.error) {
        console.log('data.usdInWallet:::', data.data.usdInWallet)
        setCryptoBalanceInAccount(data.data.balanceInWallet)
        setUsdBalanceInAccount(data.data.usdInWallet)
        //}          
        dispatch(hiddeSpinner())
      })
    }
    if (criptoSource0 > 0 && query0 > 0) {
      const timeOutId = setTimeout(() => getPrice(criptoSource, query), 500)
      return () => clearTimeout(timeOutId)
    } else {
      setDisplayAmountUsd(0)
    }

  }, [query])

  useEffect(() => {
    if (user && user.active == false) {
      setErrorUserInactive(true)
    }
  }, [])

  useEffect(() => {
    let intervalToClosModal
    if (isOpenModalSec && secondsTocloseModal > 0) {
      intervalToClosModal = setInterval(() => {
        if (secondsTocloseModal > 0) {
          setSecondsTocloseModal(secs => {
            if (secs > 0) {
              return secs - 1
            } else {
              setIsOpenModalSec(false)
              closeModal()
              clearInterval(intervalToClosModal)
              return 0
            }
          })
        }
      }, 1000)
    }

    return () => {
      clearInterval(intervalToClosModal)
    }
  }, [isOpenModalSec])

  // END Methods ---------------------------------------------------------------------------------

  // Render Methods ------------------------------------------------------------------------------

  const modalFeesView = () => {
    return (
      <div className="modal fade" id="qrModal" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('buyCriptoCurrencies.modalAdvertisement.title')}
              </h4>
            </div>
            {kyc.verifiedEmail == true && (
              <div>
                <div className="modal-body modal-body-center">
                  <Typography>
                    {t('buyCriptoCurrencies.modalAdvertisement.msg1')}
                    <br />
                    <br />
                    {`${t('buyCriptoCurrencies.modalAdvertisement.msg2')} `}
                    <Typography
                      onClick={() => setIsOpenModalCommission(true)}
                      component="span"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: '700',
                        },
                      }}>
                      {t('buyCriptoCurrencies.modalAdvertisement.msg3')}
                    </Typography>{' '}
                    {`${t('buyCriptoCurrencies.modalAdvertisement.msg4')} `}
                    <Typography
                      onClick={() => setIsOpenModalAntiWashing(true)}
                      component="span"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: '700',
                        },
                      }}>
                      {t('buyCriptoCurrencies.modalAdvertisement.msg5')}
                      <br />
                      <br />
                    </Typography>
                    {`${t('buyCriptoCurrencies.modalAdvertisement.msg6')} ${secondsTocloseModal} ${t(
                      'buyCriptoCurrencies.modalAdvertisement.msg7',
                    )}`}
                  </Typography>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      onClickPay()
                      closeModal()
                    }}>
                    {t('buyCriptoCurrencies.modalAdvertisement.accept')}<span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                  </button>
                  <button ref={hideBlockUIModalRef} type="button" className="btn btn-default" data-dismiss="modal">
                    {t('buyCriptoCurrencies.modalAdvertisement.cancel')}
                  </button>
                </div>
              </div>
            )}
            {kyc.verifiedEmail == false && (
              <div>
                <div className="modal-body modal-body-center">
                  ERROR KYC: Para Continuar debes abrir el enlace de verificación que enviamos a tu correo electronico
                  (Confirmar Correo KYC). Luego de verificar, reinicia la sesión para aplicar los cambios a tu cuenta.
                </div>
                <div className="modal-footer">
                  <button ref={hideBlockUIModalRef} type="button" className="btn btn-default" data-dismiss="modal">
                    Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  const modalUserInactiveView = () => {
    return (
      <div style={{ display: errorUserInactive == false ? 'none' : '' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('buyCriptoCurrencies.inactiveNotification.title')}
              </h4>
            </div>

            <div>
              <div className="modal-body modal-body-center">
                {t('buyCriptoCurrencies.inactiveNotification.msg')}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalFinalSucces = () => {
    return (
      <>
        <button
          ref={showBlockUIModalRefFinalSucces}
          type="button"
          data-toggle="modal"
          data-target="#finalSuccesModal"
          style={{ display: 'none' }}
        />
        <div className="modal fade" id="finalSuccesModal" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title modal-body-center" id="myModalLabel">
                  {t('buyCriptoCurrencies.ss')}
                </h4>
              </div>
              <div>
                <div className="modal-body modal-body-center">Solicitud Procesada Exitosamente</div>
                <div className="modal-footer">
                  <button
                    ref={hideBlockUIModalRefFinalSucces}
                    onClick={() => goToPageBriefCase()}
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal">
                    {t('buyCriptoCurrencies.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const alertError = error => (
    <div
      className="alert alert-danger col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: error ? '' : 'none' }}>
      {error}
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )

  const alertSuccess = success => (
    <div
      className="alert alert-info col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: success ? '' : 'none' }}>
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
      {success}
    </div>
  )

  // TODO: Move states to a constant
  const userInfo = () => {
    return (
      <div className="container ptb--40">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <div className="ckeckout-left-sidebar">
              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="login" className="normal-label">
                            {t('buyCriptoCurrencies.userAccount.subtitle')}
                          </label>
                          <input
                            disabled="disabled"
                            type="email"
                            className="form-control "
                            id="login"
                            placeholder={t('buyCriptoCurrencies.userAccount.emailPlaceHolder')}
                            //onChange={handleChange('email')}
                            value={login}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                          <label htmlFor="source" className="normal-label">
                            {`${t('buyCriptoCurrencies.userAccount.amountToBuy')} `} &nbsp;
                          </label>
                          {`${t('buyCriptoCurrencies.userAccount.crypto')} `} <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /> USD &nbsp;
                          <HelperModal
                            pictureName={'start_end_p2p'}
                            texBeforeButton={''}
                            title={'Choose Crypto or USD'}
                            description={'You have 2 options to select: Crypto or USD. The "To Buy" box is conditioned by these two options, since they will reflect an amount depending on what you have selected here'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                        </div>
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="source" className="normal-label">
                            {t('buyCriptoCurrencies.userAccount.origin')}
                          </label>
                          <HelperModal
                            pictureName={'refund1'}
                            texBeforeButton={''}
                            title={'Cryptocurrency to Buy'}
                            description={'Select the cryptocurrency to buy. Note: You must have sufficient funds on your debit/credit card to make the purchase. Fees may apply'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                          <select
                            id="source"
                            className="form-control"
                            disabled={disabled2}
                            onChange={e => {
                              setCriptoSource(e.target.value)
                              closeModal()
                              if (e.target.value > 0) {
                                setDisabled(false)
                              }
                            }}
                            value={criptoSource}>
                            <option value="0" defaultValue="0">
                              {t('buyCriptoCurrencies.userAccount.select')}
                            </option>
                            <option value="1" defaultValue="0">
                              BTC
                            </option>
                            <option value="2">ETH</option>
                            <option value="3">TRX</option>
                            <option value="7">XRP</option>
                            <option value="5">USDT-TRC20</option>
                            <option value="6">USDT-ERC20</option>
                            <option value="8">USDC-ERC20</option>
                          </select>
                        </div>
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="amount1" className="normal-label">
                            {t('buyCriptoCurrencies.userAccount.quantity')}
                            {checked == false ? '' : '(USD)'}
                          </label>
                          <HelperModal
                            pictureName={'refund1'}
                            texBeforeButton={''}
                            title={'Amount CRYPTO/USD'}
                            description={'Enter the amount to purchase. Note: Be especially careful with the amount, remember that you have the switch to choose the type of amount, either in Crypto or in USD'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                          <NumericFormat
                            NumericFormat={2}
                            className="form-control "
                            decimalSeparator="."
                            decimalScale={8}
                            disabled={disabled}
                            id="amount1"
                            placeholder={checked == false ?
                              t('buyCriptoCurrencies.userAccount.numericMessageFalse')
                              : t('buyCriptoCurrencies.userAccount.numericMessageTrue')
                            }
                            onValueChange={values => {
                              setQuery(values?.floatValue)
                            }}
                            value={query}
                            allowNegative={false}
                          />
                        </div>

                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="amount1" className="normal-label">
                            {t('buyCriptoCurrencies.userAccount.toBuy')}
                          </label>
                          <HelperModal
                            pictureName={'refund1'}
                            texBeforeButton={''}
                            title={'Amount to Buy'}
                            description={'Amount reflected in the selected cryptocurrency'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                          <input type="text" value={displayAmountUsd} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          {dataBraintree.clientToken !== null &&
                            displayAmountUsd * 1 >= 1 &&
                            successBranitree == false ? (
                            <div className="payment-form">
                              <div className="pasadena-section-title-1">{t('buyCriptoCurrencies.userAccount.paymentDetails')}</div>
                              <div className="mt--00">
                                <DropIn
                                  options={{
                                    authorization: dataBraintree.clientToken /*,
                                                                        paypal: {
                                                                            flow: 'vault'
                                                                        }*/,
                                  }}
                                  onInstance={instance => (dataBraintree.instance = instance)}
                                />
                                {console.log('dataBraintree: ', dataBraintree)}

                                <div className="checkout-btn" style={{ marginTop: 20, textAlign: 'right' }}>
                                  <button
                                    onClick={() => {
                                      setSecondsTocloseModal(10)
                                      setIsOpenModalSec(true)
                                    }}
                                    data-toggle="modal"
                                    data-target="#qrModal"
                                    aria-label="qr"
                                    className="fv-btn">
                                    Confirmar y Pagar
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TermsAndConditionsModal
            open={isOpenModalCommission}
            handleClose={() => declineTerms()}
            saveTermsAndCondition={saveTermsAndCondition}
            accepetText="SI, Acepto las comisiones"
            denyText="NO, Acepto las comisiones"
            {...{ tacText: commission ? commission.description : '' }}
          />
          <TermsAndConditionsModal
            open={isOpenModalAntiWashing}
            handleClose={() => declineTerms()}
            saveTermsAndCondition={saveTermsAntiWashing}
            accepetText={t('modalsTermsAndConditions.acceptPolitics')}
            denyText={t('modalsTermsAndConditions.denyPolitics')}
            {...{ tacText: anti_washing.description }}
          />
        </div>
        {alertSuccess(successKyc)}
        {alertError(errorKyc)}
      </div>
    )
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Layout
      title={t('buyCriptoCurrencies.title')?.toUpperCase()}
      description={t('buyCriptoCurrencies.title')}
      showBreadcrumb={true}
      currentPage={t('buyCriptoCurrencies.title')?.toUpperCase()}>
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        {errorUserInactive == false ? userInfo() : ''}
        {modalFinalSucces()}
        {modalFeesView()}
        {modalUserInactiveView()}
      </Box>
    </Layout>
  )
}

export default BuyCrypto