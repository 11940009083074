import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isLoading: false,
    loadingMsg: '',
  },
  reducers: {
    showSpinner: (state, action) => {
      state.isLoading = true
      state.loadingMsg = action?.payload ?? ''
    },
    hiddeSpinner: state => {
      state.loadingMsg = ''
      state.isLoading = false
    },
  },
})

export const { showSpinner, hiddeSpinner } = uiSlice.actions
