// Core Imports
import { LoadingButton } from '@mui/lab'
import { Alert, Grid, Link, Modal, TextField, Typography } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

// Function Imports
import { Box } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { authenticate, isAuthenticated, signin } from '../auth/functions'
import { ToastContext } from '../context'
import { hiddeSpinner, showSpinner } from '../store/slices/uiSlice'
import { useTranslation } from 'react-i18next';

const initValues = {
  login: '',
  password: '',
}

// TODO: Explanation
const Signin = (active = '') => {
  // Initial state
  const dispatch = useDispatch()
  const { isLoading } = useSelector(state => state.ui)
  const [infoModal, setInfoModal] = useState({isShowModal: false})
  const [isValidCaptcha, setIsValidCaptcha] = useState(false)
  const { t } = useTranslation()  

  const { changeToastInfo } = useContext(ToastContext)

  const history = useHistory()
  const { handleSubmit, register, reset, watch } = useForm(initValues)

  const captcha = useRef(null)

  const { user } = isAuthenticated()

  const onChangeRecaptcha = () => {
    if (captcha.current.getValue()) {
      console.log('El usuario no es un robot')
      setIsValidCaptcha(true)
    }
  }

  const onSubmitForm = async formData => {
    try {
      dispatch(showSpinner())
      const response = await signin(formData)
      if (response?.status >= 400 && response?.status <= 599) {
        changeToastInfo({ msg: response.message, severity: 'error' })
        dispatch(hiddeSpinner())
        return
      }
      const data = response
      // changeToastInfo({ msg: `Bienvenido ${data?.kyc?.name}!`, severity: 'info' })
      authenticate(data, () => {
        dispatch(hiddeSpinner())
        setInfoModal({isShowModal: true, msg: `Bienvenido ${data?.kyc?.name}!`})
        setTimeout(() => {
          setInfoModal({isShowModal: false, msg: ''})
          if (user && user.role === 'admin') {
            history.push('/admin/dashboard/user-information')
          } else {
            history.push('/user/briefcase')
          }
        }, 5000);
      })
    } catch (error) {
      dispatch(hiddeSpinner())
      console.log(error)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Modal
        open={infoModal?.isShowModal}
        onClose={() =>setInfoModal(oldState => ({...oldState,isShowModal: false}))}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {infoModal?.msg}
          </Typography>
        </Box>
      </Modal>
      <Grid container>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TextField
            label={t('auth.email')}
            type="email"
            placeholder="test@test.com"
            fullWidth
            name="email"
            {...register('login')}></TextField>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <TextField
            label={t('auth.password')}
            type="password"
            placeholder="*******"
            fullWidth
            {...register('password')}></TextField>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2, mt: 1 }}>
          <Grid item xs={12} display={isValidCaptcha === false ? '' : 'none'}>
            <Alert severity="error">{t('auth.pleaseAcceptTheCaptcha')}</Alert>
          </Grid>
          <Grid item xs={12} ml={4}>
            <ReCAPTCHA ref={captcha} sitekey="6Lcg0KskAAAAAG1wUdPU_dNz2e4RkAUogIsaKugE" onChange={onChangeRecaptcha} />
          </Grid>
          <Grid item xs={12} textAlign="center">
          {t('auth.youDoNotHaveAnAccount')} <Link href="https://www.criptopagosya.com/remesas/ingres_usuario_remesa.php"> {t('auth.signUp')}</Link>
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              fullWidth
              loading={isLoading}
              type="submit"
              disabled={isValidCaptcha === false}>
              {t('auth.login')}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default Signin
