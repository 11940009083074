import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Chip, Grid, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TableContainer from '@mui/material/TableContainer'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { DataGrid, esES } from '@mui/x-data-grid'

//Icons Imports
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// Custom Component Imports
import Layout from '../../core/Layout'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

// API Impports
import { getLegalTerms, deleteLegalTerm } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/system'

const AdminLegalTerms = () => {
  // Initial State
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()
  const [data, setData] = useState([])
  const [detailsLegalTerm, setDetailsLegalTerm] = useState({})
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openDetailsModal, setOpenDetailsModal] = useState(false)
  const [detailsModal, setDetailsModal] = useState('')

  const handleOpenDeleteModal = lt => {
    setDetailsLegalTerm(lt)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }

  const openModalDeleteLegalTerm = async detailsLegalTerm => {
    const { id: idLegalTerm } = detailsLegalTerm
    await deleteLegalTerm(idLegalTerm)
    handleCloseDeleteModal()
    getLegalTermList()
  }

  const getLegalTermList = () => {
    dispatch(showSpinner('Consultando la Lista de Términos Legales'))
    getLegalTerms()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
          //setBriefcase(data)
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            //goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const columns = [
    { field: 'name', headerName: t('legalTerms.table.name'), width: 250, renderCell: params => <>{params.value}</> },
    
    { field: 'name_country', headerName: t('legalTerms.table.country'), width: 160, flex: 1, renderCell: params => <>{params.value}</> },
    { field: 'name_type_event', headerName: 'Evento', width: 100, flex: 1, renderCell: params => <>{params.value}</> },
    {
      field: 'active',
      headerName: t('legalTerms.table.status'),
      width: 140,
      flex: 1,
      renderCell: params => (
        <>
          {params?.value ? (
            <Chip label={t('legalTerms.table.activeStatus')} color="success" variant="outlined" />
          ) : (
            <Chip label={t('legalTerms.table.inactiveStatus')} color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: 'description',
      headerName: t('legalTerms.table.description'),
      width: 100,
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
             <Tooltip title={t('legalTerms.table.showDescriptionButton')} arrow>
                <IconButton
                  aria-label={t('legalTerms.table.showDescriptionButton')} onClick={() => openLegalTermDescriptions(params.row)}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 100,
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
             <Tooltip title={t('legalTerms.table.editButton')} arrow>
                <IconButton
                  aria-label={t('legalTerms.table.editButton')} onClick={() => goToPageLegalTermDetails(params.row.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('legalTerms.table.deleteButton')} arrow onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton aria-label={t('legalTerms.table.deleteButton')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const goToPageLegalTermDetails = idLegalTerm => {
    history.push(`/admin/legal_terms/${idLegalTerm}`)
  }

  const openLegalTermDescriptions = legalTerm => {
    setOpenDetailsModal(state => !state)
    setDetailsModal(legalTerm)
  }

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false)
  }
  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getLegalTermList()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
      title={t('legalTerms.title')}
      description={t('legalTerms.title')}
      showBreadcrumb={true}
      currentPage={t('legalTerms.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Container maxWidth="lg">
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Tooltip title={t('legalTerms.back')} arrow>
                <IconButton aria-label="back icon" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{t('legalTerms.subtitle')}</Typography>
              <Tooltip title={t('legalTerms.add')} arrow>
                <IconButton
                  aria-label="add icon"
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/legal_terms/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <TableContainer component={Paper}>
              <DataGrid
                rows={data}
                columns={columns}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5, page: 0, pageCount: 1 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                getRowId={row => row?.id}
                autoHeight
                // localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              />
            </TableContainer>
          </Grid>
        </Container>
      </Box>
      <Dialog
        open={openDeleteModal}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Está seguro que desea eliminar? <br></br>
            {detailsLegalTerm?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => openModalDeleteLegalTerm(detailsLegalTerm)}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDetailsModal}
        onClose={handleCloseDetailsModal}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">{t('legalTerms.modalDetail.title')} {detailsModal?.name}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="scroll-dialog-description"
            dangerouslySetInnerHTML={{ __html: detailsModal?.description }}></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailsModal}>{t('legalTerms.modalDetail.closeButton')}</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminLegalTerms
