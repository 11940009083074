import axios from 'axios'
import Config from '../config'
import { isAuthenticated } from '../auth/functions'

const API_URL = `${Config.API}`

const baseURL = `${API_URL}`
// const token = () => typeof window !== undefined && window.sessionStorage.getItem('token')

const responseHandler = response => {
  const { data, status } = response

  if (status >= 200 && status < 600) {
    return data
  }

  return Promise.reject({ data, status })
}

// Instancias
export const axiosInstance = axios.create({
  headers: { Accept: 'application/json' },
  baseURL,
  validateStatus() {
    return true
  },
})

//funcion para cancelar la peticion al acabarse
//el tiempo mediante abort controller
function newAbortSignal(timeoutMs = 20000) {
  const abortController = new AbortController()
  setTimeout(() => abortController.abort(), timeoutMs)

  return abortController.signal
}

// Interceptos o proceso antes de la peticion
// Este permite que modifiques la peticion justo antes de enviarse
axiosInstance.interceptors.request.use(
  config => {
    const { token } = isAuthenticated()
    config.headers['Content-Type'] = 'application/json'
    // const token = getInSessionStorage('token')
    if (token !== null && token !== false) {
      config.headers['x-access-token'] = token
    }
    return {
      ...config,
      signal: newAbortSignal(),
    }
  },
  error => Promise.reject(error),
)

// este es el interceptos de la respuesta
// Si falla podemos capturar aqui mismo el error si es generico como el de internet o un error 500 del servidor
axiosInstance.interceptors.response.use(
  responseHandler,
  response => {
    //   console.log('response', response)
    //   SnackbarUtilities.success('Success')
    return response
  },
  error => {
    // if (error?.code === 'ERR_NETWORK') {
    //   SnackbarUtilities.error('Tienes inconvenientes en la conexión por favor intenta nuevamente')
    // }
    return Promise.reject(error)
  },
)
