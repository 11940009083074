import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import store from './store/store'

import { ToastProvider } from './context'
import './i18n/i18n'

import { AppTheme } from './theme'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppTheme>
        <ToastProvider>
          <App />
        </ToastProvider>
      </AppTheme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
