import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { useDispatch  } from 'react-redux'
import { DataGrid } from '@mui/x-data-grid'

//Icons Imports
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// API Imports
import { getUsersAPIList, deleteUser } from '../../services/APICalls'

// Custom Component Imports
import Layout from '../../core/Layout'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { useTranslation } from 'react-i18next'

const AdminUsers = () => {
  // Initial state
  const dispatch = useDispatch()
  let history = useHistory()
  const { t } = useTranslation()

  const columns = [
    {
      field: 'kyc_name',
      headerName: t('users.firstName'),
      flex: 1,
      renderCell: params => <>{params?.value} </>,
    },
    {
      field: 'kyc_lastname',
      headerName: t('users.lastName'),
      flex: 1,
      renderCell: params => <>{params?.value} </>,
    },
    {
      field: 'login_user',
      headerName: t('users.email'),
      minWidth: 200,
      flex: 1,
      renderCell: params => <>{params?.value}</>,
    },
    {
      field: 'name_group',
      headerName: t('users.group'),
      flex: 1,
      renderCell: params => <>{params?.value?.toString()}</>,
    },
    {
      field: 'active_user',
      headerName: t('users.status'),
      flex: 1,
      renderCell: params => <>{params?.value ? 'Activo' : 'Inactivo'}</>,
    },

    {
      field: 'actions',
      headerName: t('users.actions'),
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title="Editar" arrow>
                <IconButton aria-label="Editar" onClick={() => goToPageListUsersDetails(params.row.id_user)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar" arrow onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton aria-label="Eliminar">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const [data, setData] = useState([])
  const [detailsUser, setDetailsUser] = useState({})
  const [open, setOpen] = React.useState(false)

  const handleOpenDeleteModal = user => {
    setDetailsUser(user)
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const openModalDeleteUser = async userDetails => {
    // const { id: IdCapabilities } = userDetails
    // await deleteCapabilities(IdCapabilities)
    handleCloseDeleteModal()
    getUsersList()
  }
  

  const getUsersList = () => {
    dispatch(showSpinner('Obteniendo Lista de Usuarios'))
    getUsersAPIList().then((response) => {
      
      if (Array.isArray(response)) {
        setData(response);
      }
    })
    .catch(error => {
      console.log("🚀 ~ file: AdminUsers.js:109 ~ getUsersList ~ error:", error)
      
    }).finally(() => {
      dispatch(hiddeSpinner())
    })
  }

  const goToPageListUsersDetails = idUser => {
    history.push(`/admin/users/${idUser}`)
  }

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getUsersList()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
      title={ t('users.title')}
      description={ t('users.title')}
      showBreadcrumb={true}
      currentPage={ t('users.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
              <Tooltip title={t('users.back')} arrow>
                <IconButton aria-label="back icon" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{ t('users.userList')}</Typography>
              <Tooltip title={t('users.add')} arrow>
                <IconButton
                  aria-label="add icon"
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/users/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowId={row => row.id_user}
              autoHeight
            />
          </GridItem>
        </GridContainer>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{t('users.deleteModal.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('users.deleteModal.text')}
            <br />
            {`${detailsUser?.kyc_name}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>{t('users.deleteModal.cancelButton')}</Button>
          <Button variant="contained" onClick={() => openModalDeleteUser(detailsUser)}>
            {t('users.deleteModal.saveButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminUsers
