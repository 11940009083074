// Core Imports
import React, { useEffect, useState, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import millify from 'millify'
import { Link } from 'react-router-dom'
import { getRanking } from '../services/APICalls'
import DateRange from '@material-ui/icons/DateRange'
import GridItem from '../components/Grid/GridItem.js'
import GridContainer from '../components/Grid/GridContainer.js'
import Danger from '../components/Typography/Danger.js'
import Card from '../components/Card/Card.js'
import CardHeader from '../components/Card/CardHeader.js'
import CardIcon from '../components/Card/CardIcon.js'
import CardFooter from '../components/Card/CardFooter.js'
import 'react-tabs/style/react-tabs.css'

// Selector Imports
import styles from '../assets/jss/material-dashboard-react/views/dashboardStyle.js'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles(styles)

// TODO: Explanation
const CryptocurrencieSection = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [cryptos, setCryptos] = useState()


  useEffect(() => {
    let isMounted = true
    getRanking().then(dataResponse => {
      const data = dataResponse?.dataValue?.data?.coins
      
      // console.log('data.RANKINGt:::', data.dataValue.data.coins)
      // const filteredData = data.dataValue.data.coins.filter(item => item.name.toLowerCase().includes(searchTerm))
      if (isMounted) setCryptos(data)
      //}
    })
    return () => { isMounted = false }
  }, [])

  // Main Render
  return (
    <>
      {cryptos && (
        <div className="bg__white ptb--90">
          <section className="htc__product__area shop__page bg__white">
            <div className="container">
              <div className="htc__product__container">
                <div className="row product__list another-product-style">
                  <GridContainer>
                    {cryptos?.map((currency, index) => (
                      <GridItem xs={12} sm={6} md={3} key={`currencie-${index}`}>
                        <Card>
                          <CardHeader color="success" stats icon>
                            <CardIcon color="success">
                              <img style={{ width: '35px', height: '35px' }} src={currency?.iconUrl} />
                            </CardIcon>
                            <p className={classes?.cardCategory}>{`${currency?.rank}. ${currency?.name}`}</p>
                            <h3 className={classes?.cardTitle}>
                              <p>
                                {t('cryptocurrencies.price')}: {millify(currency?.price)}
                              </p>
                              <p>
                                {t('cryptocurrencies.marketCap')}: {millify(currency?.marketCap)}
                              </p>
                              <p>
                                {t('cryptocurrencies.dailyChange')}: {currency?.change}%
                              </p>
                            </h3>
                          </CardHeader>
                          <CardFooter stats>
                            <div className={classes?.stats}>
                              <Danger>
                                <DateRange />
                              </Danger>
                              <Link key={currency?.uuid} to={`/public/crypto/${currency?.uuid}`}>
                                {t('cryptocurrencies.seeMoreInfo')}...
                              </Link>
                            </div>
                          </CardFooter>
                        </Card>
                      </GridItem>
                    ))}
                  </GridContainer>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

export default CryptocurrencieSection
