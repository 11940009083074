import React from "react";
import GridItem from "../components/Grid/GridItem.js";
import GridContainer from "../components/Grid/GridContainer.js";
import Card from "../components/Card/Card.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import { Link } from "react-router-dom";

//Import Icons
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PublicIcon from "@mui/icons-material/Public";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import FeedbackIcon from "@mui/icons-material/Feedback";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import GradingIcon from "@mui/icons-material/Grading";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import MapIcon from "@mui/icons-material/Map";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ClassIcon from '@mui/icons-material/Class';


//import List from '@mui/icons-material/Public';
// Custom Component Imports
import Layout from "../core/Layout";
import { Box } from "@mui/system";

import { isAuthenticated } from '../auth/functions'
import { useTranslation } from 'react-i18next';
const { user } = isAuthenticated()

//Object that has the crud designed in the system


//Main function export
export default function Administration() {
  const { t } = useTranslation()
  let items = [
    {
      id: 1,
      name: t('administration.items.users'),
      category: "CRUD",
      color: "info",
      url: "/admin/users",
      icon: <ManageAccountsIcon />,
    },
    {
      id: 2,
      name: t('administration.items.profiles'),
      category: "CRUD",
      color: "danger",
      url: "/admin/profiles",
      icon: <AdminPanelSettingsIcon />,
    },
    {
      id: 3,
      name: t('administration.items.capabilities'),
      category: "CRUD",
      color: "success",
      url: "/admin/capabilities",
      icon: <SupervisedUserCircleIcon />,
    },
    {
      id: 4,
      name: t('administration.items.countries'),
      category: "CRUD",
      color: "primary",
      url: "/admin/country",
      icon: <PublicIcon />,
    },
    {
      id: 5,
      name: t('administration.items.states'),
      category: "CRUD",
      color: "warning",
      url: "/admin/state",
      icon: <MapIcon />,
    },
    {
      id: 6,
      name: t('administration.items.legalTerms'),
      category: "CRUD",
      color: "info",
      url: "/admin/legal_terms",
      icon: <GradingIcon />,
    },
    {
      id: 7,
      name: t('administration.items.warnings'),
      category: "CRUD",
      color: "danger",
      url: "/admin/caveat",
      icon: <FeedbackIcon />,
    },
    {
      id: 8,
      name: t('administration.items.taxesFees'),
      category: "CRUD",
      color: "success",
      url: "/admin/commission_taxes",
      icon: <AttachMoneyIcon />,
    },
  
    {
      id: 9,
      name: t('administration.items.notifications'),
      category: "CRUD",
      color: "primary",
      url: "/admin/notification",
      icon: <ForwardToInboxIcon />,
    },
  
    {
      id: 10,
      name: t('administration.items.blacklistsAddresses'),
      category: "CRUD",
      color: "warning",
      url: "/admin/blacklist",
      icon: <FormatListNumberedIcon />,
    },
    {
      id: 11,
      name: t('administration.items.modules'),
      category: "CRUD",
      color: "info",
      url: "/admin/modules",
      icon: <ViewModuleIcon />,
    },
    {
      id: 12,
      name: t('administration.items.cryptocurrencies'),
      category: "CRUD",
      color: "danger",
      url: "/admin/criptocurrency", 
      icon: <CurrencyExchangeIcon />,
    },
    {
      id: 13,
      name: t('administration.items.typeEvents'),
      category: "CRUD",
      color: "success",
      url: "/admin/type_events",
      icon: <ListAltIcon />,
    },
    
    {
      id: 14,
      name: t('administration.items.notificationsTypes'),
      category: "CRUD",
      color: "primary",
      url: "/admin/type_notifications",
      icon: <MarkEmailUnreadIcon />,
    },
    {
      id: 15,
      name: t('administration.items.technicalSupportTicket'),
      category: "CRUD",
      color: "warning",
      url: "/admin/technical_support",
      icon: <SupportAgentIcon />,
    },
    {
      id: 16,
      name: t('administration.items.technicalSupportIncidents'),
      category: "CRUD",
      color: "info",
      url: "/admin/incidents",
      icon: <ContactSupportIcon />,
    },
    {
      id: 17,
      name: t('administration.items.technicalSupportIncidentsCategory'),
      category: "CRUD",
      color: "danger",
      url: "/admin/incidents_category",
      icon: <ClassIcon />,
    },
    {
      id: 18,
      name: t('administration.items.technicalSupportSolutionArea'),
      category: "CRUD",
      color: "success",
      url: "/admin/resolution_area",
      icon: <HelpCenterIcon />,
    },
    {
      id: 19,
      name: 'Administration Timeout Elapsed',
      category: "CRUD",
      color: "success",
      url: "/admin/time_timeouts",
      icon: <AccessTimeIcon />,
    },
  ];
  return (
    <Layout
      title={t('administration.title')}
      description={t('administration.description')}
      showBreadcrumb={true}
      currentPage={t('administration.title')}
    >
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <GridContainer>
          {items.map((module) => (
            <GridItem xs={12} sm={6} lg={4} xl={3} key={module.id}>
              <Card>
                <CardHeader color={module.color} stats icon>
                  <CardIcon color={module.color}>{module.icon}</CardIcon>
                  <Link key="" to={`${module.url}`}>
                    <p>{module.name}</p>
                  </Link>
                </CardHeader>
              </Card>
            </GridItem>
          ))}
        </GridContainer>
      </Box>
    </Layout>
  );
}
