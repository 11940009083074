import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'

//Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

//API Imports
import { getIncidentID, createIncident, updateIncident } from '../../services/APICalls'

const initValues = {
  id: '',
  name: '',
  description: '',
  active: '',
  category: '',
  resolutory: '',
  solution: '',
}

const FormIncidents = () => {
  // Initial State
  const dispatch = useDispatch()
  const history = useHistory()
  //params que vienen por la url
  const paramsIncidentForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getDetailsIncident = async () => {
    dispatch(showSpinner('Obteniendo Información del Incidente'))
    getIncidentID(paramsIncidentForm?.idIncident)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setDefaultValues(data.data[0])
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const onSubmitForm = incident => {
    const updaterIncident = {
      id: incident.id,
      name: incident.name,
      description: incident.description,
      active: incident.active,
    }
    if (paramsIncidentForm?.idIncident !== undefined) {
      SendUpdateIncident(updaterIncident)
      goToIncidentList()
      return
    }
    sendCreateIncident(updaterIncident)
    goToIncidentList()
    return
  }

  const SendUpdateIncident = async incident => {
    dispatch(showSpinner('Modificando Información del incidente'))
    try {
      await updateIncident(incident.id, incident)
      dispatch(hiddeSpinner())
      return
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const sendCreateIncident = async incident => {
    dispatch(showSpinner('Registrando Información del incidente'))
    try {
      await createIncident(incident)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToIncidentList = () => {
    history.push('/admin/incidents')
  }

  useEffect(() => {
    //en caso de que exista un id, se pone en modo editar
    if (paramsIncidentForm?.idIncident !== undefined) {
      getDetailsIncident()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsIncidentForm?.idIncident !== undefined ? 'EDICION' : 'REGISTRO'} DE INCIDENTE`}
      description={`${
        paramsIncidentForm?.idIncident !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de incidente`}
      showBreadcrumb={true}
      currentPage={`${paramsIncidentForm?.idIncident !== undefined ? 'EDICION' : 'REGISTRO'} DE INCIDENTE`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToIncidentList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Categoría"
                name="category"
                options={[
                  { key: 'Usuarios: KYC', value: 1 },
                  { key: 'Usuarios: Bloqueos', value: 2 },
                  { key: 'Interno: Redes', value: 3 },
                  { key: 'Interno: Frontend', value: 4 },
                  { key: 'Interno: Backend', value: 5 },
                  { key: 'Interno: Base de Datos', value: 6 },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Area Resolotoria"
                name="resolutory"
                options={[
                  { key: 'Soporte KYC', value: 1 },
                  { key: 'Soporte de Redes', value: 2 },
                  { key: 'Departamento de Ingenieria', value: 3 },
                  { key: 'Proyectos', value: 4 },
                  { key: 'Talento Humano', value: 5 },
                  { key: 'Seguridad', value: 6 },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Descripción"
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('solution') !== ''}
                {...register('solution', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Solución"
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormIncidents
