import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { QrReader } from 'react-qr-reader'
import { useForm } from 'react-hook-form'
import {
  getCurrencyAddressVerify,
  requestVerifyAddressWlist,
  smsVerifyAddressInWlist,
  createNewAddressInWlist,
} from '../../services/APICalls'
import { isAuthenticated } from '../../auth/functions'
import { validateOnlyNumberStringSigns } from '../../utils'

const steps = ['Información', 'Valida que eres tú', 'Nueva dirección']

export const StepperWList = cryptoInfo => {
  const [activeStep, setActiveStep] = useState(0)

  const { user, token, kyc } = isAuthenticated()

  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm()

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep(prevActiveStep => (activeStep < steps.length ? prevActiveStep + 1 : 0))
    } else if (activeStep === 1) {
      validateAddress()
    } else if (activeStep === 2) {
      verifySMS()
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => (activeStep > 0 ? prevActiveStep - 1 : prevActiveStep))
  }

  const validateAddress = async () => {
    try {
      const res = await getCurrencyAddressVerify(cryptoInfo?.cripto_id, watch('address'))
      if (!res?.result) {
        setError('address', { type: 'custom', message: 'Dirección inválida' })
        return
      }
      sendCodeEmailAndSMS()
      setActiveStep(prevActiveStep => (activeStep < steps.length ? prevActiveStep + 1 : 0))
    } catch (error) {
      console.log('🚀 ~ file: StepperWList.jsx ~ line 48 ~ validateAddress ~ error', error)
      // return false
    }
  }

  const registerAddress = async () => {
    try {
      const sendData = {
        address: watch('address'),
        crypto_id: watch('cripto_id'),
        user_id: watch('user_id'),
      }
      const res = await createNewAddressInWlist(sendData)
    } catch (error) {
      console.log('🚀 ~ file: StepperWList.jsx ~ line 63 ~ createNewAddress ~ error', error)
      // return false
    }
  }

  const sendCodeEmailAndSMS = async () => {
    try {
      const sendData = {
        address: watch('address'),
        phoneNumber:  kyc?.phone,
        email: kyc?.email,
      }
      const res = await requestVerifyAddressWlist(sendData)
    } catch (error) {
      console.log('🚀 ~ file: StepperWList.jsx ~ line 45 ~ validateAddress ~ error', error)
      // return false
    }
  }

  const verifySMS = async () => {
    const res = await smsVerifyAddressInWlist({code: watch('smsCode'), address: watch('address')})
    if (res?.message) {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
      return
    }
    setError('smsCode', { type: 'custom', message: 'código inválida' })
  }

  return (
    <div className="modal fade" id="wlAgregarModal" role="dialog" aria-labelledby="myModalLabel">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                reset({ address: '', smsCode: '' })
                setActiveStep(0)
              }}>
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title modal-body-center" id="myModalLabel">
              Agregar una Dirección de Confianza
            </h4>
          </div>
          <div className="modal-body ">
            <Box sx={{ width: '100%' }}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {}
                  const labelProps = {}
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              <Typography sx={{ mt: 2, mb: 1 }} textAlign="center">
                Paso {activeStep + 1}
              </Typography>
              {activeStep === steps.length ? (
                <>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      El proceso de verificación vía SMS ha sido exitoso, para finalizar debes ingresar al enlace
                      enviado a tu correo electrónico
                    </Typography>
                  </Box>
                </>
              ) : (
                <>
                  {activeStep === 0 && (
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        A continuación se iniciara un proceso de validación (vía sms), todo esto con el fin de que
                        confirmemos que eres tú quien realiza el proceso. En caso de estar de acuerdo presiona el botón
                        siguiente.
                      </Typography>
                    </Box>
                  )}
                  {activeStep === 1 && (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            Usa la cámara para escanear tu código QR o escribe mediante la dirección en el campo de
                            texto, al darle siguiente validaremos la dirección colocada en dicho campo
                          </Typography>
                        </Grid>
                        <Grid item xs={12} justifyContent="center" display="flex">
                          <Box width="50%">
                            <QrReader
                              onResult={(qrData, error) => {
                                if (!!qrData) {
                                  setValue('address', qrData?.text)
                                }
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            focused={watch('address') !== ''}
                            {...register('address', {
                              required: 'Este campo es requerido',
                              pattern: {
                                value: validateOnlyNumberStringSigns(),
                                message: 'Solo se permiten caracteres válidos',
                              },
                            })}
                            label="Dirección"
                            type="text"
                            variant="outlined"
                            helperText={errors?.address?.message}
                            error={errors?.address?.message?.length > 0}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {activeStep === 2 && (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography>
                            Hemos enviado un sms a tu número {kyc?.phone} el cuál está registrado, por favor ingresar
                            abajo
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                          <TextField
                            fullWidth
                            // focused={watch('crypto_id') !== ''}
                            {...register('smsCode')}
                            label="Código SMS"
                            type="text"
                            variant="outlined"
                            helperText={errors?.smsCode?.message}
                            error={errors?.smsCode?.message?.length > 0}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </div>
          <div className="modal-body modal-body-center"></div>
          <div className="modal-footer">
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                onClick={handleBack}
                sx={{ mr: 1 }}
                data-dismiss={activeStep === 0 ? 'modal' : null}>
                {activeStep === 0 ? 'Cancelar' : 'Atrás'}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} data-dismiss={activeStep === steps.length ? 'modal' : null}>
                {activeStep === steps.length ? 'Cerrar' : 'Siguiente'}
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </div>
  )
}
