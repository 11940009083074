import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import Layout from '../../core/Layout'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

import { getTypeNotification, createTypeNotification, updateTypeNotification } from '../../services/APICalls'

const initValues = {
  id: '',
  name: '',
  status: '',
}

const FormTypeNotifications = () => {
  // Initial State
  const dispatch = useDispatch()
  const history = useHistory()
  //params que vienen por la url
  const paramsTypeNotificationForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const getDetailsTypeNotification = async () => {
    dispatch(showSpinner('Obteniendo Información de Tipo de Notificación'))
    try {
      const { 0: type_event } = await getTypeNotification(paramsTypeNotificationForm?.idTypeNotification)
      const newTypeNotification = {
        id: type_event.id,
        name: type_event.name,
        active: type_event.active,
      }
      setDefaultValues(newTypeNotification)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.log(error)
      dispatch(hiddeSpinner())
    }
  }

  const onSubmitForm = type_event => {
    const updaterTypeNotification = {
      id: type_event.id,
      name: type_event.name,
      active: type_event.active,
    }
    //debugger;
    if (paramsTypeNotificationForm?.idTypeNotification !== undefined) {
      SendUpdateTypeNotification(updaterTypeNotification)
      goToTypeNotificationList()
      return
    }
    sendCreateTypeNotification(updaterTypeNotification)
    goToTypeNotificationList()
    return
  }

  const SendUpdateTypeNotification = async type_event => {
    dispatch(showSpinner('Modificando Información de Tipo de Notificación'))
    try {
      await updateTypeNotification(type_event.id, type_event)
      dispatch(hiddeSpinner())
      return
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const sendCreateTypeNotification = async type_event => {
    dispatch(showSpinner('Registrando Información de Tipo de Notificación'))
    try {
      await createTypeNotification(type_event)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToTypeNotificationList = () => {
    history.push('/admin/type_notifications')
  }

  useEffect(() => {
    //en caso de que exista un id, se pone en modo editar
    if (paramsTypeNotificationForm?.idTypeNotification !== undefined) {
      getDetailsTypeNotification()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${
        paramsTypeNotificationForm?.idTypeNotification !== undefined ? 'EDICION' : 'REGISTRO'
      } DE TIPO DE NOTIFICACIONES`}
      description={`${
        paramsTypeNotificationForm?.idTypeNotification !== undefined
          ? 'Formulario de Edición'
          : 'Formulario de Registro'
      } de tipo de notificaciones`}
      showBreadcrumb={true}
      currentPage={`${
        paramsTypeNotificationForm?.idTypeNotification !== undefined ? 'EDICION' : 'REGISTRO'
      } DE TIPO DE NOTIFICACIONES`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToTypeNotificationList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormTypeNotifications