import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, IconButton } from '@mui/material'
import { useForm } from 'react-hook-form'

//Icons Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// Custom Component Imports
import Layout from '../../core/Layout'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

// API Imports
import { getProfilesId, createProfiles, updateProfiles } from '../../services/APICalls'

const initValues = {
  id: '',
  name: '',
  description: '',
  active: '',
  capatibilies: '',
}

const FormProfiles = () => {
  //Initial States
  const dispatch = useDispatch()
  const history = useHistory()
  //params que vienen por la url
  const paramsProfilesForm = useParams({})

  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)

  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getDetailsProfiles = async () => {
    dispatch(showSpinner('Obteniendo Información del Perfil'))    
    getProfilesId(paramsProfilesForm?.idProfile).then(data => {
    if (data?.status >= 200 && data?.status <= 299) {
      /*
      const newProfiles = {
        id: data.data.id,
        name: data.data.name,
        description: data.data.description,
        active: data.data.active,
        //Esto es un array
        capatibilies: data.data.capatibilies,
      }
      */
      setDefaultValues(data.data[0])
    } else {
      if (data?.status == 401){
        goToLogin()
        return
      }
      if (data?.status >= 400 || data?.status <= 599){
        //TODO
        //useEffect(() => {
        //  changeToastInfo({ msg: data.message, severity: 'info' })
        //}, [])
      }        
    }
    dispatch(hiddeSpinner())
  })
}
  const onSubmitForm = Profiles => {
    const updaterProfiles = {
      id: Profiles.id,
      name: Profiles.name,
      description: Profiles.description,
      active: Profiles.active,
      //Esto es un array
      capatibilies: Profiles.capatibilies,
    }
    if (paramsProfilesForm?.idProfiles !== undefined) {
      SendUpdateProfiles(updaterProfiles)
      goToProfilesList()
      return
    }
    sendCreateProfiles(updaterProfiles)
    goToProfilesList()
    return
  }

  const SendUpdateProfiles = async Profiles => {
    dispatch(showSpinner('Actualizando el Perfil'))
    try {
      await updateProfiles(Profiles.id, Profiles)
      dispatch(hiddeSpinner())
      return
    } catch (error) {
      dispatch(hiddeSpinner())
      console.error(error)
    }
  }

  const sendCreateProfiles = async Profiles => {
    dispatch(showSpinner('Registrando el Perfil'))
    try {
      await createProfiles(Profiles)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToProfilesList = () => {
    history.push('/admin/profiles')
  }

  useEffect(() => {
    //en caso de que exista un id, se pone en modo editar
    if (paramsProfilesForm?.idProfile !== undefined) {
      getDetailsProfiles()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsProfilesForm?.idProfiles !== undefined ? 'EDICION' : 'REGISTRO'} DE PERFILES`}
      description={`${
        paramsProfilesForm?.idProfile !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de perfiles`}
      showBreadcrumb={true}
      currentPage={`${paramsProfilesForm?.idProfile !== undefined ? 'EDICION' : 'REGISTRO'} DE PERFILES`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToProfilesList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}></form>
      </Box>
    </Layout>
  )
}

export default FormProfiles