// Core Imports
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'

//import FileUpload from "../components/FileUploadDownload/FileUpload";
// Custom Component Imports
import { Box, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { TermsAndConditionsModal } from '../components/Modals/ModalTerms'
import Layout from '../core/Layout'
import { hiddeSpinner, showSpinner } from '../store/slices/uiSlice'

import Switch from '@mui/material/Switch'

// Function Imports
import { isAuthenticated, signout } from '../auth/functions'

// API Impports
import { useTranslation } from 'react-i18next'
import { convertCrypto, getBalanceAccount, getCurrencyPrice, getLegalTerm } from '../services/APICalls'

import HelperModal from './components/HelperModal/HelperModal'

const styles = {
  root: {
    fontFamily: 'sans-serif',
    textAlign: 'center',
  },
  h1: {},
  reader: {
    textAlign: 'center',
    margin: '30px auto',
    maxWidth: 400,
    width: '100%',
  },
  button: {
    backgroundColor: '#f04',
    border: 0,
    borderRadius: 4,
    color: '#fff',
    padding: '5px 10px',
    fontSize: 20,
  },
}

// TODO: Explanation
const DirectConvertions = ({ history }) => {
  const { t } = useTranslation()
  // Initial state
  const dispatch = useDispatch()
  const { user, token, kyc } = isAuthenticated()
  const login = user.login
  const [amount1, setAmount1] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(false)
  const [errorUserInactive, setErrorUserInactive] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorKyc, setErrorKyc] = useState(false)
  const [successKyc, setSuccessKyc] = useState(false)
  const [criptoSource, setCriptoSource] = useState(0)
  const [balanceInAccount, setBalanceInAccount] = useState(undefined)
  const [balanceInAccountUsdt, setBalanceInAccountUsdt] = useState(null)
  const [onHoldBalanceInAccount, setOnHoldBalanceInAccount] = useState(0)
  const [destinyBalanceCorporation, setDestinyBalanceCorporation] = useState(null)
  const [amount2, setAmount2] = useState(0)
  const [criptoDest, setCriptoDest] = useState(0)
  const [isOpenModalCommission, setIsOpenModalCommission] = useState(false)
  const [isOpenModalAntiWashing, setIsOpenModalAntiWashing] = useState(false)
  const [commission, setCommission] = useState([])
  const [anti_washing, setAntiWashingTerm] = useState([])
  const [priceOnSource, setPriceOnSource] = useState(0)
  const [checked, setChecked] = React.useState(false)

  ////FOR SCAN QR
  const [dataFromScan, setDataFromScan] = useState('Sin resultados')

  ////FOR AUTOCLOSE MODAL
  const hideBlockUIModalRef = React.useRef(null)

  ////FOR MODAL SUCCES
  let showBlockUIModalRefFinalSucces = React.useRef(true)
  let hideBlockUIModalRefFinalSucces = React.useRef(null)

  const goToPageBriefCase = () => {
    history.push(`/user/briefcase`)
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }
  const [secondsTocloseModal, setSecondsTocloseModal] = useState(0)
  const [isOpenModalSec, setIsOpenModalSec] = useState(false)

  //Comisiones
  const getDetailsCommission = async () => {
    dispatch(showSpinner())
    getLegalTerm(2)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setCommission(data.data[0])
          //console.log('Este es el termino', data.data[0])
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
    dispatch(hiddeSpinner())
  }

  //Antilavado
  const getDetailsAntiWashing = async () => {
    dispatch(showSpinner())
    getLegalTerm(3)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setAntiWashingTerm(data.data[0])
          //console.log('Este es el termino', data.data[0])
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
    dispatch(hiddeSpinner())
  }

  // React Effects -------------------------------------------------------------------------------

  /*useEffect(() => {
    getUser(user.id, token).then(data => {
      if (data.error) {
        setError(data.error)
      } else {
        localStorage.setItem('currentUser', JSON.stringify(data))
      }
    })
  }, [user, token])*/

  useEffect(() => {
    console.log('user.active:+++', user.active)
    if (user && user.active == false) {
      setErrorUserInactive(true)
    }
  }, [])

  useEffect(() => {
    getDetailsCommission()
    getDetailsAntiWashing()
  }, [])

  useEffect(() => {
    let intervalToClosModal
    if (isOpenModalSec && secondsTocloseModal > 0) {
      intervalToClosModal = setInterval(() => {
        if (secondsTocloseModal > 0) {
          setSecondsTocloseModal(secs => {
            if (secs > 0) {
              return secs - 1
            } else {
              setIsOpenModalSec(false)
              console.log('voy a cerrar 2')
              closeModal()
              clearInterval(intervalToClosModal)
              return 0
            }
          })
        }
      }, 1000)
    }

    return () => {
      clearInterval(intervalToClosModal)
    }
  }, [isOpenModalSec])

  // END React Effects ---------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  const closeAlert = e => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
    setErrorKyc(false)
  }

  const isActive = (history, path) => {
    if (history.location.pathname === path) {
      return 'active'
    } else {
      return ''
    }
  }
  const saveTermsAndCondition = () => {
    setIsOpenModalCommission(false)
    //setValue("terms", true)
  }

  const saveTermsAntiWashing = () => {
    setIsOpenModalAntiWashing(false)
    //setValue("terms", true)
  }

  const declineTerms = () => {
    setIsOpenModalCommission(false)
    setIsOpenModalAntiWashing(false)
    //setValue("terms", false)
  }
  const executeDirectConvert = () => {
    //e.preventDefault();
    console.log(
      'amount1 > ',
      amount1,
      ' balanceInAccount ',
      balanceInAccount,
      ' ||||  amount2> ',
      amount2,
      'destinyBalanceCorporation ',
      destinyBalanceCorporation,
    )
    let newAmount1 = checked == true ? amount1 / priceOnSource : amount1
    if (newAmount1 > balanceInAccount - onHoldBalanceInAccount) {
      setErrorKyc(t('directConversion.errorMessages.insufficientFunds'))
    } else if (amount2 > destinyBalanceCorporation) {
      setErrorKyc(t('directConversion.errorMessages.transactionNotAvailable'))
    } else {
      if (criptoSource > 0 && criptoDest > 0 && amount1 > 0 && criptoSource !== criptoDest && amount2 > 0) {
        let dataCurrencies = { sourceCurrency: criptoSource, amount: newAmount1, destinyCurrency: criptoDest }
        convertCrypto(user.id, dataCurrencies).then(dataR => {
          if (dataR.status == 401) {
            goToLogin()
          }
          console.log('dataR1111:+++++ ', dataR)
          if (dataR.status >= 400 && dataR.status <= 599) {
            setAmount1('')
            setAmount2('')
            setCriptoSource(0)
            setCriptoDest(0)
            setError(dataR.message)
            setErrorKyc(dataR.message)
          }
          if (dataR.status == 200) {
            setAmount1('')
            setAmount2('')
            setCriptoSource(0)
            setCriptoDest(0)
            setError(dataR.message)
            setErrorKyc(dataR.message)
          }
          if (dataR.status > 200 && dataR.status <= 299) {
            console.log('dataR:+++++ ', dataR)
            setAmount1('')
            setAmount2('')
            setCriptoSource(0)
            setCriptoDest(0)
            showBlockUIModalRefFinalSucces.current.click()
          }
        })
      } else {
        setErrorKyc(t('directConversion.errorMessages.selectCryptocurrency'))
      }
    }
  }

  const handleChange = event => {
    setChecked(event.target.checked)
    getFinalCryptosRefresh(criptoSource, criptoDest, amount1, event.target.checked)
  }

  // END Events ----------------------------------------------------------------------------------

  // Methods --------------------------------------------------------------------------------------

  const closeModal = () => {
    setSecondsTocloseModal(0)
    hideBlockUIModalRef.current.click()
  }

  const getBalaceById = criptoSource => {
    console.log('ENTRE44++++')
    dispatch(showSpinner('Obteniendo Balance'))
    if (criptoSource == 0) {
      setBalanceInAccount(undefined)
    } else {
      getBalanceAccount(user.id, criptoSource).then(dataR => {
        if (dataR.status == 401) {
          goToLogin()
        }
        if (dataR.error) {
          setError(dataR.error)
        } else {
          console.log('dataR: ', dataR)
          setBalanceInAccount(dataR.data.balanceInWallet)
          setBalanceInAccountUsdt(dataR.data.usdInWallet)
          if (criptoSource == 1 || criptoSource == 2 || criptoSource == 3) {
            setOnHoldBalanceInAccount(dataR.data.onHoldInWallet)
          }
        }
      })
    }
    //dispatch(hiddeSpinner())
  }

  const getCrytoInFinalAccount = criptoDest => {
    dispatch(showSpinner('Obteniendo Cuenta'))
    var user_founds_corporation = 6
    console.log('ENTRE44++++')
    if (criptoSource == 0) {
      setBalanceInAccount(undefined)
      setBalanceInAccountUsdt(null)
    } else {
      getBalanceAccount(user_founds_corporation, criptoDest).then(dataR => {
        if (dataR.status == 401) {
          goToLogin()
        }
        if (dataR.error) {
          setError(dataR.error)
        } else {
          console.log('dataR: ', dataR)
          setDestinyBalanceCorporation(dataR.balanceInWallet)
        }
      })
    }
    dispatch(hiddeSpinner())
  }

  const getFinalCryptosRefresh = (criptoSource, criptoDest, amount1, newChecked) => {
    dispatch(showSpinner())
    console.log('tipo Moneda Destino:', criptoDest)
    console.log('tipo Moneda Origen:', criptoSource)
    console.log('amount1:', amount1)
    if (criptoSource > 0 && criptoDest > 0 && amount1 > 0 && criptoSource !== criptoDest) {
      getCurrencyPrice(criptoSource).then(dataR => {
        if (dataR.status == 401) {
          goToLogin()
        }
        if (dataR.error) {
          console.log('dataR.error1', dataR.error)
          setError(dataR.error)
        } else {
          setPriceOnSource(dataR.data.usdCurrencyValue)
          console.log('dataR: ', dataR)

          let newAmount1Converted =
            newChecked == true
              ? (amount1 * dataR.data.usdCurrencyValue).toFixed(2)
              : (amount1 / dataR.data.usdCurrencyValue).toFixed(8)
          console.log('newAmount1Converted@@@@@@@@', newAmount1Converted)
          setAmount1(newAmount1Converted)

          let usdTotals = newChecked == true ? newAmount1Converted : dataR.data.usdCurrencyValue * newAmount1Converted
          console.log('usdTotals: ', usdTotals)
          getCurrencyPrice(criptoDest).then(dataR2 => {
            if (dataR2.status == 401) {
              goToLogin()
            }
            if (dataR.error) {
              console.log('dataR.error1', dataR.error)
              setError(dataR.error)
            } else {
              console.log('dataR2: ', dataR2)
              let totals = usdTotals / (dataR2.data.usdCurrencyValue * 1.015)
              setAmount2(totals)
            }
          })
        }
      })
    } else {
      if (amount1 > 0) {
        getCurrencyPrice(criptoSource).then(dataR => {
          let newAmount1Converted =
            newChecked == true
              ? (amount1 * dataR.data.usdCurrencyValue).toFixed(2)
              : (amount1 / dataR.data.usdCurrencyValue).toFixed(8)
          console.log('newAmount1Converted@@@@@@@@', newAmount1Converted)
          setAmount1(newAmount1Converted)
        })
      }
      setAmount2(0)
    }
    dispatch(hiddeSpinner())
  }

  const getFinalCryptos = (criptoSource, criptoDest, amount1) => {
    dispatch(showSpinner())
    console.log('tipo Moneda Destino:', criptoDest)
    console.log('tipo Moneda Origen:', criptoSource)
    console.log('amount1:', amount1)
    if (criptoSource > 0 && criptoDest > 0 && amount1 > 0 && criptoSource !== criptoDest) {
      getCurrencyPrice(criptoSource).then(dataR => {
        if (dataR.status == 401) {
          goToLogin()
        }
        if (dataR.error) {
          console.log('dataR.error1', dataR.error)
          setError(dataR.error)
        } else {
          setPriceOnSource(dataR.data.usdCurrencyValue)
          console.log('dataR: ', dataR)
          let usdTotals = checked == true ? amount1 : dataR.data.usdCurrencyValue * amount1
          console.log('usdTotals: ', usdTotals)
          getCurrencyPrice(criptoDest).then(dataR2 => {
            if (dataR2.status == 401) {
              goToLogin()
            }
            if (dataR.error) {
              console.log('dataR.error1', dataR.error)
              setError(dataR.error)
            } else {
              console.log('dataR2: ', dataR2)
              let totals = usdTotals / (dataR2.data.usdCurrencyValue * 1.015)
              setAmount2(totals)
            }
          })
        }
      })
    } else {
      setAmount2(0)
    }
    dispatch(hiddeSpinner())
  }

  // END Methods ----------------------------------------------------------------------------------
  ///////////este select debe ser restaurado y luego quitar elcomentario/////////////////////////////////////////
  /*<option value="0" defaultValue="0">Seleccione</option>
  <option value="1">BTC</option>
  <option value="2">ETH</option>
  <option value="3">TRX</option>
  <option value="4">PETRO</option>
  <option value="5">USDT-TRC20</option>
  <option value="6">USDT-ERC20</option>*/
  /////////////////////////////////////////////////

  // Render Methods ------------------------------------------------------------------------------

  const modalUserInactiveView = () => {
    return (
      <div style={{ display: errorUserInactive == false ? 'none' : '' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('directConversion.inactiveNotification.title')}
              </h4>
            </div>

            <div>
              <div className="modal-body modal-body-center">{t('directConversion.inactiveNotification.msg')}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalFinalSuccess = () => {
    return (
      <>
        <button
          ref={showBlockUIModalRefFinalSucces}
          type="button"
          data-toggle="modal"
          data-target="#finalSuccesModal"
          style={{ display: 'none' }}
        />
        <div className="modal fade" id="finalSuccesModal" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title modal-body-center" id="myModalLabel">
                  {t('directConversion.ss')}
                </h4>
              </div>
              <div>
                <div className="modal-body modal-body-center">{t('directConversion.requestProcessedSuccessfully')}</div>
                <div className="modal-footer">
                  <button
                    ref={hideBlockUIModalRefFinalSucces}
                    onClick={() => goToPageBriefCase()}
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal">
                    {t('directConversion.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const alertError = error => (
    <div
      className="alert alert-danger col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: error ? '' : 'none' }}>
      {error}
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )

  const alertSuccess = success => (
    <div
      className="alert alert-info col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: success ? '' : 'none' }}>
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
      {success}
    </div>
  )

  // TODO: Move states to a constant
  const userInfo = () => {
    return (
      <div className="container ptb--40">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <div className="ckeckout-left-sidebar">
              <div className="row">
                <div className="checkout-form">
                  <div className="col-xs-12 pb--20">
                    <h2 className="pasadena-section-title-3">{t('directConversion.userAccount.title')}</h2>
                  </div>
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="login" className="normal-label">
                            {t('directConversion.userAccount.subtitle')}
                          </label>
                          <input
                            disabled="disabled"
                            type="email"
                            className="form-control "
                            id="login"
                            placeholder={t('directConversion.userAccount.emailPlaceHolder')}
                            //onChange={handleChange('email')}
                            value={login}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {balanceInAccount >=0 && (
                  <div className="checkout-form">
                    <div className="pasadena-checkout-form-inner">
                      <div className="pasadena-single-box">
                        <div className="form-row">
                          <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                            <label htmlFor="source" className="normal-label">
                              {t('directConversion.userAccount.accountBalance')}
                            </label>
                            <div>
                              <select disabled="true" id="inBalance" className="form-control" value={criptoSource}>
                                <option value="1">
                                  {balanceInAccount} BTC ≈ {balanceInAccountUsdt?.toFixed(10)} $
                                </option>
                                <option value="2">
                                  {balanceInAccount} ETH ≈ {balanceInAccountUsdt?.toFixed(10)} $
                                </option>
                                <option value="3">
                                  {balanceInAccount} TRX ≈ {balanceInAccountUsdt?.toFixed(10)} $
                                </option>
                                <option value="7">
                                  {balanceInAccount} XRP ≈ {balanceInAccountUsdt?.toFixed(10)} $
                                </option>
                                <option value="5">{balanceInAccount} USDT - TRC20</option>
                                <option value="6">{balanceInAccount} USDT - ERC20</option>
                                <option value="8">{balanceInAccount} USDC - ERC20</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                          <label htmlFor="source" className="normal-label">
                            {t('directConversion.userAccount.amountToConvert')}&nbsp;
                          </label>
                          {`${t('directConversion.userAccount.crypto')} `}
                          <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />{' '}
                          USD &nbsp;
                          <HelperModal
                            pictureName={'start_end_p2p'}
                            texBeforeButton={''}
                            title={'Choose Crypto or USD'}
                            description={'You have 2 options to select: "Crypto" or "USD". The Amount or Receive boxes are conditioned by these two options, since they will reflect amounts according to what you have selected here'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                        </div>
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="source" className="normal-label">
                            {t('directConversion.userAccount.origin')}
                          </label>
                          <HelperModal
                            pictureName={'refund1'}
                            texBeforeButton={''}
                            title={'Select Origin Cryptocurrency'}
                            description={'Select the source cryptocurrency to convert. Note: You must have sufficient funds so you can convert to another cryptocurrency. Fees may apply'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                          <select
                            id="source"
                            className="form-control"
                            onChange={e => {
                              setCriptoSource(e.target.value)
                              getBalaceById(e.target.value)
                              getFinalCryptos(e.target.value, criptoDest, amount1)
                              if (e.target.value > 0) {
                                setDisabled(false)
                              }
                            }}
                            value={criptoSource}>
                            <option value="0" defaultValue="0">
                              {t('directConversion.userAccount.select')}
                            </option>
                            <option value="1">BTC</option>
                            <option value="2">ETH</option>
                            <option value="3">TRX</option>
                            <option value="7">XRP</option>
                            <option value="5"> USDT - TRC20</option>
                            <option value="6"> USDT - ERC20</option>
                            <option value="8"> USDC - ERC20</option>
                          </select>
                        </div>
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="source" className="normal-label">
                            {t('directConversion.userAccount.quantity')}
                            {checked == false ? '' : '(USD)'}
                          </label>
                          <HelperModal
                            pictureName={'refund1'}
                            texBeforeButton={''}
                            title={'Amount to Convert'}
                            description={'Enter the amount to convert. Note: This amount cannot exceed the available balance in your cryptocurrency account selected as your funding source. Fees may apply.'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                          <NumericFormat
                            NumericFormat={2}
                            className="form-control "
                            decimalSeparator="."
                            decimalScale={8}
                            id="amount1"
                            placeholder={
                              checked == false
                                ? t('directConversion.userAccount.numericMessageFalse')
                                : t('directConversion.userAccount.numericMessageTrue')
                            }
                            onValueChange={values => {
                              getFinalCryptos(criptoSource, criptoDest, values?.floatValue)
                              setAmount1(values?.floatValue)
                            }}
                            value={amount1}
                            allowNegative={false}
                          />
                        </div>

                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="destiny" className="normal-label">
                            {t('directConversion.userAccount.convertTo')}
                          </label>
                          <HelperModal
                            pictureName={'refund1'}
                            texBeforeButton={''}
                            title={'Destination Cryptocurrency'}
                            description={'Select the destination cryptocurrency to convert from the source cryptocurrency'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                          <select
                            id="destiny"
                            className="form-control"
                            onChange={e => {
                              setCriptoDest(e.target.value)
                              getFinalCryptos(criptoSource, e.target.value, amount1)
                              getCrytoInFinalAccount(e.target.value)
                              //(e.target.value);
                            }}
                            value={criptoDest}>
                            <option value="0" defaultValue="0">
                              {t('directConversion.userAccount.select')}
                            </option>
                            <option value="1">BTC</option>
                            <option value="2">ETH</option>
                            <option value="3">TRX</option>
                            <option value="7">XRP</option>
                            <option value="5"> USDT - TRC20</option>
                            <option value="6"> USDT - ERC20</option>
                            <option value="8"> USDC - ERC20</option>
                          </select>
                        </div>
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="amount2" className="normal-label">
                          {t('directConversion.userAccount.receive')}
                          </label>
                          <HelperModal
                            pictureName={'refund1'}
                            texBeforeButton={''}
                            title={'Converted Amount'}
                            description={'Amount converted by applying the conversion rate between the amount entered in the source cryptocurrency and the destination cryptocurrency. Note: This amount is not editable. You must modify the amount in the source cryptocurrency if you want to change this value'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                          <input
                            type="text"
                            className="form-control "
                            id="amount2"
                            placeholder="cantidad"
                            value={amount2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-lg-12 text-left">
                  <div className="checkout-btn" style={{ marginTop: 20, textAlign: 'left' }}>
                    {amount2 > 0 && (
                      <button
                        onClick={() => {
                          setSecondsTocloseModal(10)
                          setIsOpenModalSec(true)
                        }}
                        data-toggle="modal"
                        data-target="#advertimesentModal"
                        aria-label="advertimesent"
                        className="fv-btn"
                        /*onClick={executeDirectConvert}*/
                      >
                         {t('directConversion.userAccount.convert')}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {alertSuccess(successKyc)}
        {alertError(errorKyc)}
      </div>
    )
  }

  const modalAdvertisement = () => {
    return (
      <div className="modal fade" id="advertimesentModal" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('directConversion.modalAdvertisement.title')}
              </h4>
            </div>
            {kyc.verifiedEmail == true && (
              <div>
                <div className="modal-body modal-body-center">
                  <Typography>
                    {t('directConversion.modalAdvertisement.msg1')}
                    <br />
                    <br />
                    {`${t('directConversion.modalAdvertisement.msg2')} `}
                    <Typography
                      onClick={() => setIsOpenModalCommission(true)}
                      component="span"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: '700',
                        },
                      }}>
                      {t('directConversion.modalAdvertisement.msg3')}
                    </Typography>{' '}
                    {`${t('directConversion.modalAdvertisement.msg4')} `}
                    <Typography
                      onClick={() => setIsOpenModalAntiWashing(true)}
                      component="span"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: '700',
                        },
                      }}>
                      {t('directConversion.modalAdvertisement.msg5')}
                      <br />
                      <br />
                    </Typography>
                    {`${t('directConversion.modalAdvertisement.msg6')} ${secondsTocloseModal} ${t(
                      'directConversion.modalAdvertisement.msg7',
                    )}`}
                  </Typography>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      executeDirectConvert()
                      closeModal()
                    }}>
                    {t('directConversion.modalAdvertisement.accept')}
                    <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                  </button>
                  <button ref={hideBlockUIModalRef} type="button" className="btn btn-default" data-dismiss="modal">
                    {t('directConversion.modalAdvertisement.cancel')}
                  </button>
                </div>
              </div>
            )}
            {kyc.verifiedEmail == false && (
              <div>
                <div className="modal-body modal-body-center">
                  ERROR KYC: Para Continuar, primero debes abrir el enlace de verificacion que enviamos a tu Correo
                  Electronico (Confirmar Correo KYC). Luego de verificar, reinicia la sesion para aplicar los cambios a
                  tu cuenta.
                </div>
                <div className="modal-footer">
                  <button ref={hideBlockUIModalRef} type="button" className="btn btn-default" data-dismiss="modal">
                    Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <TermsAndConditionsModal
          open={isOpenModalCommission}
          handleClose={() => declineTerms()}
          saveTermsAndCondition={saveTermsAndCondition}
          accepetText={t('modalsTermsAndConditions.acceptComissions')}
          denyText={t('modalsTermsAndConditions.denyComission')}
          {...{ tacText: commission ? commission.description : '' }}
        />
        <TermsAndConditionsModal
          open={isOpenModalAntiWashing}
          handleClose={() => declineTerms()}
          saveTermsAndCondition={saveTermsAntiWashing}
          accepetText={t('modalsTermsAndConditions.acceptPolitics')}
          denyText={t('modalsTermsAndConditions.denyPolitics')}
          {...{ tacText: anti_washing.description }}
        />
      </div>
    )
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Layout
      title={t('directConversion.title')?.toUpperCase()}
      description={t('directConversion.title')}
      showBreadcrumb={true}
      currentPage={t('directConversion.title')?.toUpperCase()}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {errorUserInactive == false ? userInfo() : ''}
        {modalFinalSuccess()}
        {modalUserInactiveView()}
        {modalAdvertisement()}
      </Box>
    </Layout>
  )
}
export default DirectConvertions
