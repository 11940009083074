import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton, Tooltip } from '@mui/material'
import { useForm } from 'react-hook-form'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDispatch } from 'react-redux'

//Icons Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns, validateEmail } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

// API Imports
import { getUserProfile, createUser, updateUser } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'

const initValues = {
  id_user: '',
  name_user: '',
  login_user: '',
  active_user: '',
  name_group: '',
}

const profiles = ['ADMINISTRADOR', 'GERENTE', 'VENEZUELA', 'USUARIO']

const FormUsers = () => {
  // Initial state
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  //params que vienen por la url
  const paramsUserForm = useParams()
  console.log("🚀 ~ file: FormUsers.js:43 ~ FormUsers ~ paramsUserForm:", paramsUserForm)
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const getDetailsUser = async () => {
    dispatch(showSpinner('Obteniendo información de usuario'))
    getUserProfile(paramsUserForm?.idUser).then(data => {
      if (data?.status >= 200 && data?.status <= 299) {
        const newUser = {
          id_user: data.data[0].id_user,
          name_user: data.name_user,
          login_user: data.login_user,
          active_user: data.active_user,
          name_group: data.name_group,
        }
        setDefaultValues(newUser)
      } else {
        if (data?.status == 401) {
          //goToLogin()
          return
        }
        if (data?.status >= 400 || data?.status <= 599) {
          //TODO
          //useEffect(() => {
          //  changeToastInfo({ msg: data.message, severity: 'info' })
          //}, [])
        }
      }

      /*if (Array.isArray(data)) {
        setData(data)
      }*/
      dispatch(hiddeSpinner())
    })
  }

  /*try {
      const { 0: user } = await getUserProfile(paramsUserForm?.idUser)
      const newUser = {
        id_user: user.id_user,
        name_user: user.name_user,
        login_user: user.login_user,
        active_user: user.active_user,
        name_group: user.name_group,
      }
      setDefaultValues(newUser)
      dispatch(hiddeSpinner())
    } catch (error) {
      dispatch(hiddeSpinner())
      console.log(error)
    }
    }
    */

  const [profilesUsers, setProfileUser] = React.useState([])

  const handleChange = event => {
    const {
      target: { value },
    } = event
    setProfileUser(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    )
  }

  const onSubmitForm = user => {
    const updateUser = {
      id_user: user.id_user,
      name_user: user.name_user,
      login_user: user.login_user,
      active_user: user.active_user,
      name_group: user.name_group,
    }
    //debugger;
    if (paramsUserForm?.idUser !== undefined) {
      SendUpdateUser(updateUser)
      goToUserstList()
      return
    }
    sendCreateTypeEvent(updateUser)
    goToUserstList()
    return
  }

  const SendUpdateUser = async user => {
    try {
      await updateUser(user.id_user, user)
      return
    } catch (error) {
      console.error(error)
    }
  }

  const sendCreateTypeEvent = async user => {
    try {
      await createUser(user)
    } catch (error) {
      console.error(error)
    }
  }

  const goToUserstList = () => {
    history.push('/admin/users')
  }

  useEffect(() => {
    //en caso de que exista un id_user, se pone en modo editar
    if (paramsUserForm?.idUser !== undefined) {
      getDetailsUser()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={paramsUserForm?.idUser !== undefined ?t('users.userEditForm.title') : t('users.usersRegisterForm.title')}
      description={paramsUserForm?.idUser !== undefined ?t('users.userEditForm.description') : t('users.usersRegisterForm.description')}
      showBreadcrumb={true}
      currentPage={paramsUserForm?.idUser !== undefined ?  t('users.userEditForm.title') :  t('users.usersRegisterForm.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Tooltip title={t('users.back')} arrow>
          <IconButton aria-label="volver" onClick={() => goToUserstList()}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name_user') !== ''}
                {...register('name_user', {
                  required: t('users.form.nameInput.required'),
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label={t('users.form.nameInput.label')}
                type="text"
                variant="outlined"
                helperText={errors?.name_user?.message}
                error={errors?.name_user?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('login_user') !== ''}
                {...register('login_user', {
                  required: t('users.form.emailInput.required'),
                  pattern: {
                    value: validateEmail(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label={t('users.form.emailInput.label')}
                type="text"
                variant="outlined"
                helperText={errors?.login_user?.message}
                error={errors?.login_user?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active_user')?.toString()}
                label={t('users.form.statusInput.label')}
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      active: target.value,
                    })),
                  required: t('users.form.statusInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name_group') !== ''}
                {...register('name_group', {
                  required: t('users.form.groupNameInput.required'),
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label={t('users.form.groupNameInput.label')}
                type="text"
                variant="outlined"
                helperText={errors?.name_group?.message}
                error={errors?.name_group?.message?.length > 0}
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <FormControl sx={{ width: `100%` }}>
                <InputLabel id="demo-multiple-name-label">{t('users.form.profilesUsersInput.label')}</InputLabel>

                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={profilesUsers}
                  onChange={handleChange}
                  input={<OutlinedInput label="Perfiles" />}>
                  {profiles.map(profile => (
                    <MenuItem key={profile} value={profile}>
                      {profile}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                {t('users.form.saveButton')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormUsers
