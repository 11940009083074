// Core Imports
import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

import BriefcaseDataTable from './BriefcaseDataTable'
//import FileUpload from "../components/FileUploadDownload/FileUpload";
// Custom Component Imports
import Layout from '../core/Layout'
import { Box } from '@mui/material'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// Function Imports
import { authenticate, isAuthenticated, signout } from '../auth/functions'

// API Impports
import { useTranslation } from 'react-i18next'

// TODO: Explanation
const BriefCase = ({ history }) => {
  // Initial state
  const { user, token, kyc } = isAuthenticated()
  const { t } = useTranslation()
  console.log('token:++++++', token)
  const [login, setLogin] = useState(user.login)
  const [briefcase, setBriefcase] = useState([])
  console.log('🚀 ~ file: BriefCase.js:28 ~ BriefCase ~ briefcase', briefcase)

  const [gender, setGender] = useState(kyc.kyc_gender_id)

  const [address, setAddress] = useState(kyc.address)

  const [bod, setBod] = useState(new Date(kyc.bod))
  const [city, setCity] = useState(kyc.city)
  const [postalCode, setPostalCode] = useState(kyc.zip)
  const [country, setCountry] = useState(0)

  const [error, setError] = useState(false)
  const [errorUserInactive, setErrorUserInactive] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorKyc, setErrorKyc] = useState(false)
  const [successKyc, setSuccessKyc] = useState(false)
  const [state, setState] = useState(kyc.state_cod)
  ////FOR UPLOAD FILE
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [userSelected, setUserSelected] = useState(user.id ? user.id : [])
  ///FOR MUI TABS
  const [value, setValue] = useState('2');

  // React Effects -------------------------------------------------------------------------------

  /*useEffect(() => {
    getUser(user.id, token).then(data => {
      if (data.error) {
        setError(data.error)
      } else {
        localStorage.setItem('currentUser', JSON.stringify(data))
      }
    })
  }, [user, token])*/

  useEffect(() => {
    setErrorKyc(false)
    setSuccessKyc(false)
  }, [address, gender, city, bod, postalCode, country, state])

  useEffect(() => {
    if (user && user.active == false) {
      setErrorUserInactive(true)
    }
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  const closeAlert = e => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
  }

  const clickSignout = event => {
    event.preventDefault()
    localStorage.removeItem('currentUser')
    signout(() => history.push('/'))
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // END Events ----------------------------------------------------------------------------------

  // Render Methods ------------------------------------------------------------------------------

  const modalUserInactiveView = () => {
    return (
      <div style={{ display: errorUserInactive == false ? 'none' : '' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                Aviso Importante
              </h4>
            </div>

            <div>
              <div className="modal-body modal-body-center">
                Usuario Inactivo por KYC, revise las instrucciones enviadas asu correo electronico
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const alertError = error => (
    <div
      className="alert alert-danger col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: error ? '' : 'none' }}>
      {error}
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )

  const alertSuccess = success => (
    <div
      className="alert alert-info col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: success ? '' : 'none' }}>
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
      {success}
    </div>
  )

  const options = {
    responsive: true,
  }

  const data = {
    labels: briefcase?.map(el => el?.acronym),
    datasets: [
      {
        label: 'Cantidad en $',
        data: briefcase?.map(el => el?.in_usd),
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
        ],
        borderWidth: 1,
      },
    ],
  }
  // TODO: Move states to a constant
  const userInfo = () => {
    return (
      <div className="container ptb--40">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <div className="ckeckout-left-sidebar">
              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="login" className="normal-label">
                            {t('briefCase.userAccount')}
                          </label>
                          <select
                            id="login"
                            className="form-control"
                            onChange={e => {
                              setUserSelected(e.target.value)
                            }}
                            value={userSelected}>
                            <option value={user.id} defaultValue={user.id}>
                              {login}
                            </option>
                            <option value="6">CORPORATIVA (CYBAVO)</option>
                            <option value="9">CORPORATIVA (BINANCE)</option>
                            <option value="7">CORPORATIVA COMISIONES (CYBAVO)</option>
                            <option value="10">CORPORATIVA COMISIONES (BINANCE)</option>
                            <option value="8">FONDOS CLIENTES(CYBAVO)</option>
                            <option value="11">FONDOS CLIENTES(BINANCE)</option>
                            <option value="12">CORPORATIVA (PETRO)</option>
                            <option value="13">COMISIONES (PETRO)</option>
                            <option value="14">FONDOS CLIENTES (PETRO)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {console.log('userSelected <biefcase>:', userSelected)}
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="In Exchange" value="2" />
                      <Tab label="P2P (MY OWN FUNDS)" value="1" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <BriefcaseDataTable source={'1'} userInput={userSelected} briefcase={briefcase} setBriefcase={setBriefcase} />
                  </TabPanel>
                  <TabPanel value="2">
                    <BriefcaseDataTable source={'2'} userInput={userSelected} briefcase={briefcase} setBriefcase={setBriefcase} />
                  </TabPanel>

                </TabContext>
              </Box>

            </div>
            <div className="row">
              <div className="form-row">
                <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                  <label className="normal-label">{t('briefCase.titleGraphic')}</label>
                </div>
              </div>
            </div>
            <div className="row">
              <Box maxWidth="md">
                <Pie data={data} width={200} height={200} options={{ maintainAspectRatio: false }} />
              </Box>
            </div>
          </div>
        </div>
        {alertSuccess(successKyc)}
        {alertError(errorKyc)}
      </div>
    )
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render

  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())

  const isStepOptional = step => {
    return step === 1
  }

  const isStepSkipped = step => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }
  return (
    <Layout
      title={t('briefCase.title')}
      description={t('briefCase.title')}
      showBreadcrumb={true}
      currentPage={t('briefCase.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        {errorUserInactive == false ? userInfo() : ''}
        {modalUserInactiveView()}
      </Box>
    </Layout>
  )
}

export default BriefCase
