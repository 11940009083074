// Core Imports
import React, { useState, useEffect, Fragment } from 'react'
import DataTable from 'react-data-table-component'
import QRCode from 'react-qr-code'
import { useHistory, useParams } from 'react-router-dom'

// Custom Component Imports

import Alert from '../common/Alert'

// Selector Imports
import { isAuthenticated, signout } from '../auth/functions'

// API Imports
import { getTransactionsByUserIdAndOrderId } from '../services/APICalls'
import { useTranslation } from 'react-i18next'

// TODO: Explanation
const TransactionsDataTable = ({ userInput }) => {
  let history = useHistory()
  const { t } = useTranslation()
  const paramsDataTable = useParams({})
  // Init state
  const [transactions, setTransactions] = useState(0)
  const { token } = isAuthenticated()
  const [selectedCountries, setSelectedCountries] = useState('')
  const [filterText, setFilterText] = useState('')
  const [filteredItems, setFilteredItems] = useState('')
  const [dataTableError, setDataTableError] = useState(false)
  const [dataTableSuccess, setDataTableSuccess] = useState(false)
  const [newName, setNewName] = useState('')
  const [newStatus, setNewStatus] = useState('')
  const [userSelected, setUserSelected] = useState(userInput)

  const { user } = isAuthenticated()

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  // Initial columns configuration
  const columns = [
    {
      name: t('transactions.transactionTable.transaction'),
      width: '60px',
      selector: 'id',
      sortable: true,
    },
    {
      name: t('transactions.transactionTable.tx'),
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 12 }}>
          {row.tx_id} {row.vout_index ? `||${row.vout_index}` : ''}{' '}
        </div>
      ),
    },
    {
      name: t('transactions.transactionTable.description'),
      width: '150px',
      selector: 'description_type_transaction',
      sortable: true,
      cell: row => <div style={{ fontSize: 12 }}>{row.description_type_transaction}</div>,
    },
    {
      name: t('transactions.transactionTable.address'),
      selector: 'wallet_address',
      sortable: true,
      cell: row => <div style={{ fontSize: 12 }}>{row.wallet_address}</div>,
    },
    {
      name: t('transactions.transactionTable.amount'),
      width: '115px',
      sortable: true,
      cell: row => (
        <div style={{ fontSize: 12 }}>
          {row.type_transaction == 17
            ? `-`
            : row.type_transaction == 7
            ? `-`
            : row.type_transaction == 23
            ? `-`
            : row.type_transaction == 3
            ? `-`
            : row.type_transaction == 18
            ? `-`
            : row.type_transaction == 30
            ? `-`
            : row.type_transaction == 21
            ? `-`
            : '+'}
          {(row.amount * `1e-${row.decimal}`).toFixed(8)} <span style={{ fontSize: 9 }}>{row.acronym}</span>
        </div>
      ),
    },
    {
      name: t('transactions.transactionTable.date'),
      selector: 'date',
      sortable: true,
      cell: row => <div style={{ fontSize: 12 }}>{row.date}</div>,
    },
    {
      name: t('transactions.transactionTable.actions'),
      cell: row => actionsBtns(row),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    if (transactions) {
      const items =
        filterText == ''
          ? transactions
          : transactions.filter(item => item.name && item.name.toUpperCase().includes(filterText.toUpperCase()))
      setFilteredItems(items)
    }
  }, [filterText, transactions])

  useEffect(() => {
    if (userInput) {
      loadTransactions(userInput)
    } else {
      setUserSelected(user.id)
    }
  }, [userInput])

  // END React Effects ---------------------------------------------------------------------------

  // Methods -------------------------------------------------------------------------------------

  const loadTransactions = userInput => {
    getTransactionsByUserIdAndOrderId(userInput, paramsDataTable?.order_id, paramsDataTable?.cripto_id).then(data => {
      if (data.status == 401) {
        goToLogin()
      }
      if (data.status >= 400 && data.status <= 599) {
        //TODO
      } else {
        setTransactions(data?.data)
      }
    })
  }

  // END Methods ---------------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  // END Events ----------------------------------------------------------------------------------

  // Render Methods ------------------------------------------------------------------------------

  const actionsBtns = row => (
    <>
      <button type="button" className="btn btn-default btn-sm btn-icon" aria-label="qr">
        <span className="glyphicon glyphicon glyphicon-list" aria-hidden="true"></span>
      </button>
    </>
  )

  const FilterComponent = filterText => (
    <div className="container ptb--0">
      <div className="row">
        <div className="col-md-8 col-lg-8">
          <div className="ckeckout-left-sidebar">
            <div className="row">
              <div className="checkout-form">
                <div className="pasadena-checkout-form-inner">
                  <div className="pasadena-single-box">
                    <div className="form-row">
                      <div className="form-group col-xs-12 col-sm-9 col-lg-9">
                        <div className="">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={ t('transactions.placeHolderSearch')}
                              aria-describedby="sizing-addon2"
                              value={filterText}
                              onChange={e => setFilterText(e.target.value)}
                              style={{ borderRadius: '0' }}
                            />
                            <span className="input-group-addon" id="sizing-addon2" style={{ borderRadius: '0' }}>
                            { t('transactions.searchButton')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Fragment>
      <div className="row">
        <Alert
          error={dataTableError}
          success={dataTableSuccess}
          onError={value => {
            setDataTableError(value)
          }}
          onSuccess={value => {
            setDataTableSuccess(value)
          }}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredItems}
        defaultSortField="name"
        pagination
        highlightOnHover
        pointerOnHover
        subHeader
        subHeaderComponent={[FilterComponent()]}
      />
    </Fragment>
  )
}

export default TransactionsDataTable
