// Core Imports
import React, { useState, useEffect } from 'react'

import ReceiveCryptoDataTable from './ReceiveCryptoDataTable'
//import FileUpload from "../components/FileUploadDownload/FileUpload";
// Custom Component Imports
import Layout from '../core/Layout'
import { Box } from '@mui/material'
// Function Imports
import { isAuthenticated, signout } from '../auth/functions'

// API Impports
import { getUser } from '../services/APICalls'
import { Container } from '@mui/system'
import { useTranslation } from 'react-i18next'

// TODO: Explanation
const ReceiveCrypto = ({ history }) => {
  // Initial state
  const { user, token, kyc } = isAuthenticated()
  const { t } = useTranslation()
  console.log('user:+++++', user)
  const [login, setLogin] = useState(user.login)

  const [gender, setGender] = useState(kyc.kyc_gender_id)

  const [address, setAddress] = useState(kyc.address)

  const [bod, setBod] = useState(new Date(kyc.bod))
  const [city, setCity] = useState(kyc.city)
  const [postalCode, setPostalCode] = useState(kyc.zip)
  const [country, setCountry] = useState(0)

  const [error, setError] = useState(false)
  const [errorUserInactive, setErrorUserInactive] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorKyc, setErrorKyc] = useState(false)
  const [successKyc, setSuccessKyc] = useState(false)
  const [state, setState] = useState(kyc.state_cod)
  ////FOR UPLOAD FILE
  const [selectedFiles, setSelectedFiles] = useState(undefined)
  const [userSelected, setUserSelected] = useState(user.id)

  // React Effects -------------------------------------------------------------------------------

  /*useEffect(() => {
    getUser(user.id, token).then(data => {
      if (data.error) {
        setError(data.error)
      } else {
        localStorage.setItem('currentUser', JSON.stringify(data))
      }
    })
  }, [user, token])*/

  useEffect(() => {
    setErrorKyc(false)
    setSuccessKyc(false)
  }, [address, gender, city, bod, postalCode, country, state])

  useEffect(() => {
    if (user && user.active == false) {
      setErrorUserInactive(true)
    }
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  const closeAlert = e => {
    e.preventDefault()
    setError(false)
    setSuccess(false)
  }

  const clickSignout = event => {
    event.preventDefault()
    localStorage.removeItem('currentUser')
    signout(() => history.push('/'))
  }

  // END Events ----------------------------------------------------------------------------------

  // Render Methods ------------------------------------------------------------------------------

  const modalUserInactiveView = () => {
    return (
      <div style={{ display: errorUserInactive == false ? 'none' : '' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                Aviso Importante
              </h4>
            </div>

            <div>
              <div className="modal-body modal-body-center">
                Usuario Inactivo por KYC, usted sera contactado por nuestro personal para confirmar sus Datos
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const alertError = error => (
    <div
      className="alert alert-danger col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: error ? '' : 'none' }}>
      {error}
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )

  const alertSuccess = success => (
    <div
      className="alert alert-info col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: success ? '' : 'none' }}>
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
      {success}
    </div>
  )

  // TODO: Move states to a constant
  const userInfo = () => {
    return (
      <div className="container ptb--40">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <div className="ckeckout-left-sidebar">
              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="login" className="normal-label">
                            {t('receiveCrypto.userAccount')}
                          </label>
                          <select
                            id="login"
                            disabled
                            className="form-control"
                            onChange={e => {
                              setUserSelected(e.target.value)
                            }}
                            value={userSelected}>
                            <option value={user.id} defaultValue={user.id}>
                              {login}
                            </option>
                          </select>
                        </div>
                      </div>

                      <Container maxWidth="md">
                        <ReceiveCryptoDataTable userInput={userSelected} />
                      </Container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {alertSuccess(successKyc)}
        {alertError(errorKyc)}
      </div>
    )
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Layout
      title={t('receiveCrypto.title')}
      description={t('receiveCrypto.title')}
      showBreadcrumb={true}
      currentPage={t('receiveCrypto.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {errorUserInactive == false ? userInfo() : ''}
        {modalUserInactiveView()}
      </Box>
    </Layout>
  )
}

export default ReceiveCrypto
