import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'

//Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

//API Imports
import { getResolutionAreaAPIList, createResolutionArea, updateResolutionArea } from '../../services/APICalls'

const initValues = {
  id: '',
  name: '',
  description: '',
  active: '',
}

const FormResolutionArea = () => {
  // Initial State
  const dispatch = useDispatch()
  const history = useHistory()
  //params que vienen por la url
  const paramsResolutionAreaForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getDetailsResolutionArea = async () => {
    dispatch(showSpinner('Obteniendo Información del Area Resolutoria'))
    getResolutionAreaAPIList(paramsResolutionAreaForm?.idResolutionArea)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setDefaultValues(data.data[0])
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const onSubmitForm = ResolutionArea => {
    const updaterResolutionArea = {
      id: ResolutionArea.id,
      name: ResolutionArea.name,
      description: ResolutionArea.description,
      active: ResolutionArea.active,
    }
    if (paramsResolutionAreaForm?.idResolutionArea !== undefined) {
      SendUpdateResolutionArea(updaterResolutionArea)
      goToResolutionAreaList()
      return
    }
    sendCreateResolutionArea(updaterResolutionArea)
    goToResolutionAreaList()
    return
  }

  const SendUpdateResolutionArea = async ResolutionArea => {
    dispatch(showSpinner('Modificando Información del Area Resolutoria'))
    try {
      await updateResolutionArea(ResolutionArea.id, ResolutionArea)
      dispatch(hiddeSpinner())
      return
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const sendCreateResolutionArea = async ResolutionArea => {
    dispatch(showSpinner('Registrando Información del Area Resolutoria'))
    try {
      await createResolutionArea(ResolutionArea)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToResolutionAreaList = () => {
    history.push('/admin/resolution_area')
  }

  useEffect(() => {
    //en caso de que exista un id, se pone en modo editar
    if (paramsResolutionAreaForm?.idResolutionArea !== undefined) {
      getDetailsResolutionArea()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsResolutionAreaForm?.idResolutionArea !== undefined ? 'EDICION' : 'REGISTRO'} DE Area Resolutoria`}
      description={`${
        paramsResolutionAreaForm?.idResolutionArea !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de Area Resolutoria`}
      showBreadcrumb={true}
      currentPage={`${paramsResolutionAreaForm?.idResolutionArea !== undefined ? 'EDICION' : 'REGISTRO'} DE Area Resolutoria`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToResolutionAreaList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Descripción"
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormResolutionArea
