// Core Imports
import React, { useState, useEffect } from 'react'
import { QrReader } from 'react-qr-reader'
import { NumericFormat } from 'react-number-format'
import { useHistory, useParams } from 'react-router-dom'
//import FileUpload from "../components/FileUploadDownload/FileUpload";
// Custom Component Imports
import Layout from '../core/Layout'
import { Box } from '@mui/material'
import Switch from '@mui/material/Switch';
///FOR RADIO BUTTON
import { pink } from '@mui/material/colors';
import Radio from '@mui/material/Radio';

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

// Function Imports
import { authenticate, isAuthenticated, signout } from '../auth/functions'
import { hiddeSpinner, showSpinner } from '../store/slices/uiSlice'
import { useTranslation } from 'react-i18next'

// API Impports
import {
  getUser,
  getBalanceAccountOnlyCrypto,
  getBalanceAccountOnlyCryptoP2p,
  newSendCrypto,
  newSendCryptoExchangeToP2p,
  newSendCryptoP2pToExchange,
  getMemoByUserAndCrypto,
  getAddressByUserAndCrypto,
  getCurrencyAddressVerify,
  createNewAddressInWlist,
  requestVerifyAddressWlist,
  smsVerifyAddressInWlist,
  getWListUsers,
  getWListByEmail,
  getCurrencyPrice,
  getLegalTerm,
} from '../services/APICalls'
import { ConstructionOutlined } from '@mui/icons-material'
import { TermsAndConditionsModal } from '../components/Modals/ModalTerms'
import { useDispatch, useSelector } from 'react-redux'

import HelperModal from './components/HelperModal/HelperModal'

const styles = {
  root: {
    fontFamily: 'sans-serif',
    textAlign: 'center',
  },
  h1: {},
  reader: {
    textAlign: 'center',
    margin: '30px auto',
    maxWidth: 400,
    width: '100%',
  },
  reader2: {
    textAlign: 'center',
    margin: '30px auto',
    maxWidth: 400,
    width: '100%',
  },
  button: {
    backgroundColor: '#f04',
    border: 0,
    borderRadius: 4,
    color: '#fff',
    padding: '5px 10px',
    fontSize: 20,
  },
  button2: {
    backgroundColor: '#20a499',
    border: 0,
    borderRadius: 4,
    color: '#fff',
    padding: '5px 10px',
    fontSize: 20,
    narginRight: '5px',
  },
  button3: {
    backgroundColor: '#20a499',
    border: 0,
    borderRadius: 4,
    color: '#fff',
    padding: '5px 10px',
    fontSize: 12,
  },
  button4: {
    backgroundColor: '#f04',
    border: 0,
    borderRadius: 4,
    color: '#fff',
    padding: '5px 10px',
    fontSize: 12,
  },
}

// TODO: Explanation
const SendCrypto = () => {
  // Initial state
  const { t } = useTranslation()
  let history = useHistory()
  const { user, token, kyc } = isAuthenticated()
  const [login, setLogin] = useState(user.login)

  const [amount1, setAmount1] = useState('')

  const [disabled, setDisabled] = useState(true)
  const [disabledCodeSms, setDisabledCodeSms] = useState(true)

  const [gender, setGender] = useState(kyc.kyc_gender_id)

  const [error, setError] = useState(false)
  const [errorUserInactive, setErrorUserInactive] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorKyc, setErrorKyc] = useState(false)
  const [successKyc, setSuccessKyc] = useState(false)
  const [balanceInAccount, setBalanceInAccount] = useState(undefined)
  const [criptoSource, setCriptoSource] = useState(0)
  const [destinyUser, setDestinyUser] = useState('')
  const [statusNewAddress, setStatusNewAddress] = useState(false)
  const [isInvalidAddress, setIsInvalidAddress] = useState(false)
  const [isValidAddress, setIsValidAddress] = useState(false)
  const [smsCode, setSmsCode] = useState('')
  const [errorInCode, setErrorInCode] = useState(false)
  const [succesCode, setSuccesCode] = useState(false)
  const [myWlist, setMyWlist] = useState(false)

  const [dataFromLogin, setDataFromLogin] = useState(false)
  const [aditionalForRequest, setAditionalForRequest] = useState(0)

  const [priceOnSource, setPriceOnSource] = useState(0)
  const [checked, setChecked] = React.useState(false);

  ////FOR SCAN QR
  const [dataFromScan, setDataFromScan] = useState('')
  const [dataMemo, setDataMemo] = useState('')
  const [memoFromScan, setMemoFromScan] = useState('Sin resultados')
  const [nickname, setNickname] = useState('')
  const [addedId, setAddedId] = useState('')

  ////FOR AUTOCLOSE MODAL
  const hideBlockUIModalRef = React.useRef(null)

  ////FOR AUTOOPEN MODAL
  const showBlockUIModalRef = React.useRef(true)

  ////FOR MODAL SUCCES
  let showBlockUIModalRefFinalSucces = React.useRef(true)
  let hideBlockUIModalRefFinalSucces = React.useRef(null)

  ///FOR RADIO BUTTON
  const [selectedValueRadio, setSelectedValueRadio] = useState('a');


  const dispatch = useDispatch()
  const [commission, setCommission] = useState([])
  const [anti_washing, setAntiWashingTerm] = useState([])
  const [isOpenModalCommission, setIsOpenModalCommission] = useState(false)
  const [isOpenModalAntiWashing, setIsOpenModalAntiWashing] = useState(false)

  const goToPageBriefCase = () => {
    history.push(`/user/briefcase`)
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  ////FOR ADDRESS VALIDATE BY SMS ANDD EMAIL

  console.log('KYC:+++', kyc)

  //Comisiones
  const getDetailsCommission = async () => {
    dispatch(showSpinner())
    getLegalTerm(2)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setCommission(data.data[0])
          //console.log('Este es el termino', data.data[0])
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
    dispatch(hiddeSpinner())
  }

  //Antilavado
  const getDetailsAntiWashing = async () => {
    dispatch(showSpinner())
    getLegalTerm(3)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setAntiWashingTerm(data.data[0])
          //console.log('Este es el termino', data.data[0])
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
    dispatch(hiddeSpinner())

  }

  const saveTermsAndCondition = () => {
    setIsOpenModalCommission(false)
    //setValue("terms", true)
  }

  const saveTermsAntiWashing = () => {
    setIsOpenModalAntiWashing(false)
    //setValue("terms", true)
  }

  const declineTerms = () => {
    setIsOpenModalCommission(false)
    setIsOpenModalAntiWashing(false)
    //setValue("terms", false)
  }

  // React Effects -------------------------------------------------------------------------------
  useEffect(() => {
    getDetailsCommission()
    getDetailsAntiWashing()
  }, [])

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    let criptoSource0 = criptoSource * 1
    if (criptoSource0 > 0 && destinyUser.length > 4) {
      const timeOutId = setTimeout(() => getOneWListByEmail(destinyUser), 900)
      return () => clearTimeout(timeOutId)
    }
    if (criptoSource0 == 0 && destinyUser.length > 0) {
      setErrorKyc(t('sendCriptoCurrencies.error.selectCrypto'))
      setDestinyUser('')
    }
  }, [destinyUser])

  useEffect(() => {
    if (user && user.active == false) {
      setErrorUserInactive(true)
    }
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  const handleChange = (event) => {
    setChecked(event.target.checked);
    getAmountRefresh(event.target.checked);
  };

  const handleChangeRadio = (event) => {
    setSelectedValueRadio(event.target.value);
    setBalanceInAccount(undefined);
    setCriptoSource(0);
  };

  const controlProps = (item) => ({
    checked: selectedValueRadio === item,
    onChange: handleChangeRadio,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const closeAlert = e => {
    e.preventDefault()
    setErrorKyc(false)
    setSuccessKyc(false)
  }

  const isActive = (history, path) => {
    if (history.location.pathname === path) {
      return 'active'
    } else {
      return ''
    }
  }

  // END Events ----------------------------------------------------------------------------------

  // Methods --------------------------------------------------------------------------------------

  const closeModal = () => {
    console.log('ENTRE CERRAR MODAL')
    hideBlockUIModalRef.current.click()
  }
  const OpenModal = () => {
    console.log('ENTRE ABRIR MODAL')
    showBlockUIModalRef.current.click()
  }

  const getOneWListByEmail = email => {
    //GET RATE FOR TYPE TAX 8 = "COMISION COMPRAR CRIPTO CON TARJETA DEBITO"
    getWListByEmail(email, criptoSource).then(dataUserDestiny => {
      if (dataUserDestiny.status == 401) {
        goToLogin()
        return
      }
      console.log('dataUserDestiny:+++', dataUserDestiny[0])
      if (dataUserDestiny[0]) {
        setDataFromScan(dataUserDestiny[0].address)
        setDataFromLogin(true)
      }
    })
  }

  const loadWlists = () => {
    console.log('@@@@CARGANDO WHITELIST')
    getWListUsers(user.id).then(productData => {
      if (productData.status == 401) {
        goToLogin()
        return
      }
      if (productData.error) {
        console.log('ERROR AL CARGAR WHITE LIST 00000001')
        //setError(productData.error);
      } else {
        console.log('@@@@@@@WHITELISTproductData', productData)
        setMyWlist(productData.data)
      }
    })
  }

  const getBalanceById = criptoSource => {
    console.log('ENTRE44++++0')
    if (criptoSource == 0) {
      setBalanceInAccount(undefined)
    } else {
      if (selectedValueRadio == 'a' || selectedValueRadio == 'b') {
        getBalanceAccountOnlyCrypto(user.id, criptoSource).then(dataR => {
          dispatch(showSpinner())
          if (dataR.error) {
            dispatch(hiddeSpinner())
            setError(dataR.error)
          } else {
            console.log('dataR getBalanceAccountOnlyCrypto: ', dataR)
            dispatch(hiddeSpinner())
            setBalanceInAccount(dataR?.data?.balanceInWallet)
          }
        })
        getCurrencyPrice(criptoSource).then(data1 => {
          dispatch(showSpinner())
          if (data1.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data1.status >= 401 && data1.status <= 599) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data1.status >= 201 && data1.status <= 299) {
            let requerid_crypto = criptoSource == 2 ? 50 / (data1.data.usdCurrencyValue * 1.015) : criptoSource == 6 ? 50 : 0;
            dispatch(hiddeSpinner())
            setAditionalForRequest(criptoSource == 2 ? requerid_crypto : 0);
          }
        })
      }
      if (selectedValueRadio == 'c') {
        getBalanceAccountOnlyCryptoP2p(user.id, criptoSource).then(dataR => {
          dispatch(showSpinner())
          if (dataR.error) {
            dispatch(hiddeSpinner())
            setError(dataR.error)
          } else {
            console.log('dataR getBalanceAccountOnlyCrypto: ', dataR)
            dispatch(hiddeSpinner())
            setBalanceInAccount(dataR?.data?.balanceInWallet)
          }
        })
        getCurrencyPrice(criptoSource).then(data1 => {

          if (data1.status == 401) {
            goToLogin()
            return
          }
          if (data1.status >= 401 && data1.status <= 599) {
            goToLogin()
            return
          }
          if (data1.status >= 201 && data1.status <= 299) {
            let requerid_crypto = criptoSource == 2 ? 50 / (data1.data.usdCurrencyValue * 1.015) : criptoSource == 6 ? 50 : 0;
            setAditionalForRequest(criptoSource == 2 ? requerid_crypto : 0);
          }
        })
      }
    }
  }

  const createWlist = async (address, criptoSource, token, nickname, dataMemo) => {

    createNewAddressInWlist(address, criptoSource, user.id, token, dataMemo).then(dataR3 => {
      if (dataR3.status == 401) {
        goToLogin()
        return
      }
      if (dataR3.status >= 400 && dataR3.status <= 599) {
        setError(dataR3.message)
        return
      }
      console.log('dataR3 CREATE WHITELIST', dataR3)
      requestVerifyAddressWlist(address, kyc.phone, kyc.email, token).then(dataR30 => {
        if (dataR30.status == 401) {
          goToLogin()
          return
        }
        if (dataR30.status >= 400 && dataR30.status <= 599) {
          setError(dataR30.message)
        }
      })
    })

  }

  const getAddressInWlist = async (criptoSource, address) => {
    console.log('ENTRE44++++1')
    if (criptoSource == 0) {
      setBalanceInAccount(undefined)
    } else {
      await getAddressByUserAndCrypto(user.id, criptoSource, address).then(dataR => {
        if (dataR.status == 401) {
          goToLogin()
          return
        }
        if (dataR.status >= 400 && dataR.status <= 599) {
          setError(dataR.message)
          return
        } else {
          console.log('dataR ENCONTRADA: ', dataR)
          if (dataR.data?.sms_verified == true) {
            setStatusNewAddress(true)
          }
          //setStatusNewAddress(sms_verifiedaddress?.length > 10 ? true : false)
          if (!dataR.data?.sms_verified) {
            ///verificar si es correcta con respecto a la red
            console.log('ADDRESS NO ENCONTRADA EN LISTA BLANCA/NO VERIFICADA, SE PROCEDE A COMPROBAR')
            getCurrencyAddressVerify(criptoSource, address).then(dataR2 => {
              if (dataR2.status == 401) {
                goToLogin()
                return
              }
              if (dataR.status >= 400 && dataR.status <= 599) {
                setError(dataR.message)
                return
              }
              if (dataR2.data.result == false) {
                setIsInvalidAddress(true)
              }
              if (dataR2.data.result == true) {
                setIsValidAddress(true)
                console.log('kyc.email:::', kyc.email, 'kyc.phone:::', kyc.phone)

                createNewAddressInWlist(address, criptoSource, user.id, token, dataMemo).then(dataR3 => {
                  console.log('dataR3 CREATE WHITELIST', dataR3)
                  if (dataR3.status == 401) {
                    goToLogin()
                    return
                  }
                  if (dataR3.status >= 400 && dataR3.status <= 599) {
                    console.log('OURRIO ERROR Y MANDE A CERRAR')
                    setError(dataR3.message)
                    setErrorKyc(dataR3.message)
                    closeModal()
                    return
                  }
                  else {
                    setAddedId(dataR3.id)
                    requestVerifyAddressWlist(address, kyc.phone, kyc.email, token).then(dataR3 => {
                      if (dataR3.status == 401) {
                        goToLogin()
                        return
                      }
                      if (dataR.status >= 400 && dataR.status <= 599) {
                        setError(dataR.message)
                      }
                    })

                  }



                })
              }
            })
          }
          else {
            console.log('SI ENCONTRE LA DATA');
          }
        }
      })
    }
  }

  const executeSend = () => {

    //e.preventDefault();
    console.log('amount1:::', amount1, 'aditionalForRequest::::', aditionalForRequest)
    let newAmount1 = checked == true ? amount1 / priceOnSource : amount1;
    let NewAmountRequerid = (newAmount1 * 1) + (aditionalForRequest * 1)
    console.log('amount1 > ', amount1, 'balanceInAccount', balanceInAccount, 'newAmount1', newAmount1)
    if (selectedValueRadio == 'a') {
      if (NewAmountRequerid > balanceInAccount) {
        setErrorKyc(t('sendCriptoCurrencies.error.amountRequired'))
      } else {
        if (criptoSource > 0 && newAmount1 > 0) {
          let newDataMemo = dataMemo == '' ? 0 : dataMemo;
          let dataCurrencies = { cripto_id: criptoSource, quantity: newAmount1, address: dataFromScan, memo: newDataMemo, nickname: nickname }
          console.log('dataCurrencies::::', dataCurrencies)
          newSendCrypto(user.id, dataCurrencies).then(dataR => {
            if (dataR.status == 401) {
              goToLogin()
            }
            //console.log('dataR Sended:++',dataR.error.description)
            if (dataR.status >= 400 && dataR.status <= 499) {
              console.log('dataR:+++++ ', dataR)
              setAmount1('')
              setCriptoSource(0)
              setErrorKyc(dataR.message)
            }
            if (dataR.status >= 200 && dataR.status <= 299) {
              console.log('dataR:+++++ ', dataR)
              showBlockUIModalRefFinalSucces.current.click()
              setAmount1('')
              setCriptoSource(0)
            }
          })
        } else {
          setErrorKyc(t('sendCriptoCurrencies.error.requiredInputs'))
        }
      }
    }
    if (selectedValueRadio == 'b') {
      if (newAmount1 >= balanceInAccount) {
        setErrorKyc(t('sendCriptoCurrencies.error.amountRequired'))
      } else {
        if (criptoSource > 0 && newAmount1 > 0) {
          let newDataMemo = dataMemo == '' ? 0 : dataMemo;
          let dataCurrencies = { cripto_id: criptoSource, quantity: newAmount1, address: dataFromScan, memo: newDataMemo, nickname: nickname }
          console.log('dataCurrencies::::', dataCurrencies)
          newSendCryptoExchangeToP2p(user.id, dataCurrencies).then(dataR => {
            if (dataR.status == 401) {
              goToLogin()
            }
            //console.log('dataR Sended:++',dataR.error.description)
            if (dataR.status >= 400 && dataR.status <= 499) {
              console.log('dataR:+++++ ', dataR)
              setAmount1('')
              setCriptoSource(0)
              setErrorKyc(dataR.message)
            }
            if (dataR.status >= 200 && dataR.status <= 299) {
              console.log('dataR:+++++ ', dataR)
              showBlockUIModalRefFinalSucces.current.click()
              setAmount1('')
              setCriptoSource(0)
            }
          })
        } else {
          setErrorKyc(t('sendCriptoCurrencies.error.requiredInputs'))
        }
      }
    }
    if (selectedValueRadio == 'c') {
      if (newAmount1 >= balanceInAccount) {
        setErrorKyc(t('sendCriptoCurrencies.error.amountRequired'))
      } else {
        if (criptoSource > 0 && newAmount1 > 0) {
          let newDataMemo = dataMemo == '' ? 0 : dataMemo;
          let dataCurrencies = { cripto_id: criptoSource, quantity: newAmount1, address: dataFromScan, memo: newDataMemo, nickname: nickname }
          console.log('dataCurrencies::::', dataCurrencies)
          newSendCryptoP2pToExchange(user.id, dataCurrencies).then(dataR => {
            if (dataR.status == 401) {
              goToLogin()
            }
            //console.log('dataR Sended:++',dataR.error.description)
            if (dataR.status >= 400 && dataR.status <= 499) {
              console.log('dataR:+++++ ', dataR)
              setAmount1('')
              setCriptoSource(0)
              setErrorKyc(dataR.message)
            }
            if (dataR.status >= 200 && dataR.status <= 299) {
              console.log('dataR:+++++ ', dataR)
              showBlockUIModalRefFinalSucces.current.click()
              setAmount1('')
              setCriptoSource(0)
            }
          })
        } else {
          setErrorKyc(t('sendCriptoCurrencies.error.requiredInputs'))
        }
      }
    }

  }

  const getAmountRefresh = (newChecked) => {
    if (amount1 > 0) {
      getCurrencyPrice(criptoSource).then(dataR => {
        let newAmount1Converted = newChecked == true ? (amount1 * dataR.data.usdCurrencyValue).toFixed(2) : (amount1 / dataR.data.usdCurrencyValue).toFixed(8);
        console.log('newAmount1Converted@@@@@@@@', newAmount1Converted)
        setAmount1(newAmount1Converted)
      })
    }
  }

  const getCryptoRefresh = (criptoSource) => {

    getCurrencyPrice(criptoSource).then(dataR1 => {
      if (dataR1.status == 401) {
        goToLogin()
        return
      }
      console.log('+++dataR11+++', dataR1)
      if (dataR1.status >= 400 && dataR1.status <= 599) {
        ///TODO
      }
      if (dataR1.status >= 200 && dataR1.status <= 299) {
        setPriceOnSource(dataR1.data.usdCurrencyValue)
      }
    })
  }

  // END Methods ----------------------------------------------------------------------------------

  // Render Methods ------------------------------------------------------------------------------

  const modalUserInactiveView = () => {
    return (
      <div style={{ display: errorUserInactive == false ? 'none' : '' }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('sendCriptoCurrencies.address.modalUserInactiveView.title')}
              </h4>
            </div>

            <div>
              <div className="modal-body modal-body-center">
                {t('sendCriptoCurrencies.address.modalUserInactiveView.msg')}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalAddAddress = () => {
    return (
      <div className="modal fade" id="addModal" role="dialog" aria-labelledby="myModalLabel2">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('sendCriptoCurrencies.address.modalAddAddress.title')}
              </h4>
            </div>
            <div className="modal-body modal-body-center">
              <div className="form-row">
                <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                  <div style={styles.reader2}>{dataFromScan}</div>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                  {!statusNewAddress && !isInvalidAddress && !isValidAddress && (
                    <div style={styles.reader}>
                      <button
                        style={styles.button2}
                        onClick={() => {
                          getAddressInWlist(criptoSource, dataFromScan)
                        }}>
                        {t('sendCriptoCurrencies.address.modalAddAddress.button')} <span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span>
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {isValidAddress && (
                <div className="form-row">
                  <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                    <button
                      type="button"
                      style={styles.button3}
                      onClick={() => {
                        //setDataFromScan('Escaneando');
                      }}>
                      {t('sendCriptoCurrencies.address.modalAddAddress.isValid')} <span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                    </button>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                    {`${t('sendCriptoCurrencies.address.modalAddAddress.enterCode')} ${kyc?.phone}`}:
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                    <input
                      type="text"
                      className="form-control "
                      id="smsCode"
                      placeholder=""
                      onChange={e => {
                        setErrorInCode(false)
                        setSmsCode(e.target.value)
                        let theCode = e.target.value
                        if (theCode.length == 5) {
                          smsVerifyAddressInWlist(theCode, dataFromScan, token, nickname, dataMemo).then(dataR1x => {
                            if (dataR1x.status == 401) {
                              goToLogin();
                              return
                            }
                            if (dataR1x.status >= 400 && dataR1x.status <= 599) {
                              setErrorInCode(dataR1x.message)
                              return
                            }
                            if (dataR1x.status >= 200 && dataR1x.status <= 299) {
                              setSuccesCode(true)
                              //closeModal();
                            }
                            console.log('dataR1x:::', dataR1x)
                          })
                        }
                      }}
                      value={smsCode}
                    />
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                    {errorInCode && (
                      <div>
                        <b>{t('sendCriptoCurrencies.address.modalAddAddress.invalidCode')}</b>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {isInvalidAddress && (
                <div style={styles.reader2}>
                  <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                    <button
                      type="button"
                      style={styles.button4}
                      onClick={() => {
                        //setDataFromScan('Escaneando');
                      }}>
                      {t('sendCriptoCurrencies.address.modalAddAddress.isValid')}<span className="glyphicon glyphicon-remove" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              )}
              {statusNewAddress && (
                <div className="form-row">
                  <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                    <button
                      type="button"
                      style={styles.button3}
                      onClick={() => {
                        //setDataFromScan('Escaneando');
                      }}>
                      {t('sendCriptoCurrencies.address.modalAddAddress.alreadyAffiliated')}<span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                    </button>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                    <button
                      type="button"
                      style={styles.button3}
                      onClick={() => {
                        //setDataFromScan('Escaneando');
                      }}>
                      {t('sendCriptoCurrencies.address.modalAddAddress.itIsVerified')}<span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                ref={hideBlockUIModalRef}
                type="button"
                className="btn btn-default"
                onClick={() => {
                  //setDataFromScan(dataFromScan == 'Escaneando' ? '' : dataFromScan);
                }}
                data-dismiss="modal">
                {t('sendCriptoCurrencies.address.modalAddAddress.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalQrView = () => {
    return (
      <div>

        {criptoSource == 7 &&
          <div className="modal fade" id="qrModal" role="dialog" aria-labelledby="myModalLabel">
            {!dataFromLogin && (
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={() => {
                        setDataFromScan(dataFromScan == 'Escaneando' ? '' : dataFromScan)
                      }}
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title modal-body-center" id="myModalLabel">
                      {t('sendCriptoCurrencies.address.qrView.title')}
                    </h4>
                  </div>
                  <div className="modal-body modal-body-center">
                    {dataFromScan == 'Escaneando' && (
                      <div style={styles.reader}>
                        <QrReader
                          onResult={(result2, error) => {
                            if (!!result2) {
                              console.log('result2::::::', result2.text)
                              getAddressInWlist(criptoSource, result2.text)
                              setDataFromScan(result2?.text)

                              //closeModal();
                            }

                            if (!!error) {
                              console.info(error)
                            }
                          }}
                          style={{ width: '100%' }}
                        />
                      </div>
                    )}


                    {isValidAddress && succesCode == false && (
                      <div style={styles.reader2}>

                        <b>{dataFromScan}</b>
                        <br></br>
                        <br></br>
                        {t('sendCriptoCurrencies.address.qrView.title')}
                        {t('sendCriptoCurrencies.address.qrView.inputName')}:
                        <input
                          type="text"
                          className="form-control "
                          id="nickname"
                          placeholder=""
                          onChange={e => {
                            setErrorInCode(false)
                            setNickname(e.target.value)
                            setDisabledCodeSms(false)
                          }}
                          value={nickname}
                        />
                        <br></br>

                        {`${t('sendCriptoCurrencies.address.qrView.inputName')} ${kyc?.phone}`}:
                        <input
                          type="text"
                          className="form-control"
                          disabled={disabledCodeSms}
                          id="smsCode"
                          placeholder=""
                          onChange={e => {
                            setErrorInCode(false)
                            setSmsCode(e.target.value)
                            let theCode = e.target.value
                            if (theCode.length == 5) {
                              smsVerifyAddressInWlist(theCode, dataFromScan, token, user.id, criptoSource, addedId, nickname).then(dataR1x => {
                                console.log('dataR1x:::', dataR1x)
                                if (dataR1x.error) {
                                  setErrorInCode(dataR1x.error)
                                }
                                if (dataR1x.message) {
                                  setSuccesCode(true)
                                  closeModal();
                                }
                              })
                            }
                          }}
                          value={smsCode}
                        />
                        {errorInCode && (
                          <div>
                            <b>{t('sendCriptoCurrencies.address.qrView.invalidCode')}</b>
                          </div>
                        )}
                      </div>
                    )}

                    {isInvalidAddress && (
                      <div style={styles.reader2}>
                        <br></br>
                        <br></br>
                        <br></br>
                        <button
                          type="button"
                          style={styles.button4}
                          onClick={() => {
                            //setDataFromScan('Escaneando');
                          }}>
                          {t('sendCriptoCurrencies.address.qrView.invalidAddress')}<span className="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        </button>
                      </div>
                    )}
                    {statusNewAddress && (
                      <div style={styles.reader2}>

                        <b>{dataFromScan}</b>
                        <br></br>


                      </div>
                    )}
                  </div>

                  <div className="modal-footer">
                    <button
                      ref={hideBlockUIModalRef}
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        setDataFromScan(dataFromScan == 'Escaneando' ? '' : dataFromScan)
                      }}
                      data-dismiss="modal">
                      {t('sendCriptoCurrencies.address.qrView.close')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        }

        {(criptoSource == 1 || criptoSource == 2 || criptoSource == 3 || criptoSource == 4 || criptoSource == 5 || criptoSource == 6 || criptoSource == 8) &&
          <div className="modal fade" id="qrModal" role="dialog" aria-labelledby="myModalLabel">
            {!dataFromLogin && (
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={() => {
                        setDataFromScan(dataFromScan == 'Escaneando' ? '' : dataFromScan)
                      }}
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 className="modal-title modal-body-center" id="myModalLabel">
                      {t('sendCriptoCurrencies.address.qrView.readWithdrawalAddress')}
                    </h4>
                  </div>
                  <div className="modal-body modal-body-center">
                    {dataFromScan == 'Escaneando' && (
                      <div style={styles.reader}>
                        <QrReader
                          onResult={(result2, error) => {
                            if (!!result2) {
                              console.log('result2::::::', result2.text)
                              getAddressInWlist(criptoSource, result2.text)
                              setDataFromScan(result2?.text)

                              //closeModal();
                            }

                            if (!!error) {
                              console.info(error)
                            }
                          }}
                          style={{ width: '100%' }}
                        />
                      </div>
                    )}


                    {isValidAddress && succesCode == false && (
                      <div style={styles.reader2}>
                        <b>{dataFromScan}</b>
                        <br></br>
                        <br></br>
                        {t('sendCriptoCurrencies.address.qrView.aliasOrEmail')}:
                        <input
                          type="text"
                          className="form-control "
                          id="nickname"
                          placeholder=""
                          onChange={e => {
                            setErrorInCode(false)
                            setNickname(e.target.value)
                            setDisabledCodeSms(false)
                          }}
                          value={nickname}
                        />
                        <br></br>
                        <button
                          type="button"
                          style={styles.button3}
                          onClick={() => {
                            //setDataFromScan('Escaneando');
                          }}>
                          {t('sendCriptoCurrencies.address.qrView.isValid')}<span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                        </button>
                        <br></br>
                        <br></br>
                        {`${t('sendCriptoCurrencies.address.qrView.isValid')} ${kyc?.phone}`}:
                        <input
                          type="text"
                          className="form-control"
                          disabled={disabledCodeSms}
                          id="smsCode"
                          placeholder=""
                          onChange={e => {
                            setErrorInCode(false)
                            setSmsCode(e.target.value)
                            let theCode = e.target.value
                            if (theCode.length == 5) {
                              smsVerifyAddressInWlist(theCode, dataFromScan, token, user.id, criptoSource, addedId, nickname).then(dataR1x => {
                                console.log('dataR1x:::', dataR1x)
                                if (dataR1x.error) {
                                  setErrorInCode(dataR1x.error)
                                }
                                if (dataR1x.message) {
                                  setSuccesCode(true)
                                  closeModal();
                                }
                              })
                            }
                          }}
                          value={smsCode}
                        />
                        {errorInCode && (
                          <div>
                            <b>{t('sendCriptoCurrencies.address.qrView.isValid')}</b>
                          </div>
                        )}
                      </div>
                    )}

                    {isInvalidAddress && (
                      <div style={styles.reader2}>
                        <br></br>
                        <br></br>
                        <br></br>
                        <button
                          type="button"
                          style={styles.button4}
                          onClick={() => {
                            //setDataFromScan('Escaneando');
                          }}>
                          {t('sendCriptoCurrencies.address.qrView.invalidAddress')}<span className="glyphicon glyphicon-remove" aria-hidden="true"></span>
                        </button>
                      </div>
                    )}
                    {statusNewAddress && (
                      <div style={styles.reader2}>
                        <br></br>

                      </div>
                    )}
                  </div>

                  <div className="modal-footer">
                    <button
                      ref={hideBlockUIModalRef}
                      type="button"
                      className="btn btn-default"
                      onClick={() => {
                        setDataFromScan(dataFromScan == 'Escaneando' ? '' : dataFromScan)
                      }}
                      data-dismiss="modal">
                      {t('sendCriptoCurrencies.address.qrView.close')}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </div>

    )
  }

  const alertError = error => (
    <div
      className="alert alert-danger col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: error ? '' : 'none' }}>
      {error}
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )

  const alertSuccess = success => (
    <div
      className="alert alert-info col-xs-12 col-sm-offset-3 col-sm-6 col-lg-offset-3 col-lg-6"
      style={{ display: success ? '' : 'none' }}>
      <button type="button" className="close" aria-label="Close" onClick={closeAlert}>
        <span aria-hidden="true">&times;</span>
      </button>
      {success}
    </div>
  )

  // TODO: Move states to a constant
  const userInfo = () => {
    return (
      <div className="container ptb--40">
        <div className="row">
          <div className="col-md-8 col-lg-8">
            <div className="ckeckout-left-sidebar">
              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                          <label htmlFor="login" className="normal-label">
                            {t('sendCriptoCurrencies.userAccount.subtitle')}
                          </label>
                          <input
                            disabled="disabled"
                            type="email"
                            className="form-control "
                            id="login"
                            placeholder="Correo Eletronico - email"
                            //onChange={handleChange('email')}
                            value={login}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="checkout-form">
                  <div className="pasadena-checkout-form-inner">
                    <div className="pasadena-single-box">
                      <div className="form-row">
                        <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                          <label htmlFor="source" className="normal-label">
                            {`${t('sendCriptoCurrencies.userAccount.amountToConvert')} `}
                          </label>
                          &nbsp; {`${t('sendCriptoCurrencies.userAccount.crypto')} `} <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                          /> USD <HelperModal
                            pictureName={'start_end_p2p'}
                            texBeforeButton={''}
                            title={'Choose Crypto or USD'}
                            description={'You have 2 options to select: Crypto or USD. The "Amount" box is conditioned by these two options, since they will reflect an amount depending on what you have selected here'}
                            iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                            variant={'text'}
                          />
                        </div>
                        <div>
                          <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                            {`${t('sendCriptoCurrencies.fromAndToWhereToSend')} `}
                            <HelperModal
                              pictureName={'direct_convertion1'}
                              texBeforeButton={''}
                              title={'From and to where to send'}
                              iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                              description={'To third parties: send to third parties inside and outside our platform (Using "QuickSend" you can save fees within our platform). Exchange => P2P: You can send from your exchange wallet to your p2p funds wallet. P2P => Exchange:You can send from your p2p fund wallet to your exchange fund wallet'}
                              variant={'text'}
                            />
                          </div>
                          <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                            <Radio {...controlProps('a')} />{`${t('sendCriptoCurrencies.toThirdParties')} `}
                            <Radio {...controlProps('b')} color="secondary" />Exchange {'=>'} P2P
                          </div>
                          <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                            <Radio {...controlProps('c')} color="success" />P2P {'=>'} Exchange
                          </div>

                        </div>
                        {selectedValueRadio == 'a' &&
                          <div>
                            <div className="row">
                              {balanceInAccount >= 0 && (
                                <div className="checkout-form">
                                  <div className="pasadena-checkout-form-inner">
                                    <div className="pasadena-single-box">
                                      <div className="form-row">
                                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                                          <label htmlFor="source" className="normal-label">
                                            {`${t('sendCriptoCurrencies.userAccount.accountBalance')} `}
                                          </label>
                                          <div>
                                            <select disabled="true" id="inBalance" className="form-control" value={criptoSource}>
                                              <option value="1">{balanceInAccount} BTC</option>
                                              <option value="2">{balanceInAccount} ETH</option>
                                              <option value="3">{balanceInAccount} TRX</option>
                                              <option value="7">{balanceInAccount} XRP</option>
                                              <option value="5">{balanceInAccount} USDT-TRC20</option>
                                              <option value="6">{balanceInAccount} USDT-ERC20</option>
                                              <option value="8">{balanceInAccount} USDC-ERC20</option>

                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="source" className="normal-label">
                                {t('sendCriptoCurrencies.userAccount.origin')}
                              </label>
                              <HelperModal
                                pictureName={'refund1'}
                                texBeforeButton={''}
                                title={'Cryptocurrency to Send'}
                                description={'Select the cryptocurrency to buy. Note: You must have sufficient funds on your debit/credit card to make the purchase. Fees may apply'}
                                iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                                variant={'text'}
                              />
                              <select
                                id="source"
                                className="form-control"
                                onChange={e => {
                                  setCriptoSource(e.target.value)
                                  getBalanceById(e.target.value)
                                  if (e.target.value > 0) {
                                    setDisabled(false)
                                    getCryptoRefresh(e.target.value)
                                  }
                                }}
                                value={criptoSource}>
                                <option value="0" defaultValue="0">
                                  {t('sendCriptoCurrencies.userAccount.select')}
                                </option>
                                <option value="1">BTC</option>
                                <option value="2">ETH</option>
                                <option value="3">TRX</option>
                                <option value="7">XRP</option>
                                <option value="5">USDT-TRC20</option>
                                <option value="6">USDT-ERC20</option>
                                <option value="8">USDC-ERC20</option>
                              </select>
                            </div>
                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="amount1" className="normal-label">
                                {`${t('buyCriptoCurrencies.userAccount.quantity')} `}
                              </label>
                              <HelperModal
                                pictureName={'refund1'}
                                texBeforeButton={''}
                                title={'Cryptocurrency to Send'}
                                description={'Select the cryptocurrency to buy. Note: You must have sufficient funds on your debit/credit card to make the purchase. Fees may apply'}
                                iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                                variant={'text'}
                              />

                              <NumericFormat
                                NumericFormat={2}
                                className="form-control "
                                decimalSeparator="."
                                decimalScale={8}
                                disabled={disabled}
                                id="amount1"
                                placeholder={t('sendCriptoCurrencies.quantityToSend')}
                                onValueChange={values => {
                                  setAmount1(values?.floatValue)
                                }}
                                value={amount1}
                                allowNegative={false}
                              />
                            </div>

                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="endaddress" className="normal-label">
                                {t('sendCriptoCurrencies.userAccount.addressDestiny')}
                              </label>
                              <HelperModal
                                pictureName={'refund1'}
                                texBeforeButton={''}
                                title={'Destination Address'}
                                description={'Here you must write from the clipboard the string of the address to send the cryptocurrencies. You also have the option to scan QR code'}
                                iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                                variant={'text'}
                              />
                              <input
                                type="text"
                                className="form-control "
                                id="endaddress"
                                placeholder={t('sendCriptoCurrencies.userAccount.addressDestinyPlaceHolder')}
                                onChange={e => {
                                  const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                  e.target.value = newValue
                                  let criptoSource0 = criptoSource * 1
                                  if (criptoSource0 == 0) {
                                    setErrorKyc(t('sendCriptoCurrencies.error.selectCripto'))
                                  } else {
                                    setDataFromScan(newValue)
                                    setDataFromLogin(true)
                                  }
                                }}
                                //onChange={e => {
                                //    setDataFromScan(e.target.value);
                                //}}
                                value={dataFromScan}
                              />
                            </div>

                            {criptoSource == 7 &&
                              <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                                <label htmlFor="endaddress" className="normal-label">
                                  Memo/Tag (Opcional)
                                </label>
                                <HelperModal
                                  pictureName={'refund1'}
                                  texBeforeButton={''}
                                  title={'Memo / Tag'}
                                  description={'Some cryptocurrencies use an additional field called Memo or Tag. If applicable, place it in this box. Leave the box at zero if you do not have a memo/tag'}
                                  iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                                  variant={'text'}
                                />
                                <input
                                  type="text"
                                  className="form-control "
                                  id="endaddress"
                                  placeholder="Colocar 0 (Cero) sino posee"
                                  onChange={e => {
                                    const newValueMemo = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                    e.target.value = newValueMemo
                                    let criptoSource0 = criptoSource * 1
                                    if (criptoSource0 == 0) {
                                      setErrorKyc(t('sendCriptoCurrencies.error.selectCripto'))
                                    } else {
                                      setDataMemo(newValueMemo)
                                    }
                                  }}
                                  //onChange={e => {
                                  //    setDataFromScan(e.target.value);
                                  //}}
                                  value={dataMemo}
                                />
                              </div>
                            }

                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="endaddress" className="normal-label">
                                .
                              </label>
                              <div>                
                                {criptoSource == 0 && (
                                  <button
                                    type="button"
                                    style={styles.button}
                                    onClick={() => {
                                      setErrorKyc(t('sendCriptoCurrencies.error.selectCripto'))
                                    }}>
                                    {t('sendCriptoCurrencies.userAccount.read')} <span className="glyphicon glyphicon-qrcode" aria-hidden="true"></span>
                                  </button>
                                )}
                                {criptoSource > 0 && !dataFromLogin && criptoSource != 7 && (
                                  <button
                                    type="button"
                                    style={styles.button2}
                                    data-toggle="modal"
                                    data-target="#qrModal"
                                    aria-label="qr"
                                    onClick={() => {
                                      setIsValidAddress(false)
                                      setIsInvalidAddress(false)
                                      setStatusNewAddress(false)
                                      setDataFromScan('Escaneando')
                                    }}>
                                    {t('sendCriptoCurrencies.userAccount.read')} <span className="glyphicon glyphicon-qrcode" aria-hidden="true"></span>
                                  </button>
                                )}

                                {criptoSource == 7 && dataMemo == '' && (
                                  <button
                                    type="button"
                                    style={styles.button}
                                    onClick={() => {
                                      setErrorKyc(t('sendCriptoCurrencies.error.tagError'))
                                    }}>
                                    {t('sendCriptoCurrencies.userAccount.read')} <span className="glyphicon glyphicon-qrcode" aria-hidden="true"></span>
                                  </button>
                                )}

                                {criptoSource == 7 && !dataFromLogin && dataMemo != '' && (
                                  <button
                                    type="button"
                                    style={styles.button2}
                                    data-toggle="modal"
                                    data-target="#qrModal"
                                    aria-label="qr"
                                    onClick={() => {
                                      setIsValidAddress(false)
                                      setIsInvalidAddress(false)
                                      setStatusNewAddress(false)
                                      setDataFromScan('Escaneando')
                                    }}>
                                    {t('sendCriptoCurrencies.userAccount.read')} <span className="glyphicon glyphicon-qrcode" aria-hidden="true"></span>
                                  </button>
                                )}


                                <span>&nbsp;&nbsp;</span>
                                <button
                                  type="button"
                                  style={styles.button2}
                                  data-toggle="modal"
                                  data-target="#wlModal"
                                  aria-label="qr"
                                  onClick={() => {
                                    loadWlists()
                                  }}>
                                  {t('sendCriptoCurrencies.userAccount.directory')} <span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span>
                                </button>

                                <HelperModal
                                  pictureName={'refund1'}
                                  texBeforeButton={''}
                                  title={'Scan or Read'}
                                  description={'Here you can shrink from a trusted whitelist deposit address or you can read a new one by scanning a QR code'}
                                  iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                                  variant={'text'}
                                />
                              </div>
                            </div>

                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="endaddress" className="normal-label">
                                {t('sendCriptoCurrencies.userAccount.quickSend')}
                              </label>
                              <HelperModal
                                pictureName={'refund1'}
                                texBeforeButton={''}
                                title={'Quick Send'}
                                description={'In this box you can add the email of a third party who is a client of the coinmaxweb platform and can make the delivery immediately and without paying fees.'}
                                iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
                                variant={'text'}
                              />
                              <input
                                type="email"
                                className="form-control "
                                id="endaddress"
                                placeholder="Email"
                                onChange={e => {
                                  const newValue = e.target.value.replace(/[^a-zA-Z0-9@._-]/g, '')
                                  e.target.value = newValue
                                  setDestinyUser(newValue)
                                }}
                                value={destinyUser}
                              />
                            </div>

                            {dataFromLogin && (
                              <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                                <label htmlFor="endaddress" className="normal-label">
                                  .
                                </label>
                                <div>
                                  <button
                                    type="button"
                                    style={styles.button2}
                                    data-toggle="modal"
                                    data-target="#addModal"
                                    aria-label="myModalLabel2"
                                    onClick={() => {
                                      setIsValidAddress(false)
                                      setIsInvalidAddress(false)
                                      setStatusNewAddress(false)
                                    }}>
                                    {t('sendCriptoCurrencies.userAccount.add')} <span className="glyphicon glyphicon-list-alt" aria-hidden="true"></span>
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                        }


                        {selectedValueRadio == 'b' &&
                          <div>
                            <div className="row">
                              {balanceInAccount >= 0 && (
                                <div className="checkout-form">
                                  <div className="pasadena-checkout-form-inner">
                                    <div className="pasadena-single-box">
                                      <div className="form-row">
                                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                                          <label htmlFor="source" className="normal-label">
                                            Available On Exchange
                                          </label>
                                          <div>
                                            <select disabled="true" id="inBalance" className="form-control" value={criptoSource}>
                                              <option value="1">{balanceInAccount} BTC</option>
                                              <option value="2">{balanceInAccount} ETH</option>
                                              <option value="3">{balanceInAccount} TRX</option>
                                              <option value="7">{balanceInAccount} XRP</option>
                                              <option value="5">{balanceInAccount} USDT-TRC20</option>
                                              <option value="6">{balanceInAccount} USDT-ERC20</option>
                                              <option value="8">{balanceInAccount} USDC-ERC20</option>

                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="source" className="normal-label">
                                From Exchange
                              </label>
                              <select
                                id="source"
                                className="form-control"
                                onChange={e => {
                                  setCriptoSource(e.target.value)
                                  getBalanceById(e.target.value)
                                  if (e.target.value > 0) {
                                    setDisabled(false)
                                    getCryptoRefresh(e.target.value)
                                  }
                                }}
                                value={criptoSource}>
                                <option value="0" defaultValue="0">
                                  Select
                                </option>
                                <option value="1">BTC</option>
                                <option value="2">ETH</option>
                                <option value="3">TRX</option>
                                <option value="7">XRP</option>
                                <option value="5">USDT-TRC20</option>
                                <option value="6">USDT-ERC20</option>
                                <option value="8">USDC-ERC20</option>
                              </select>
                            </div>
                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="amount1" className="normal-label">
                                Amount To Send
                              </label>

                              <NumericFormat
                                NumericFormat={2}
                                className="form-control "
                                decimalSeparator="."
                                decimalScale={8}
                                disabled={disabled}
                                id="amount1"
                                placeholder={t('sendCriptoCurrencies.quantityToSend')}
                                onValueChange={values => {
                                  setAmount1(values?.floatValue)
                                }}
                                value={amount1}
                                allowNegative={false}
                              />
                            </div>

                            {criptoSource == 7 &&
                              <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                                <label htmlFor="endaddress" className="normal-label">
                                  Memo/Tag (Opcional)
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  id="endaddress"
                                  placeholder="Colocar 0 (Cero) sino posee"
                                  onChange={e => {
                                    const newValueMemo = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                    e.target.value = newValueMemo
                                    let criptoSource0 = criptoSource * 1
                                    if (criptoSource0 == 0) {
                                      setErrorKyc(t('sendCriptoCurrencies.error.selectCripto'))
                                    } else {
                                      setDataMemo(newValueMemo)
                                    }
                                  }}
                                  //onChange={e => {
                                  //    setDataFromScan(e.target.value);
                                  //}}
                                  value={dataMemo}
                                />
                              </div>
                            }

                          </div>
                        }

                        {selectedValueRadio == 'c' &&
                          <div>
                            <div className="row">
                              {balanceInAccount >= 0 && (
                                <div className="checkout-form">
                                  <div className="pasadena-checkout-form-inner">
                                    <div className="pasadena-single-box">
                                      <div className="form-row">
                                        <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                                          <label htmlFor="source" className="normal-label">
                                            Available On P2P
                                          </label>
                                          <div>
                                            <select disabled="true" id="inBalance" className="form-control" value={criptoSource}>
                                              <option value="1">{balanceInAccount} BTC</option>
                                              <option value="2">{balanceInAccount} ETH</option>
                                              <option value="3">{balanceInAccount} TRX</option>
                                              <option value="7">{balanceInAccount} XRP</option>
                                              <option value="5">{balanceInAccount} USDT-TRC20</option>
                                              <option value="6">{balanceInAccount} USDT-ERC20</option>
                                              <option value="8">{balanceInAccount} USDC-ERC20</option>

                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="source" className="normal-label">
                                From P2P
                              </label>
                              <select
                                id="source"
                                className="form-control"
                                onChange={e => {
                                  setCriptoSource(e.target.value)
                                  getBalanceById(e.target.value)
                                  if (e.target.value > 0) {
                                    setDisabled(false)
                                    getCryptoRefresh(e.target.value)
                                  }
                                }}
                                value={criptoSource}>
                                <option value="0" defaultValue="0">
                                  Select
                                </option>
                                <option value="1">BTC</option>
                                <option value="2">ETH</option>
                                <option value="3">TRX</option>
                                <option value="7">XRP</option>
                                <option value="5">USDT-TRC20</option>
                                <option value="6">USDT-ERC20</option>
                                <option value="8">USDC-ERC20</option>
                              </select>
                            </div>
                            <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                              <label htmlFor="amount1" className="normal-label">
                                Amount To Send
                              </label>

                              <NumericFormat
                                NumericFormat={2}
                                className="form-control "
                                decimalSeparator="."
                                decimalScale={8}
                                disabled={disabled}
                                id="amount1"
                                placeholder={t('sendCriptoCurrencies.quantityToSend')}
                                onValueChange={values => {
                                  setAmount1(values?.floatValue)
                                }}
                                value={amount1}
                                allowNegative={false}
                              />
                            </div>

                            {criptoSource == 7 &&
                              <div className="form-group col-xs-12 col-sm-6 col-lg-6">
                                <label htmlFor="endaddress" className="normal-label">
                                  Memo/Tag (Opcional)
                                </label>
                                <input
                                  type="text"
                                  className="form-control "
                                  id="endaddress"
                                  placeholder="Colocar 0 (Cero) sino posee"
                                  onChange={e => {
                                    const newValueMemo = e.target.value.replace(/[^a-zA-Z0-9]/g, '')
                                    e.target.value = newValueMemo
                                    let criptoSource0 = criptoSource * 1
                                    if (criptoSource0 == 0) {
                                      setErrorKyc(t('sendCriptoCurrencies.error.selectCripto'))
                                    } else {
                                      setDataMemo(newValueMemo)
                                    }
                                  }}
                                  //onChange={e => {
                                  //    setDataFromScan(e.target.value);
                                  //}}
                                  value={dataMemo}
                                />
                              </div>
                            }

                          </div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 col-sm-12 col-lg-12 text-left">
                  {dataFromScan.length > 0 && amount1 > 0 && selectedValueRadio == 'a' &&
                    <button
                      data-toggle="modal"
                      data-target="#advertimesentModal"
                      aria-label="advertimesent"
                      className="btn btn-dark">
                      {t('sendCriptoCurrencies.userAccount.processOrder')}
                    </button>
                  }
                  {amount1 > 0 && selectedValueRadio == 'b' && criptoSource > 0 &&
                    <button
                      data-toggle="modal"
                      data-target="#advertimesentModal"
                      aria-label="advertimesent"
                      className="btn btn-dark">
                      {t('sendCriptoCurrencies.userAccount.processOrder')}
                    </button>
                  }
                  {amount1 > 0 && selectedValueRadio == 'c' && criptoSource > 0 &&
                    <button
                      data-toggle="modal"
                      data-target="#advertimesentModal"
                      aria-label="advertimesent"
                      className="btn btn-dark">
                      {t('sendCriptoCurrencies.userAccount.processOrder')}
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        {alertSuccess(successKyc)}
        {alertError(errorKyc)}
      </div>
    )
  }

  const modalWList = () => {
    return (
      <div className="modal fade" id="wlModal" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('sendCriptoCurrencies.trustedAddresses.title')}
              </h4>
            </div>
            <div className="modal-body modal-body-center">
              <Box sx={{ width: '100%', maxWidth: 600, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                  <List style={{ maxHeight: '100%', overflow: 'auto' }}>
                    {myWlist &&
                      myWlist.length > 0 &&
                      myWlist.map((oneWlist, i) => (

                        <ListItem disablePadding>
                          <ListItemButton component="a" href="#" onClick={() => {
                            setDataFromScan(oneWlist?.address)
                            setDataMemo(oneWlist?.memo)
                            setNickname(oneWlist?.nickname)
                            setCriptoSource(oneWlist?.crypto_id)
                            getBalanceById(oneWlist?.crypto_id)
                            setDisabled(false)
                            //closeModal();
                          }}>
                            <ListItemText
                              primaryTypographyProps > {`${oneWlist?.nickname?.substring(8, 0)}`}...
                            </ListItemText>
                            <ListItemText
                              primaryTypographyProps={{ fontSize: '16px', }}> ...{oneWlist?.address?.substring(oneWlist?.address?.length - 8)}
                            </ListItemText>

                            <ListItemText
                              primaryTypographyProps > {`${oneWlist?.currency_acronym}`}
                            </ListItemText>
                            {oneWlist?.memo > 0 &&
                              <ListItemText
                                primaryTypographyProps={{ fontSize: '16px', }}> Tag:{oneWlist?.memo}
                              </ListItemText>
                            }
                            {oneWlist?.memo == 0 &&
                              <ListItemText
                                primaryTypographyProps={{ fontSize: '16px', }}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </ListItemText>
                            }

                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                </nav>
              </Box>
            </div>
            <div className="modal-body modal-body-center"></div>
            <div className="modal-footer">
              <button ref={hideBlockUIModalRef} type="button" className="btn btn-default" data-dismiss="modal">
                {t('sendCriptoCurrencies.trustedAddresses.close')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalFinalSucces = () => {
    return (
      <>
        <button
          ref={showBlockUIModalRefFinalSucces}
          type="button"
          data-toggle="modal"
          data-target="#finalSuccesModal"
          style={{ display: 'none' }}
        />
        <div className="modal fade" id="finalSuccesModal" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title modal-body-center" id="myModalLabel">
                  {t('sendCriptoCurrencies.modalFinalSuccess.result')}
                </h4>
              </div>
              <div>
                <div className="modal-body modal-body-center">{t('sendCriptoCurrencies.modalFinalSuccess.requestProcessedSuccessfully')}</div>
                <div className="modal-footer">
                  <button
                    ref={hideBlockUIModalRefFinalSucces}
                    onClick={() => goToPageBriefCase()}
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal">
                    {t('sendCriptoCurrencies.modalFinalSuccess.close')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const modalAdvertisement = () => {
    return (
      <div className="modal fade" id="advertimesentModal" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('sendCriptoCurrencies.modalAdvertisement.title')}
              </h4>
            </div>
            {kyc.verifiedEmail == true && (
              <div>
                <div className="modal-body modal-body-center">
                  <Typography>
                    {t('sendCriptoCurrencies.modalAdvertisement.msg1')}
                    <br />
                    <br />
                    {`${t('sendCriptoCurrencies.modalAdvertisement.msg2')} `}
                    <Typography
                      onClick={() => setIsOpenModalCommission(true)}
                      component="span"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: '700',
                        },
                      }}>
                      {t('sendCriptoCurrencies.modalAdvertisement.msg3')}
                    </Typography>{' '}
                    {`${t('sendCriptoCurrencies.modalAdvertisement.msg4')} `}
                    <Typography
                      onClick={() => setIsOpenModalAntiWashing(true)}
                      component="span"
                      sx={{
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: '700',
                        },
                      }}>
                      {t('sendCriptoCurrencies.modalAdvertisement.msg5')}
                      <br />
                      <br />
                    </Typography>
                  </Typography>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() => {
                      executeSend()
                      //closeModal();
                    }}
                    data-dismiss="modal">
                    {t('sendCriptoCurrencies.modalAdvertisement.accept')}<span className="glyphicon glyphicon-ok" aria-hidden="true"></span>
                  </button>
                  <button ref={hideBlockUIModalRef} type="button" className="btn btn-default" data-dismiss="modal">
                    {t('sendCriptoCurrencies.modalAdvertisement.cancel')}
                  </button>
                </div>
              </div>
            )}
            {kyc.verifiedEmail == false && (
              <div>
                <div className="modal-body modal-body-center">
                  ERROR KYC: Para Continuar, primero debes abrir el enlace de verificación que enviamos a tu Correo
                  Electrónico (Confirmar Correo KYC). Luego de verificar, reinicia la sesion para aplicar los cambios a
                  tu cuenta.
                </div>
                <div className="modal-footer">
                  <button ref={hideBlockUIModalRef} type="button" className="btn btn-default" data-dismiss="modal">
                    Cancelar
                  </button>
                </div>
              </div>
            )}
          </div>
          <TermsAndConditionsModal
            open={isOpenModalCommission}
            handleClose={() => declineTerms()}
            saveTermsAndCondition={saveTermsAndCondition}
            accepetText={t('modalsTermsAndConditions.acceptComissions')}
            denyText={t('modalsTermsAndConditions.denyComission')}
            {...{ tacText: commission ? commission.description : '' }}
          />
          <TermsAndConditionsModal
            open={isOpenModalAntiWashing}
            handleClose={() => declineTerms()}
            saveTermsAndCondition={saveTermsAntiWashing}
            accepetText={t('modalsTermsAndConditions.acceptPolitics')}
            denyText={t('modalsTermsAndConditions.denyPolitics')}
            {...{ tacText: anti_washing.description }}
          />
        </div>
      </div>
    )
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Layout
      title={t('sendCriptoCurrencies.title')?.toUpperCase()}
      description={t('sendCriptoCurrencies.title')}
      showBreadcrumb={true}
      currentPage={t('sendCriptoCurrencies.title')?.toUpperCase()}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {modalQrView()}
        {modalFinalSucces()}
        {modalAddAddress()}
        {errorUserInactive == false ? userInfo() : ''}
        {modalUserInactiveView()}
        {modalWList()}
        {modalAdvertisement()}
      </Box>
    </Layout>
  )
}

export default SendCrypto