import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

//Custom Component Imports
import Layout from '../../core/Layout'
import { validateOnlyString } from '../../utils'
import SelectForm from '../../components/form/SelectForm'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Icons Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

//API Imports
import { getLegalTerm, createLegalTerm, updateLegalTerm, getCountries, getTypeEvents } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'

const initValues = {
  id: '',
  name: '',
  description: '',
  id_country: '',
  name_country: '',
  id_type_event: '',
  name_type_event: '',
  active: '',
}

const FormLegalTerm = () => {
  // Initial State
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  //params que vienen por la url
  const paramsLegalTermForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  const [typeEvents, setTypeEvent] = useState([])
  const [countries, setCountrys] = useState([])
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm(initValues)

  const getDetailsLegalTerm = async () => {
    dispatch(showSpinner('Obteniendo Término Legal'))

    getLegalTerm(paramsLegalTermForm?.id).then(data => {
      if (data?.status >= 200 && data?.status <= 299) {
        const newLegalTerm = {
          id: data.data[0].id,
          name: data.data[0].name,
          description: data.data[0].description,
          idCountry: data.data[0].id_country.toString(),
          nameCountry: data.data[0].name_country,
          idTypeEvent: data.data[0].id_type_event.toString(),
          active: data.data[0].active,
        }
        setDefaultValues(newLegalTerm)
      } else {
        if (data?.status == 401) {
          //goToLogin()
          return
        }
        if (data?.status >= 400 || data?.status <= 599) {
          //TODO
          //useEffect(() => {
          //  changeToastInfo({ msg: data.message, severity: 'info' })
          //}, [])
        }
      }

      /*if (Array.isArray(data)) {
      setData(data)
    }*/
      dispatch(hiddeSpinner())
    })
  }

  const getCountriesList = async () => {
    dispatch(showSpinner('Obteniendo Información'))
    getCountries()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setCountrys(data.data)
        } else {
          if (data?.status == 401) {
            //goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const getTypeEventsList = async () => {
    dispatch(showSpinner('Obteniendo Información'))
    getTypeEvents().then(data => {
      if (data?.status >= 200 && data?.status <= 299) {
        setTypeEvent(data.data)
        //setBriefcase(data)
      } else {
        if (data?.status == 401) {
          dispatch(hiddeSpinner())
          //goToLogin()
          return
        }
        if (data?.status >= 400 || data?.status <= 599) {
          //TODO
          //useEffect(() => {
          //  changeToastInfo({ msg: data.message, severity: 'info' })
          //}, [])
        }
      }
      dispatch(hiddeSpinner())
    })
  }

  const onSubmitForm = legal_term => {
    const updaterLegalTerm = {
      id: legal_term.id,
      name: legal_term.name,
      description: legal_term.description,
      id_country: legal_term.idCountry,
      id_type_event: legal_term.idTypeEvent,
      active: true,
    }
    if (paramsLegalTermForm?.id !== undefined) {
      SendUpdateLegalTerm(updaterLegalTerm)
      goToLegalTermList()
      return
    }
    sendCreateLegalTerm(updaterLegalTerm)
    goToLegalTermList()
    return
  }

  const SendUpdateLegalTerm = async legal_term => {
    dispatch(showSpinner('Modificando Término Legal'))
    try {
      await updateLegalTerm(legal_term.id, legal_term)
      dispatch(hiddeSpinner())
      //return
    } catch (error) {
      dispatch(hiddeSpinner())
      console.error(error)
    }
  }

  const sendCreateLegalTerm = async legal_term => {
    dispatch(showSpinner('Registrando un Término Legal'))
    try {
      await createLegalTerm(legal_term)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToLegalTermList = () => {
    history.push('/admin/legal_terms')
  }

  useEffect(() => {
    getCountriesList()
    getTypeEventsList()
    //en caso de que exista un id, se pone en modo editar
    if (paramsLegalTermForm?.id !== undefined) {
      getDetailsLegalTerm()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={paramsLegalTermForm?.id === undefined ? t('legalTerms.form.titleNew') : t('legalTerms.form.titleEdit')}
      description={
        paramsLegalTermForm?.id === undefined ? t('legalTerms.form.titleNew') : t('legalTerms.form.titleEdit')
      }
      showBreadcrumb={true}
      currentPage={
        paramsLegalTermForm?.id === undefined ? t('legalTerms.form.titleNew') : t('legalTerms.form.titleEdit')
      }>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton title={t('legalTerms.back')} aria-label={t('legalTerms.back')} onClick={() => goToLegalTermList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: t('legalTerms.form.nameInput.required'),
                  pattern: {
                    value: validateOnlyString(),
                    message: t('legalTerms.form.nameInput.patternMessage'),
                  },
                })}
                label={t('legalTerms.form.nameInput.label')}
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('idCountry')?.toString()}
                label={t('legalTerms.form.countryInput.label')}
                name="idCountry"
                options={countries}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      idCountry: target.value,
                    })),
                  required: t('legalTerms.form.countryInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('idTypeEvent')?.toString()}
                label={t('legalTerms.form.typeEventInput.label')}
                name="idTypeEvent"
                options={typeEvents}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      idTypeEvent: target.value,
                    })),
                  required: t('legalTerms.form.typeEventInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label={t('legalTerms.form.statusInput.label')}
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      active: target.value,
                    })),
                  required: t('legalTerms.form.statusInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <CKEditor
                editor={ClassicEditor}
                data={
                  defaultValues?.description !== undefined && defaultValues?.description !== ''
                    ? defaultValues?.description
                    : // : '<p> Tú descripción va aquí!</p>'
                      t('legalTerms.form.descriptionInput.placeHolder')
                }
                onReady={editor => {}}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  // console.log({ event, editor, data })
                  setValue('description', data)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                {t('legalTerms.form.saveButton')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormLegalTerm
