import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Chip, Grid, TextField, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'

// Icons Import
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// Custom Component Imports
import Layout from '../../core/Layout'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'
import { validateOnlyNumberStringSigns } from '../../utils'

// API Impports
import { deleteCountry, getCountries, saveCountry } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import SelectForm from '../../components/form/SelectForm'

// TODO: Explanation
const AdminCountry = () => {
  // // Initial state
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()

  const [data, setData] = useState([])
  const [detailsProfiles, setDetailsProfiles] = useState({})
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenFormModal, setIsOpenFormModal] = useState(false)
  const [defaultValues, setDefaultValues] = useState({})
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const {
    handleSubmit,
    getValues,
    register,
    watch,
    formState: { errors },
    reset,
  } = useForm()

  const columns = [
    {
      field: 'name',
      headerName: t('country.table.name'),
      flex: 1,
      renderCell: params => <>{params?.value} </>,
    },
    {
      field: 'active',
      headerName: t('country.table.status'),

      flex: 1,
      renderCell: params => (
        <>
          {params?.value ? (
            <Chip label={t('country.table.activeStatus')} color="success" variant="outlined" />
          ) : (
            <Chip label={t('country.table.inactiveStatus')} color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: t('country.table.actions'),
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title={t('capability.table.editButton')} arrow>
                <IconButton
                  aria-label={t('capability.table.editButton')}
                  onClick={() => openCountryDetails(params?.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('capability.table.deleteButton')} arrow onClick={() => handleOpenDeleteModal(params)}>
                <IconButton aria-label={t('capability.table.deleteButton')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const filteredCountries = useMemo(() => {
    return watch('searchInput')?.trim() === ''
      ? data
      : data?.filter(
          country => country?.name && country?.name?.toUpperCase()?.includes(watch('searchInput')?.toUpperCase()),
        )
  }, [data, watch('searchInput')])

  const handleOpenDeleteModal = profile => {
    setDetailsProfiles(profile)
    setIsOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false)
  }

  const openModalDelete = async detailsProfiles => {
    const { id: IdProfiles } = detailsProfiles
    await deleteCountry(IdProfiles)
    // deleteCountry(country_id).then(data => {
    //   if (data.error) {
    //     setDataTableError(data.error.description)
    //   } else {
    //     loadCountries()
    //     setDataTableSuccess('Solicitud Procesada Exitosamente')
    //   }
    // })
    handleCloseDeleteModal()
    getCountriesList()
  }

  const openCountryDetails = idCountry => {
    const formValues = data?.find(country => country.id === idCountry)
    setDefaultValues({
      countryName: formValues?.name,
      countryStatus: formValues?.active?.toString(),
      id: formValues?.id,
    })
    setIsOpenFormModal(true)
  }

  const getCountriesList = () => {
    dispatch(showSpinner('Obteniendo Lista de Países'))
    getCountries()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
        }
        // else {
        //   if (data?.status == 401) {
        //     // goToLogin()
        //     return
        //   }
        //   if (data?.status >= 400 || data?.status <= 599) {
        //     //TODO
        //     //useEffect(() => {
        //     //  changeToastInfo({ msg: data.message, severity: 'info' })
        //     //}, [])
        //   }
        // }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const clickSaveNewCountry = countryToSave => {
    dispatch(showSpinner('Registrando País'))
    saveCountry(countryToSave)
      .then(data => {
        if (data.error) {
          setError('Error al registrar el país, intente mas tarde')
          dispatch(hiddeSpinner())
        } else {
          setSuccess('Se registro el país correctamente')
          dispatch(hiddeSpinner())
          setIsOpenFormModal(false)
        }
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const handleOpenNewCountryModal = () => {
    reset({ countryName: '', countryStatus: '', searchInput: watch('searchInput') })
    setIsOpenFormModal(true)
  }

  const handleCloseNewCountryModal = () => {
    setIsOpenFormModal(false)
  }

  useEffect(() => {
    getCountriesList()
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    // if (defaultValues?.id) {
    reset({
      ...defaultValues,
      searchInput: watch('searchInput'),
    })
    // }
  }, [defaultValues])

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Layout
      title={t('country.title')}
      description={t('country.title')}
      showBreadcrumb={true}
      currentPage={t('country.title')}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}></Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} component="main">
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
              <Tooltip title={t('country.back')} arrow>
                <IconButton aria-label="back icon" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{t('country.subtitle')}</Typography>
              <Tooltip title={t('country.add')} arrow>
                <IconButton aria-label="add icon" sx={{ mr: 2 }} color="primary" onClick={handleOpenNewCountryModal}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <Stack direction="row" py="1rem">
              <TextField
                focused={watch('searchInput') !== ''}
                {...register('searchInput')}
                placeholder={t('country.searchButton')}
                type="text"
                variant="standard"
                sx={{ width: '100%', maxWidth: { xs: '100%', md: '30vw', lg: '20vw' } }}
              />
            </Stack>
            <DataGrid
              rows={filteredCountries}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 5, page: 0, pageCount: 1 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              getRowId={row => row?.id}
              autoHeight
            />
          </GridItem>
        </GridContainer>
      </Box>
      <Dialog
        open={isOpenDeleteModal}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Está seguro que desea eliminar? <br></br>
            {detailsProfiles?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => openModalDelete(detailsProfiles)}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenFormModal}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description"
        component="form"
        maxWidth="md"
        onSubmit={handleSubmit(clickSaveNewCountry)}>
        <DialogTitle>
          {defaultValues?.id === undefined
            ? t('country.form.titleNew')
            : `${t('country.form.titleEdit')} - ${defaultValues?.countryName}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  focused={watch('countryName') !== ''}
                  {...register('countryName', {
                    required: t('country.form.nameInput.required'),
                    pattern: {
                      value: validateOnlyNumberStringSigns(),
                      message: t('country.form.nameInput.patternMessage'),
                    },
                  })}
                  label={t('country.form.nameInput.label')}
                  type="text"
                  variant="outlined"
                  helperText={errors?.name_group?.message}
                  error={errors?.name_group?.message?.length > 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
                <SelectForm
                  label={t('country.form.statusInput.label')}
                  name="countryStatus"
                  defaultValue={getValues('countryStatus')?.toString()}
                  options={[
                    { key: 'Activo', value: true },
                    { key: 'Inactivo', value: false },
                  ]}
                  register={register}
                  validations={{
                    onChange: ({ target }) =>
                      setDefaultValues(() => ({
                        ...getValues(),
                        countryStatus: target.value,
                      })),
                    required: t('country.form.statusInput.required'),
                  }}
                  watch={watch}
                  error={errors}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewCountryModal}>{t('country.form.cancelButton')}</Button>
          <Button variant="contained" type="submit">
            {t('country.form.saveButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminCountry
