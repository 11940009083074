import React, { useContext } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import Routes from './Routes'
import { ToastContext } from './context'
import { LoadingWrapped } from './components/loading'

const App = () => {
  const { toastInfo, changeToastInfo } = useContext(ToastContext)
  console.log('ho')
  const { msg, severity, isOpen, autoHideDuration } = toastInfo
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    changeToastInfo({ ...toastInfo, isOpen: false })
  }
  return (
    <>
      <Routes />
      <LoadingWrapped>
        <Snackbar open={isOpen} autoHideDuration={autoHideDuration} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {msg}
          </Alert>
        </Snackbar>
      </LoadingWrapped>
    </>
  )
}

export default App
