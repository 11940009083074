//Permite validar letras mayúsculas, minusculas, espacios en blanco, acentos y la letra ñ
export const validateOnlyString = () => /^[A-Za-z\s\u00C0-\u017F]+$/g

//Permite validar números y espacios en blanco
export const validateOnlyNumber = () => /^[0-9\s]+$/g

//Permite validar números, letras mayúsculas y minúsculas, espacios en blanco, acentos y la letra ñ
export const validateOnlyNumberString = () => /^[0-9A-Za-z\s\u00C0-\u017F]+$/g

//Permite validar correo electrónico
export const validateEmail = () =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+/gi

  
 //Permite validar letras mayúsculas y minúsculas, puntos, comas, espacios en blanco, acentos y letra ñ
export const validateOnlyStringSigns = () => /^[A-Za-z.,\s\u00C0-\u017F]+$/g


//Permite números, letras mayúsculas y minúsculas, puntos, comas, espacios en blanco, acentos, letra ñ y guiones
export const validateOnlyNumberStringSigns = () => /^[0-9A-Za-z.,\s\u00C0-\u017F-]+$/g

//Permite validar numeros IP
export const validateIP = () => /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
    
