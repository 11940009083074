import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Chip, Container, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'

// Icons Import
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'

// Custom Component Imports
import Layout from '../../core/Layout'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'
import { validateOnlyNumberStringSigns } from '../../utils'

// API Impports
import { deleteCountry, getCountries, getStates, saveCountry } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import SelectForm from '../../components/form/SelectForm'

// TODO: Explanation
const AdminState = () => {
  // // Initial state
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let history = useHistory()

  const [data, setData] = useState([])
  const [countriesList, setCountriesList] = useState([])
  const [detailsStates, setDetailsStates] = useState({})
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenFormModal, setIsOpenFormModal] = useState(false)
  const [defaultValues, setDefaultValues] = useState()
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
   

  const {
    handleSubmit,
    getValues,
    register,
    watch,
    formState: { errors },
    reset,
  } = useForm()

  const columns = [
    {
      field: 'name',
      headerName: t('state.table.name'),
      flex: 1,
      renderCell: params => <>{params?.value} </>,
    },
    {
      field: 'country_name',
      headerName: t('state.table.country'),
      flex: 1,
      renderCell: params => <>{params?.value} </>,
    },
    {
      field: 'active',
      headerName: t('state.table.status'),

      flex: 1,
      renderCell: params => (
        <>
          {params?.value ? (
            <Chip label={t('state.table.activeStatus')} color="success" variant="outlined" />
          ) : (
            <Chip label={t('state.table.inactiveStatus')} color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: t('state.table.actions'),
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title={t('capability.table.editButton')} arrow>
                <IconButton aria-label={t('capability.table.editButton')} onClick={() => openStateDetails(params?.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('capability.table.deleteButton')} arrow onClick={() => handleOpenDeleteModal(params?.id)}>
                <IconButton aria-label={t('capability.table.deleteButton')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const filteredCountries = useMemo(() => {
    return watch('searchInput')?.trim() === ''
      ? data
      : data?.filter(
          country => country?.name && country?.name?.toUpperCase()?.includes(watch('searchInput')?.toUpperCase()),
        )
  }, [data, watch('searchInput')])

  const handleOpenDeleteModal = idState => {
    const formValues = data?.find(state => state.id === idState)
    setDetailsStates(formValues)
    setIsOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false)
  }

  const openModalDelete = async detailsState => {
    //const { id: IdState } = detailsState
    //const formValues = data?.find(state => state.id === idState)  ///WLADI REVISA ESTO
    //await deleteCountry(IdState)
    // deleteCountry(country_id).then(data => {
    //   if (data.error) {
    //     setDataTableError(data.error.description)
    //   } else {
    //     loadCountries()
    //     setDataTableSuccess('Solicitud Procesada Exitosamente')
    //   }
    // })
    //handleCloseDeleteModal()
    //getStatesList()
  }

  const openStateDetails = idState => {
    const formValues = data?.find(state => state.id === idState)
    setDefaultValues({
      countryInput: formValues?.id,
      nameInput: formValues?.name,
      id: formValues?.id,
      statusInput: formValues?.active?.toString(),
    })
    setIsOpenFormModal(true)
  }

  const getStatesList = () => {
    dispatch(showSpinner('Obteniendo Lista de División territorial'))
    getStates()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
        }
        // else {
        //   if (data?.status == 401) {
        //     // goToLogin()
        //     return
        //   }
        //   if (data?.status >= 400 || data?.status <= 599) {
        //     //TODO
        //     //useEffect(() => {
        //     //  changeToastInfo({ msg: data.message, severity: 'info' })
        //     //}, [])
        //   }
        // }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const getCountriesList = () => {
    dispatch(showSpinner('Obteniendo Lista de Países'))
    getCountries()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setCountriesList(data.data)
        }
        // else {
        //   if (data?.status == 401) {
        //     // goToLogin()
        //     return
        //   }
        //   if (data?.status >= 400 || data?.status <= 599) {
        //     //TODO
        //     //useEffect(() => {
        //     //  changeToastInfo({ msg: data.message, severity: 'info' })
        //     //}, [])
        //   }
        // }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const clickSaveNewCountry = country => {
    dispatch(showSpinner('Registrando País'))
    saveCountry(country)
      .then(data => {
        if (data.error) {
          setError('Error al registrar el país, intente mas tarde')
          dispatch(hiddeSpinner())
        } else {
          setSuccess('Se registro el país correctamente')
          dispatch(hiddeSpinner())
          setIsOpenFormModal(false)
        }
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const handleOpenNewCountryModal = () => {
    reset({
      id: undefined,
      countryInput: '',
      nameInput: '',
      statusInput: '',
      searchInput: watch('searchInput'),
    })
    setIsOpenFormModal(true)
  }

  const handleCloseNewCountryModal = () => {
    setIsOpenFormModal(false)
  }

  useEffect(() => {
    getStatesList()
    getCountriesList()
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    if (defaultValues?.id) {
      reset({
        ...defaultValues,
        searchInput: watch('searchInput'),
      })
    }
  }, [defaultValues])

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Layout
      title={t('state.title')}
      description={t('state.title')}
      showBreadcrumb={true}
      currentPage={t('state.title')}>
      <Box component="main" sx={{ flexGrow: 1, pY: 3 }}>
        <Container maxWidth="lg">
          <Grid item xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Tooltip title={t('state.back')} arrow>
                <IconButton aria-label="back icon" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{t('state.subtitle')}</Typography>
              <Tooltip title={t('state.add')} arrow>
                <IconButton aria-label="add icon" sx={{ mr: 2 }} color="primary" onClick={handleOpenNewCountryModal}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <Stack direction="row" py="1rem">
              <TextField
                focused={watch('searchInput') !== ''}
                {...register('searchInput')}
                placeholder={t('state.searchButton')}
                type="text"
                variant="standard"
                sx={{ width: '100%', maxWidth: { xs: '100%', md: '30vw', lg: '20vw' } }}
              />
            </Stack>
            <DataGrid
              rows={filteredCountries}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 5, page: 0, pageCount: 1 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              getRowId={row => row?.id}
              autoHeight
            />
          </Grid>
        </Container>
      </Box>

      <Dialog
        open={isOpenDeleteModal}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{t('state.deleteModal.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('state.deleteModal.text')}
            <br />
            {detailsStates?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>{t('state.deleteModal.cancelButton')}</Button>
          <Button variant="contained" onClick={() => openModalDelete(detailsStates)}>
            {t('state.deleteModal.saveButton')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenFormModal}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description"
        component="form"
        maxWidth="md"
        onSubmit={handleSubmit(clickSaveNewCountry)}>
        <DialogTitle>
          {defaultValues?.id === undefined
            ? t('state.form.titleNew')
            : `${t('state.form.titleEdit')} - ${defaultValues?.nameInput}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container>
              <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
                <SelectForm
                  label={t('state.form.countryInput.label')}
                  name="countryInput"
                  defaultValue={getValues('countryInput')?.toString()}
                  options={countriesList.map(country => ({ key: country?.name, value: country?.id }))}
                  register={register}
                  validations={{
                    onChange: ({ target }) =>
                      setDefaultValues(() => ({
                        ...getValues(),
                        countryInput: target.value,
                      })),
                    required: t('state.form.countryInput.required'),
                  }}
                  watch={watch}
                  error={errors}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  focused={watch('nameInput') !== ''}
                  {...register('nameInput', {
                    required: t('state.form.nameInput.required'),
                    pattern: {
                      value: validateOnlyNumberStringSigns(),
                      message: t('state.form.nameInput.patternMessage'),
                    },
                  })}
                  label={t('state.form.nameInput.label')}
                  type="text"
                  variant="outlined"
                  helperText={errors?.nameInput?.message}
                  error={errors?.nameInput?.message?.length > 0}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
                <SelectForm
                  label={t('state.form.statusInput.label')}
                  name="statusInput"
                  defaultValue={getValues('statusInput')?.toString()}
                  options={[
                    { key: 'Activo', value: true },
                    { key: 'Inactivo', value: false },
                  ]}
                  register={register}
                  validations={{
                    onChange: ({ target }) =>
                      setDefaultValues(() => ({
                        ...getValues(),
                        statusInput: target.value,
                      })),
                    required: t('state.form.statusInput.required'),
                  }}
                  watch={watch}
                  error={errors}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewCountryModal}>{t('state.form.cancelButton')}</Button>
          <Button variant="contained" type="submit">
            {t('state.form.saveButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminState
