// Core Imports
import React from 'react';

// Custom Component Imports
import CryptomarketSection from './CryptomarketSection';
import Layout from '../core/Layout';

// TODO: Explanation
const Cryptomarkets = () => {

    // TODO: Is it printingTypeId hard-coded?
    return (
        <Layout 
            title="Screen Printing Page"
            description="Ecommerce"
            showBreadcrumb={false}
        >
            <CryptomarketSection printingTypeId='5ee5a2c62055cc54dc5ecf4a' />
        </Layout>
    );
};

export default Cryptomarkets;