// Core Imports
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import React, { useContext, useEffect, useState } from 'react'
import { AvatarLetter } from '../components/AvatarLetter/AvatarLetter'
import UploadService from '../services/FileUploadService'

import Config from '../config'
///// For WebCam
import { WebcamCapture } from '../components/Webcam/Webcam'
import Layout from '../core/Layout'

// Function Imports
import { isAuthenticated, signout } from '../auth/functions'

// API Impports
import { CameraAlt, FileUpload } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { PhoneNumberForm } from '../components/form'
import SelectForm from '../components/form/SelectForm'
import { ToastContext } from '../context'
import { faceMatching, getCountries, getStatesCountryID, updateUser } from '../services/APICalls'
import { validateEmail, validateOnlyNumberString, validateOnlyString } from '../utils'
import { useTranslation } from 'react-i18next'

const { upload: uploadFile, uploadShot } = UploadService

// TODO: Explanation
const Dashboard = ({ history }) => {
  const { t } = useTranslation()
  const { user, token, kyc } = isAuthenticated()
  const { changeToastInfo } = useContext(ToastContext)
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [defaultValues, setDefaultValues] = useState({})
  const [currentUrlSelfieValid, setCurrentUrlSelfieValid] = useState(false)
  const [currentUrlDocumentValid, setCurrentUrlDocumentValid] = useState(false)
  const [openNotificacionRes, setOpenNotificationRes] = useState(false)
  const [openCamera, setOpenCamera] = useState(false)
  const [openCamera2, setOpenCamera2] = useState(false)
  const [notificationResponse, setNotificationResponse] = useState({
    message: '',
    success: false,
  })
  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const { currentUrlSelfie, currentUrlDocument } = defaultValues

  const isDisabledForVerifiedEmail = () => {
    return kyc?.updated_kyc
  }

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos

    setDefaultValues({
      address_id: kyc.address_id,
      address: kyc.address,
      bod: new Date(kyc.bod),
      city: kyc.city,
      country: kyc.country_cod,
      currentUrlSelfie: kyc.url,
      currentUrlDocument: kyc.urlDocument,
      email: kyc.email,
      gender: kyc.kyc_gender_id,
      kyc_id: kyc.kyc_id,
      kycdocuments_id: kyc.kycdocuments_id,
      lastName: kyc.lastName,
      login: user.login,
      name: kyc.name,
      phoneNumber: kyc?.phone?.replace('+580', '+58'),
      postalCode: kyc.zip,
      state: kyc.state_cod,
      userActive: user.active,
      verifiedAddress: kyc.verifiedAddress,
      verifiedEmail: kyc.verifiedEmail,
      verifiedPhone: kyc.verifiedPhone,
    })
    if (kyc?.url?.length > 9) {
      setCurrentUrlSelfieValid(true)
    }
    if (kyc?.urlDocument?.length > 9) {
      setCurrentUrlDocumentValid(true)
    }
    getCountriesList()
    if (kyc.country_cod !== null && kyc.country_cod !== undefined && kyc.country_cod !== '') {
      getStatesList(kyc.country_cod)
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)

    if (currentUrlSelfie !== '' && currentUrlDocument !== '') {
      validateImage()
    }
  }, [defaultValues])

  const getCountriesList = async () => {
    const response = await getCountries()
    if (response.status == 401) {
      goToLogin()
      return
    }
    if (Array.isArray(response.data)) {
      setCountries(response.data)
    }
  }

  const getStatesList = async countryId => {
    const response = await getStatesCountryID(countryId)
    if (response.status == 401) {
      goToLogin()
    }
    if (Array.isArray(response.data)) {
      setStates(response.data)
    }
  }

  const onSubmitForm = async data => {
    try {
      if (currentUrlSelfieValid == false || currentUrlDocumentValid == false) {
        setNotificationResponse({
          message: ':( Debes subir una selfie y documento de identidad, antes de guardar los cambios',
          success: false,
        })
      } else {
        data.uploadFile = kyc.url
        data.uploadFileDocument = kyc.urlDocument
        const response = await updateUser(user.id, token, data)
        if (response.status == 401) {
          goToLogin()
        }
        if (response.status >= 400 && response.status <= 499) {
          setNotificationResponse({ message: response.message, success: false })
        } else {
          setNotificationResponse({ message: response.message, success: true })
        }
      }
    } catch (error) {
      setNotificationResponse({ message: 'Ocurrio un error', success: false })
    }
    const saveDataOnStorage = { ...data, country_cod: data?.country }
    saveKyc(saveDataOnStorage)
    setOpenNotificationRes(true)
    // delete data.email
    // delete data.login
    // delete data.lastName
    // delete data.name
    // delete data.phoneNumber
  }

  const saveKyc = ({
    currentUrlSelfie: url,
    currentUrlDocument: urlDocument,
    gender: kyc_gender_id,
    phoneNumber: phone,
    postalCode: zip,
    state: state_cod,
    ...rest
  }) => {
    const kyc = {
      ...rest,
      url,
      urlDocument,
      kyc_gender_id,
      phone,
      zip,
      state_cod,
    }
    const data = {
      kyc,
      token,
      user,
    }

    localStorage.setItem('jwt', JSON.stringify(data))
  }

  const clickSignout = event => {
    event.preventDefault()
    localStorage.removeItem('currentUser')
    changeToastInfo({ msg: t('byeMsg'), severity: 'info' })
    signout(() => history.push('/'))
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    changeToastInfo({ msg: `Debe Iniciar Sesión`, severity: 'info' })
    signout(() => history.push('/user/auth'))
  }

  const handleClose = () => setOpenNotificationRes(false)

  const validateImage = async () => {
    console.log('HICE PETICION FACEMATCHING');
    const response = await faceMatching({
      face1: `${Config.API}${currentUrlSelfie}`,
      face2: `${Config.API}${currentUrlDocument}`,
    })
    console.log('$$$$$$$$$$$response facematching$$$$$$$$$$$$$',response);

    if (response?.data?.distance >= 0.6) {
      setCurrentUrlSelfieValid(true)
      setCurrentUrlDocumentValid(true)
      setNotificationResponse({
        message: t('userProfile.matchSuccessfully'),
        success: true,
      })
    } else {
      setCurrentUrlSelfieValid(false)
      setCurrentUrlDocumentValid(false)
      setNotificationResponse({
        message: t('userProfile.matchFailed'),
        success: false,
      })
    }
  }

  const uploadArchive = async () => {
    const { 0: file } = getValues('uploadFileI')

    try {
      const response = await uploadFile(file, user.id)

      if (response?.error) {
        setNotificationResponse({ message: t('userProfile.uploadSelfieFailed'), success: false })
        setOpenNotificationRes(true)
        return
      }
      // setNotificationResponse({ message: 'Se ha cargado tu foto efectivamente', success: true })
      setOpenNotificationRes(true)
      setDefaultValues(actualValues => ({
        ...actualValues,
        currentUrlSelfie: `/images/${user.id}_${response.message}`,
      }))
      setCurrentUrlSelfieValid(true)
      saveKyc(watch())
    } catch (error) {
      setNotificationResponse({ message: t('userProfile.uploadSelfieFailed'), success: false })
      setOpenNotificationRes(true)
    }
  }

  const uploadArchiveDocument = async () => {
    const { 0: file } = getValues('uploadFileI')

    try {
      const response = await uploadFile(file, user.id)

      if (response?.error) {
        // setNotificationResponse({ message: 'No logramos cargar tu foto', success: false })
        setOpenNotificationRes(true)
        return
      }
      setNotificationResponse({ message: t('userProfile.uploadSelfieSuccessfully'), success: true })
      setOpenNotificationRes(true)
      setDefaultValues(actualValues => ({
        ...actualValues,
        currentUrlDocument: `/images/${user.id}_${response.message}`,
      }))
      setCurrentUrlDocumentValid(true)
      saveKyc(watch())
    } catch (error) {
      setNotificationResponse({ message: t('userProfile.uploadDocumentFailed'), success: false })
      setOpenNotificationRes(true)
    }
  }

  const uploadSelfie = async dataImage => {
    try {
      const response = await uploadShot(dataImage, user.id)

      if (response?.error) {
        // setNotificationResponse({ message: 'No logramos cargar tu foto', success: false })
        setOpenNotificationRes(true)
        return
      }
      setDefaultValues(actualValues => ({
        ...actualValues,
        currentUrlSelfie: `${response}`,
      }))
      setCurrentUrlSelfieValid(true)
      // setNotificationResponse({ message: t('userProfile.uploadSelfieSuccessfully'), success: true })
      setOpenNotificationRes(true)
      setOpenCamera(false)
      saveKyc(watch())
    } catch (error) {
      setNotificationResponse({ message: t('userProfile.uploadSelfieFailed'), success: false })
      setOpenNotificationRes(true)
    }
  }

  const uploadSelfieDocument = async dataImage => {
    try {
      const response = await uploadShot(dataImage, user.id)

      if (response?.error) {
        setNotificationResponse({ message: t('userProfile.uploadSelfieFailed'), success: false })
        setOpenNotificationRes(true)
        return
      }
      setDefaultValues(actualValues => ({
        ...actualValues,
        currentUrlDocument: `${response}`,
      }))
      setCurrentUrlDocumentValid(true)
      setNotificationResponse({ message: 'Se ha cargado el documento efectivamente', success: true })
      setOpenNotificationRes(true)
      setOpenCamera2(false)
      saveKyc(watch())
    } catch (error) {
      setNotificationResponse({ message: t('userProfile.uploadDocumentFailed'), success: false })
      setOpenNotificationRes(true)
    }
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render

  return (
    <Layout title="Usuario Perfil" description="Exchange" showBreadcrumb={true} currentPage="Dashboard Usuario">
      <Box component="main" sx={{ flexGrow: 1, px: 8 }}>
        {/* {userInfo()} */}
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container item xs={12} justifyContent="center">
            {openCamera && <WebcamCapture userId={user.id} update={selfie => uploadSelfie(selfie)} />}
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            {openCamera2 && <WebcamCapture userId={user.id} update={selfie => uploadSelfieDocument(selfie)} />}
          </Grid>
          <Grid container spacing={2}>
            {!defaultValues?.verifiedEmail && (
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Alert severity="info" sx={{ width: '100%' }}>
                  {t('userProfile.alertCheckEmail')}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h5">{t('userProfile.firstSectionTitle')}</Typography>
            </Grid>
            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center">
                <Typography variant="h5">{t('userProfile.profileImage')}</Typography>
                <AvatarLetter
                  name={defaultValues?.name}
                  styles={{ width: '10vw', height: '10vw' }}
                  url={
                    defaultValues?.currentUrlSelfie !== ''
                      ? `${Config.API}${defaultValues?.currentUrlSelfie}`.replace('api//', 'api/')
                      : null
                  }
                />
                {kyc?.url == null && (
                  <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Tooltip title="Subir imagen" arrow>
                      <IconButton color="primary" aria-label="upload picture" component="label">
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          style={{ display: 'none' }}
                          {...register('uploadFileI', {
                            onChange: () => uploadArchive(),
                          })}
                        />
                        <FileUpload />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Tomar una foto" arrow>
                      <IconButton color="primary" aria-label="upload picture" component="label">
                        <CameraAlt onClick={() => setOpenCamera(true)} />
                      </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center">
                <Typography variant="h5">{t('userProfile.dni')}</Typography>
                <AvatarLetter
                  name={defaultValues?.name}
                  styles={{ width: '10vw', height: '10vw' }}
                  url={
                    defaultValues?.currentUrlDocument !== ''
                      ? `${Config.API}${defaultValues?.currentUrlDocument}`.replace('api//', 'api/')
                      : null
                  }
                />
                {kyc?.urlDocument == null && (
                  <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Tooltip title="Subir imagen" arrow>
                      <IconButton color="primary" aria-label="upload document" component="label">
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          style={{ display: 'none' }}
                          {...register('uploadFileI', {
                            onChange: () => uploadArchiveDocument(),
                          })}
                        />
                        <FileUpload />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Tomar una foto" arrow>
                      <IconButton color="primary" aria-label="upload picture" component="label">
                        <CameraAlt onClick={() => setOpenCamera2(true)} />
                      </IconButton>
                    </Tooltip>
                  </ButtonGroup>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: t('userProfile.form.nameInput.required'),
                  pattern: {
                    value: validateOnlyString(),
                    message: t('userProfile.form.nameInput.patternMessage'),
                  },
                })}
                label={t('userProfile.form.nameInput.label')}
                disabled={isDisabledForVerifiedEmail}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                focused={watch('lastName') !== ''}
                {...register('lastName', {
                  required: t('userProfile.form.lastNameInput.required'),
                  pattern: {
                    value: validateOnlyString(),
                    message: t('userProfile.form.lastNameInput.patternMessage'),
                  },
                })}
                label={t('userProfile.form.lastNameInput.label')}
                disabled={isDisabledForVerifiedEmail}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                variant="outlined"
                helperText={errors?.lastName?.message}
                error={errors?.lastName?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label={t('userProfile.form.dateInput.label')}
                  disabled={kyc?.bod == null ? false : true}
                  inputFormat="dd/MM/yyyy"
                  // value={bod}
                  // onChange={handleChange}
                  renderInput={params => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectForm
                defaultValue={getValues('gender')?.toString()}
                label={t('userProfile.form.genderInput.label')}
                disabled={isDisabledForVerifiedEmail}
                name="gender"
                options={[
                  { key: 'Masculino', value: 1 },
                  { key: 'Femenino', value: 2 },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) => {
                    setDefaultValues(() => ({
                      ...getValues(),
                      gender: target.value,
                    }))
                  },
                  required: t('userProfile.form.genderInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                focused={watch('email') !== ''}
                {...register('email', {
                  required: t('userProfile.form.emailInput.required'),
                  pattern: {
                    value: validateEmail(),
                    message: t('userProfile.form.emailInput.patternMessage'),
                  },
                })}
                label={t('userProfile.form.emailInput.label')}
                disabled={true}
                type="text"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                helperText={errors?.email?.message}
                error={errors?.email?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <PhoneNumberForm
                fullWidth
                focused={watch('phoneNumber') !== '' && watch('phoneNumber') !== undefined}
                name={'phoneNumber'}
                label={t('userProfile.form.phoneNumber.label')}
                disabled={isDisabledForVerifiedEmail}
                control={control}
                helperText={errors?.phoneNumber?.message}
                error={errors?.phoneNumber?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="h5">{t('userProfile.form.addressTitle')} </Typography>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <SelectForm
                defaultValue={getValues('country')?.toString()}
                label={t('userProfile.form.countryInput.label')}
                name="country"
                disabled={isDisabledForVerifiedEmail}
                options={countries.map(country => ({ key: country?.name, value: country?.id }))}
                register={register}
                validations={{
                  onChange: ({ target }) => {
                    setDefaultValues(() => ({
                      ...getValues(),
                      country: target.value,
                    }))
                    getStatesList(target?.value)
                  },
                  required: t('userProfile.form.countryInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <SelectForm
                defaultValue={getValues('state')?.toString()}
                label={t('userProfile.form.stateInput.label')}
                name="state"
                disabled={isDisabledForVerifiedEmail}
                options={states.map(state => ({ key: state?.name, value: state?.id }))}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      state: target.value,
                    })),
                  required: t('userProfile.form.stateInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                focused={watch('city') !== ''}
                {...register('city', {
                  required: t('userProfile.form.cityInput.required'),
                  pattern: {
                    value: validateOnlyString(),
                    message: t('userProfile.form.cityInput.patternMessage'),
                  },
                })}
                label={t('userProfile.form.cityInput.label')}
                disabled={isDisabledForVerifiedEmail}
                type="text"
                variant="outlined"
                helperText={errors?.city?.message}
                error={errors?.city?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                focused={watch('postalCode') !== ''}
                {...register('postalCode', {
                  required: t('userProfile.form.postalCodeInput.required'),
                  pattern: {
                    value: validateOnlyNumberString(),
                    message: t('userProfile.form.postalCodeInput.patternMessage'),
                  },
                })}
                label={t('userProfile.form.postalCodeInput.label')}
                disabled={isDisabledForVerifiedEmail}
                type="text"
                variant="outlined"
                helperText={errors?.postalCode?.message}
                error={errors?.postalCode?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                focused={watch('address') !== ''}
                {...register('address', {
                  required: t('userProfile.form.addressInput.required'),
                  pattern: {
                    value: validateOnlyString(),
                    message: t('userProfile.form.addressInput.patternMessage'),
                  },
                })}
                label={t('userProfile.form.addressInput.label')}
                disabled={isDisabledForVerifiedEmail}
                type="text"
                variant="outlined"
                helperText={errors?.address?.message}
                error={errors?.address?.message?.length > 0}
              />
            </Grid>
          </Grid>
          <Grid container marginTop="0.5rem">
            {!isDisabledForVerifiedEmail && (
              <Grid xs={12} md={6} paddingRight={{ xs: 0, md: 2 }} paddingY="0.5rem">
                <Button type="button" onClick={handleSubmit(onSubmitForm)} variant="contained" fullWidth>
                  {t('userProfile.form.saveButton')}
                </Button>
              </Grid>
            )}
            <Grid xs={12} md={6} paddingLeft={{ xs: 0, md: 2 }} paddingY="0.5rem">
              <Button type="button" onClick={clickSignout} variant="contained" color="error" fullWidth>
                {t('userProfile.form.signOutButton')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Snackbar open={openNotificacionRes} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={notificationResponse?.success ? 'success' : 'error'}
          sx={{ width: '100%' }}>
          {notificationResponse?.message}
        </Alert>
      </Snackbar>
    </Layout>
  )
}

export default Dashboard
