// Core Imports
import { Grid, Link, Paper, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useState, useEffect } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'

//import FileUpload from "../components/FileUploadDownload/FileUpload";
// Custom Component Imports
import Layout from '../core/Layout'

// API Impports
import { checkMailId } from '../services/APICalls'

// TODO: Explanation
const EmailVerify = ({ history }) => {
  const { verifyid: verifyId } = useParams()
  // Initial state

  const [success, setSuccess] = useState({
    isSuccess: false,
    message: 'Actualmente nos encontramos verificando tu cuenta, por favor espera...'
  })

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    if (verifyId) {
      executeCheck(verifyId)
      return
    }
    setSuccess((e) => ({ ...e, message: 'Lo sentimos, pero tu cuenta no logró ser verificada' }))
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  // Methods --------------------------------------------------------------------------------------

  const executeCheck = (id) => {
    checkMailId(id).then((data) => {
      if (data === undefined || data.error) {
        setSuccess((e) => ({ ...e, message: 'Lo sentimos, pero tu cuenta no logró ser verificada' }))
        return
      }
      setSuccess((e) => ({ isSuccess: true, message: 'Logramos verificar exitosamente tu cuenta' }))
    })
  }

  // Main Render
  return (
    <Layout
      title='Verificar Correo'
      description='Exchange'
      showBreadcrumb={false}
      currentPage='Comprobar Cuenta de Correo'
    >
      <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{ minHeight: '100vh', backgroundColor: green[400], padding: 4 }}
      >
        <Grid item xs={12}>
          <Paper elevation={4} sx={{ px: 8, py: 4 }}>
            <Typography variant='h3' component='h1'>
              Verificación de correo electrónico
            </Typography>
            <Grid container>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Typography textAlign='center' variant='subtitle1'>
                  {success?.message}{' '}
                  {!!success.isSuccess && (
                    <Link component={RouterLink} to='/user/auth'>
                      ir al login
                    </Link>
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default EmailVerify
