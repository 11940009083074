import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'

// Icons Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// API Imports
import { getBList, createBList, updateBList } from '../../services/APICalls'

// Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

//Initial Values
const initValues = {
  id: '',
  crypto_id: '',
  address: '',
  currency_acronym: '',
  active: '',
}

const FormBList = () => {
  // Initial State
  const dispatch = useDispatch()
  const history = useHistory()
  // URL Parameters
  const paramsBListForm = useParams({})
  // Valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  // Manejo de formulario
  const {
    handleSubmit, //manejador de evento sintetico de submit
    register, // registro de campos y validaciones
    reset, // reseteo de funciones iniciales
    watch, // observa el valor actual de un campo especifico
    getValues, // obtiene el valor de uno o todos los campos
    formState: { errors }, // estado del formulario y desestructuro los errores
  } = useForm(defaultValues) // inicializacion del formulario con los valores por defecto

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getDetailsBList = async () => {
    dispatch(showSpinner('Obteniendo Información de Dirección en Lista Negra'))
    getBList(paramsBListForm?.idBList)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setDefaultValues(data.data[0])
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const onSubmitForm = black_list => {
    const updateBList = {
      id: black_list.id,
      crypto_id: black_list.crypto_id,
      address: black_list.address,
      currency_acronym: black_list.currency_acronym,
      active: black_list.active,
    }
    if (paramsBListForm?.idBList !== undefined) {
      SendUpdateBList(updateBList)
      goToBListList()
      return
    }
    sendCreateBList(updateBList)
    goToBListList()
    return
  }

  const SendUpdateBList = async black_list => {
    try {
      await updateBList(black_list)
      return
    } catch (error) {
      console.error(error)
    }
  }

  const sendCreateBList = async black_list => {
    try {
      await createBList(black_list)
    } catch (error) {
      console.error(error)
    }
  }

  const goToBListList = () => {
    history.push('/admin/blacklist')
  }

  useEffect(() => {
    if (paramsBListForm?.idBList !== undefined) {
      getDetailsBList()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsBListForm?.idBList !== undefined ? 'EDICION' : 'REGISTRO'} DE DIRECCIÓN EN LISTA NEGRA`}
      description={`${
        paramsBListForm?.idBList !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de lista blanca y negra`}
      showBreadcrumb={true}
      currentPage={`${paramsBListForm?.idBList !== undefined ? 'EDICION' : 'REGISTRO'} DE DIRECCION EN LISTA NEGRA`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" tittle="Volver" onClick={() => goToBListList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('crypto_id') !== ''}
                {...register('crypto_id')}
                label="ID Cripto"
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('currency_acronym') !== ''}
                {...register('currency_acronym')}
                label="Moneda"
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('address') !== ''}
                {...register('address')}
                label="Dirección"
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormBList
