// Core Imports
import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import { useHistory, useParams } from "react-router-dom";

// Custom Component Imports

import Alert from '../common/Alert'

// Selector Imports
import { isAuthenticated, signout } from '../auth/functions';

// API Imports
import { getOrdersByUserIdAndCriptoId } from '../services/APICalls';
import { useTranslation } from 'react-i18next';

// TODO: Explanation
const OrdersDataTable = ({ userInput }) => {
    let history = useHistory();
    const { t } = useTranslation()
    const paramsDataTable = useParams({});
    // Init state
    const [orders, setOrders] = useState(0);
    const { token} = isAuthenticated();
    const [selectedCountries, setSelectedCountries] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState('');
    const [dataTableError, setDataTableError] = useState(false);
    const [dataTableSuccess, setDataTableSuccess] = useState(false);
    const [newName, setNewName] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [userSelected, setUserSelected] = useState(userInput);


    const { user } = isAuthenticated();
    const goToPageTransactions = (order_id) => {
        history.push(`/user/transactions/${order_id}/currencies/${paramsDataTable?.cripto_id}`);
    };

    const goToLogin = () => {
        localStorage.removeItem('currentUser')
        signout(() => history.push('/user/auth'))
      }

    // Initial columns configuration
    const columns = [
        {
            name: t('orders.orderTable.nOrder'),
            width: "110px",
            selector: 'order_id',
            sortable: true
        },
        {
            name: t('orders.orderTable.description'),
            selector: 'description',
            sortable: true,
            cell: row => <div style={{fontSize: 12}}>{row.description}</div>
        },
        {
            name: t('orders.orderTable.status'),
            width: "110px",
            selector: 'is_paid',
            sortable: true,
            cell: row => <div>{row.status_description}  {row.active ? t('orders.orderTable.statusTrue') : t('orders.orderTable.statusFalse')}  {row.payment_method_description}</div>
        },
        {
            name: t('orders.orderTable.amount'),
            selector: 'order_amount',
            sortable: true,
            cell: row => <div>{row.order_amount.toFixed(8)}  {row.acronym}</div>
        },
        {
             name: t('orders.orderTable.fees'),
            selector: 'fee_blockchain',
            sortable: true,
            cell: row => <div>{(row.fee_blockchain.toFixed(8) * 1) + (row.fee_exchange.toFixed(8) * 1)}  {row.acronym}</div>
        },
        {
            name: t('orders.orderTable.date'),
            selector: 'order_date',
            sortable: true
        },
        {
            name: t('orders.orderTable.actions'),
            cell: row => actionsBtns(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ];

    // React Effects -------------------------------------------------------------------------------


    useEffect(() => {


        if (orders) {
            const items = filterText == '' ? orders : orders.filter(item => item.name && item.name.toUpperCase().includes(filterText.toUpperCase()));
            setFilteredItems(items);
        }

    }, [filterText, orders]);

    useEffect(() => {
        if (userInput) {

            loadOrders(userInput);

        }
        else {

            setUserSelected(user.id);
        }
    }, [userInput]);

    // END React Effects ---------------------------------------------------------------------------

    // Methods -------------------------------------------------------------------------------------

    const loadOrders = (userInput) => {
     console.log('userInput:::',userInput)
        getOrdersByUserIdAndCriptoId(userInput, paramsDataTable?.cripto_id).then(data => {
            
            console.log('DATA:+++++++++',data);
            if (data.status == 401) {
                goToLogin();
            }
            if (data.status >= 400 && data.status <= 599) {
                //TODO
            }
            else {
                setOrders(data?.data);
            }
        });
    }

    // END Methods ---------------------------------------------------------------------------------

    // Events --------------------------------------------------------------------------------------
 

    // END Events ----------------------------------------------------------------------------------

    // Render Methods ------------------------------------------------------------------------------

    const actionsBtns = row => (
        <>
            <button
                type="button"
                className="btn btn-default btn-sm btn-icon"
                aria-label="qr"
                onClick={() => goToPageTransactions(row?.order_id)}>
                <span className="glyphicon glyphicon glyphicon-list" aria-hidden="true"></span>
            </button>
        </>
    );



    const FilterComponent = (filterText) => (
        <div className="container ptb--0">
            <div className="row">
                <div className="col-md-8 col-lg-8">
                    <div className="ckeckout-left-sidebar">
                        <div className="row">
                            <div className="checkout-form">
                                <div className="pasadena-checkout-form-inner">
                                    <div className="pasadena-single-box">
                                        <div className="form-row">
                                            <div className="form-group col-xs-12 col-sm-9 col-lg-9">
                                                <div className="">
                                                    <div className="input-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={t('orders.placeHolderSearch')}
                                                            aria-describedby="sizing-addon2"
                                                            value={filterText}
                                                            onChange={e => setFilterText(e.target.value)}
                                                            style={{ borderRadius: '0' }}
                                                        />
                                                        <span className="input-group-addon" id="sizing-addon2" style={{ borderRadius: '0' }}>{t('orders.searchButton')}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );


    // END Render Methods --------------------------------------------------------------------------

    // Main Render
    return (
        <Fragment>
            <div className="row">
                <Alert
                    error={dataTableError}
                    success={dataTableSuccess}
                    onError={(value) => { setDataTableError(value) }}
                    onSuccess={(value) => { setDataTableSuccess(value) }}
                />
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortField="name"
                pagination
                highlightOnHover
                pointerOnHover
                subHeader
                subHeaderComponent={[FilterComponent()]}
            />
        </Fragment>
    );
}

export default OrdersDataTable;