import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Grid, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from '@mui/material/Tooltip'
import ButtonGroup from "@mui/material/ButtonGroup";

// import Table from '../../components/Table/Table.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'

// Custom Component Imports
import Layout from '../../core/Layout'

// API Impports
import { getWListUsers } from '../../services/APICalls'
import { isAuthenticated } from '../../auth/functions.js'
import { useTranslation } from 'react-i18next'


const AdminWList = () => {
  const { t } = useTranslation()
  const { user } = isAuthenticated()
  
  let history = useHistory()
  const [data, setData] = useState([])
  
  const cells = [t('whiteList.whiteListTable.address'), t('whiteList.whiteListTable.currency'), t('whiteList.whiteListTable.copy'), t('whiteList.whiteListTable.active')]
  const getListWhiteList = () => {
    getWListUsers(user.id).then((response) => {
      if (Array.isArray(response)) {
        setData(response)
      }
    })
  }

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getListWhiteList()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
    title={t('whiteList.title')}
    description={t('whiteList.description')}
    showBreadcrumb={true}
    currentPage={t('whiteList.title')}
    >
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}></Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction='row' alignItems='center' spacing={6}>
              <Tooltip title='Volver' arrow>
                <IconButton aria-label='Volver' onClick={() => history.push('/user/briefcase')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Stack direction='row' width="100%">
                <Typography variant="h5" width="100%" display="inline-block" textAlign="center">{t('whiteList.subtitle')}</Typography>
              </Stack>
            </Stack>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    {cells.map((cell) => (
                      <TableCell key={`cell-${cell}`}>{cell}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                    >
                      <TableCell component='th' scope='row'>
                        {row.address}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {row.currency_acronym}
                      </TableCell>
                      <TableCell>
                        <ButtonGroup>
                          <Tooltip title={t('whiteList.whiteListTable.copy')} arrow onClick={() => { navigator.clipboard.writeText(row.address) }}>
                            <IconButton aria-label={t('whiteList.whiteListTable.copy')}>
                              <ContentCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </ButtonGroup>
                      </TableCell>
                      <TableCell>{row.active ? t('whiteList.whiteListTable.activeOption') : t('whiteList.whiteListTable.inactiveOption')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      </Box>
    </Layout>
  )
}

export default AdminWList
