// Core Imports
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Config from '../config'
import { useTranslation } from 'react-i18next';

// TODO: Explanation
const Footer = ({history}) => {
    const { t } = useTranslation()
    // Main render
    return (
        <footer className="htc__foooter__area gray-bg mt--40">
            <div className="container">
                <div className="row">
                    <div className="footer__container clearfix">
                         
                        <div className="col-md-3 col-lg-4 col-sm-6">
                            <div className="ft__widget">
                                <h2 className="ft__title">
                                    <Link className="" to="/">{t('footer.contactUs')}</Link>
                                </h2>
                                <div className="footer-address">
                                    <ul>
                                        <li>
                                            <div className="address-icon">
                                                <i className="zmdi zmdi-pin"></i>
                                            </div>
                                            <div className="address-text">
                                                <p>{Config.CRIPTOPAGOS_INFO.address}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="address-icon">
                                                <i className="zmdi zmdi-email"></i>
                                            </div>
                                            <div className="address-text">
                                                <a href={`mailto:${Config.CRIPTOPAGOS_INFO.contact_email}`}>{Config.CRIPTOPAGOS_INFO.contact_email}</a>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="address-icon">
                                                <i className="zmdi zmdi-phone-in-talk"></i>
                                            </div>
                                            <div className="address-text">
                                                <p>{Config.CRIPTOPAGOS_INFO.contact_phone}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="social__icon"><br></br>
                                    <li><a href={Config.CRIPTOPAGOS_INFO.social_network.twitter}><i className="zmdi zmdi-twitter"></i></a></li>
                                    <li><a href={Config.CRIPTOPAGOS_INFO.social_network.instagram}><i className="zmdi zmdi-instagram"></i></a></li>
                                    <li><a href={Config.CRIPTOPAGOS_INFO.social_network.facebook}><i className="zmdi zmdi-facebook"></i></a></li>
                                    <li><a href={Config.CRIPTOPAGOS_INFO.social_network.facebook}><i className="zmdi zmdi-google-plus"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    
                        <div className="col-md-3 col-lg-4 col-sm-6 smt-30 xmt-30">
                            <div className="ft__widget">
                                <h2 className="ft__title">{t('footer.categories')}</h2>
                                <ul className="footer-categories">
                                    <li><Link to="/screen-printing/traditional">{t('footer.marketValues')}</Link></li>
                                    <li><Link to="/screen-printing/htv">{t('footer.exchange')}</Link></li>
                                    <li><Link to="/sublimation">{t('footer.remittances')}</Link></li>
                                    <li><Link to="/embroidery">{t('footer.ATMs')}</Link></li>
                                    <li><Link to="/engraving">{t('footer.pos')}</Link></li>
                                    <li><Link to="/merchandise">{t('footer.agencies')}</Link></li>
                                </ul>
                            </div>
                        </div>
                      
                        <div className="col-md-3 col-lg-4 col-sm-6 smt-30 xmt-30">
                            <div className="ft__widget">
                                <h2 className="ft__title">{t('footer.information')}</h2>
                                <ul className="footer-categories">
                                    <li><a href={`mailto:${Config.CRIPTOPAGOS_INFO.contact_email}`}>{t('footer.contact')}</a></li>
                                    <li><a>{t('footer.termsAndConditions')}</a></li>
                                    <li><a>{t('footer.exchange')}</a></li>
                                    <li><a>{t('footer.shippingAndDispatchOfCards')}</a></li>
                                    <li><a>{t('footer.privacyPolicies')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div className="htc__copyright__area">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div className="copyright__inner">
                                <div className="copyright">
                                    <p>© 2023 <Link className="" to="/">CoinMax</Link> {t('footer.allRightsReserved')}.</p>
                                </div>
                                <ul className="footer__menu">
                                    <li><Link className="" to="/">{t('footer.home')}</Link></li>
                                    <li><a href={`mailto:${Config.CRIPTOPAGOS_INFO.contact_email}`}>{t('footer.contactUs')}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </footer>
    );

};

export default withRouter(Footer);