import React from 'react'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

const SelectForm = ({
  defaultValue = '',
  error,
  label,
  name,
  options = [],
  optionsKey = 'key',
  optionsValue = 'value',
  register = () => {},
  validations = {},
  watch = () => {},
  disabled = false
}) => {
  
  return (
    <TextField
      fullWidth
      focused={watch(name) !== ''}
      value={defaultValue?.toString()}
      {...register(name, validations)}
      select
      label={label}
      helperText={error?.[name]?.message}
      error={error?.[name]?.message?.length > 0}
      disabled={disabled}
    >
      {options.map((l, index) => (
        <MenuItem key={index} value={l[optionsValue] !== undefined ? l[optionsValue] : l}>
          {l[optionsKey] || l}
        </MenuItem>
      ))}
    </TextField>
  )
}
export default SelectForm
