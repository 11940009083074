// Core Imports
import React, { Fragment, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import QRCode from 'react-qr-code'
import { useHistory } from 'react-router-dom'

//Son las librerias que usa el stepper
import { StepperWList } from './components'

// Custom Component Imports

import Alert from '../common/Alert'

// Selector Imports
import { isAuthenticated, signout } from '../auth/functions'

// API Imports
import { useTranslation } from 'react-i18next'
import { getBriefcase, getBriefcaseFromP2p, getWListUsers } from '../services/APICalls'

import HelperModal from './components/HelperModal/HelperModal'

// TODO: Explanation
const BriefcaseDataTable = ({ source, userInput, briefcase, setBriefcase }) => {
  let history = useHistory()
  console.log('INSIDE userInput: ', userInput)
  const { t } = useTranslation()
  // Init state

  const [selectedBriefcase, setSelectedBriefcase] = useState('')
  const [filterText, setFilterText] = useState('')
  const [filteredItems, setFilteredItems] = useState('')
  const [dataTableError, setDataTableError] = useState(false)
  const [dataTableSuccess, setDataTableSuccess] = useState(false)
  const [myWlist, setMyWlist] = useState(false)
  const [userSelected, setUserSelected] = useState(userInput)
  const [sourceDataTable, setSourceDataTable] = useState(source)

  const { user, token } = isAuthenticated()
  const goToPageOrders = cripto_id => {
    history.push(`/user/orders/${cripto_id}`)
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  // Initial columns configuration
  const columns = [
    {
      name: ' ',
      cell: row => (
        <div>
          <img
            style={{ width: '35px', height: '35px' }}
            src={window.location.origin + '/images/currencies/' + row.cripto_id + '.png'}
            alt="loading"
          />
          {console.log('ROW:', row)}
        </div>
      ),
    },
    {
      name: t('briefCase.initials'),
      selector: 'acronym',
    },
    {
      name: t('briefCase.name'),
      selector: 'name',
      sortable: true,
    },
    {
      name: t('briefCase.amount'), //cell: row => <div>{row.amount.toFixed(8)}</div>
      //selector: 'amount',
      headerStyle: { textAlign: 'right' },
      cell: row => <div>{row.amount.toFixed(8)}</div>,
      sortable: true,
    },
    {
      name: t('briefCase.usd'), //cell: row => <div>{row.amount.toFixed(8)}</div>
      //selector: 'amount',
      headerStyle: { textAlign: 'right' },
      cell: row => <div>{row?.in_usd}</div>,
      sortable: true,
    },
    {
      name: t('briefCase.actions'),
      cell: row => actionsBtns(row),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getWListUsers(user.id).then(productData => {
      if (productData.error) {
        //setError(productData.error);
      } else {
        setMyWlist(productData)
      }
    })
  }, [])

  useEffect(() => {
    if (briefcase) {
      const items = briefcase.filter(item => item.name && item.name.toUpperCase().includes(filterText.toUpperCase()))
      setFilteredItems(items)
    }
  }, [filterText, briefcase])

  useEffect(() => {
    if (userInput) {
      loadBriefcase(userInput)
    } else {
      setUserSelected(user.id)
    }
  }, [user.id, userInput])

  // END React Effects ---------------------------------------------------------------------------

  // Methods -------------------------------------------------------------------------------------

  const loadBriefcase = userInput => {
    if (sourceDataTable == '2') {
      getBriefcase(userInput, token).then(data => {
        console.log('DATAPO)RTAFOLIO:', data)
        if (data?.status >= 200 && data?.status <= 299) {
          const newData = data?.data?.map(el => ({ ...el, in_usd: (el?.amount * el?.price).toFixed(8) }))
          setBriefcase(newData)
          //setBriefcase(data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }


        }
      })
    }
    if (sourceDataTable == '1') {
      getBriefcaseFromP2p(userInput, token).then(data => {
        console.log('DATAPO)RTAFOLIO:', data)
        if (data?.status >= 200 && data?.status <= 299) {
          const newData = data?.data?.map(el => ({ ...el, in_usd: (el?.amount * el?.price).toFixed(8) }))
          setBriefcase(newData)
          //setBriefcase(data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }


        }
      })
    }
  }

  // END Methods ---------------------------------------------------------------------------------

  // Events --------------------------------------------------------------------------------------

  // END Events ----------------------------------------------------------------------------------

  // Render Methods ------------------------------------------------------------------------------

  const actionsBtns = row => (
    <>
      {sourceDataTable == '2' &&
        <button
          type="button"
          title="Ver Ordenes"
          className="btn btn-default btn-sm btn-icon"
          aria-label="remove"
          onClick={() => goToPageOrders(row.cripto_id)}>
          <span className="glyphicon glyphicon glyphicon-list" aria-hidden="true"></span>
        </button>
      }

&nbsp;
      <HelperModal
        pictureName={'manager'}
        texBeforeButton={''}
        title={'View Options Column'}
        description={'You can see the details of transactions and movements for each type of Cryptocurrency'}
        iconFigureBootstrap={'glyphicon glyphicon glyphicon-question-sign'}
        variant={'text'}
      />
    </>
  )

  const modalDeleteConfirm = () => {
    return (
      <div className="modal fade" id="deleteModal" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title" id="myModalLabel">
                Confirmar Borrado
              </h4>
            </div>
            <div className="modal-body">
              {selectedBriefcase && `Desea eliminar la wallet ${selectedBriefcase.name}?`}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">
                Cancelar
              </button>
              <button type="button" className="btn btn-dark" data-dismiss="modal">
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalQrView = () => {
    return (
      <div className="modal fade" id="qrModal" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                Dirección de Depósito de mi Wallet{' '}
                {selectedBriefcase.name ? ` - Código QR  ${selectedBriefcase.name}` : ''}
              </h4>
            </div>
            <div className="modal-body modal-body-center">
              <QRCode
                title={selectedBriefcase.name ? `Código QR de  ${selectedBriefcase.name}` : ''}
                value={selectedBriefcase.wallet ? selectedBriefcase.wallet : ''}
                bgColor={'white'}
                fgColor={'black'}
                size={150}
              />
            </div>
            <div className="modal-body modal-body-center">
              Direccion: {selectedBriefcase.wallet ? selectedBriefcase.wallet : ''}
            </div>
            {selectedBriefcase.cripto_id == 7 && selectedBriefcase.memo.length > 2 && (
              <div className="modal-body modal-body-center">
                <QRCode
                  title={'Código QR de MEMO:'}
                  value={selectedBriefcase.memo ? selectedBriefcase.memo : ''}
                  bgColor={'white'}
                  fgColor={'black'}
                  size={150}
                />
              </div>
            )}
            {selectedBriefcase.cripto_id == 7 && selectedBriefcase.memo.length > 2 && (
              <div className="modal-body modal-body-center">
                Memo: {selectedBriefcase.memo ? selectedBriefcase.memo : ''}
              </div>
            )}
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalWList = () => {
    return (
      <div className="modal fade" id="wlModal" role="dialog" aria-labelledby="myModalLabel">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title modal-body-center" id="myModalLabel">
                {t('briefCase.trustedPickupAddresses')}
              </h4>
            </div>
            <div className="modal-body modal-body-center">
              {myWlist && (
                <div className="row">
                  {myWlist &&
                    myWlist.length > 0 &&
                    myWlist.map((oneWlist, i) => (
                      <div className="form-group col-xs-12 col-sm-12 col-lg-12">
                        <div className="form-group col-xs-12 col-sm-10 col-lg-9">{`${oneWlist.address}`}</div>
                        <div className="form-group col-xs-12 col-sm2 col-lg-3">{`${oneWlist.currency_acronym}`}</div>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="modal-body modal-body-center"></div>
            <div className="modal-footer">
              <button type="button" className="btn btn-default" data-dismiss="modal">
                {t('briefCase.closButtonTrustedPickupAddresses')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const modalAgregarWList = () => {
    return <StepperWList {...selectedBriefcase} />
  }

  const FilterComponent = filterText => {
    let history = useHistory()
    return (
      <div className="container ptb--0">
        {sourceDataTable == '2' &&
          <div className="row">
            <div className="col-md-8 col-lg-8">
              <div className="ckeckout-left-sidebar">
                <div className="row">
                  <div className="checkout-form">
                    <div className="pasadena-checkout-form-inner">
                      <div className="pasadena-single-box">
                        <div className="form-row">
                          <div className="form-group col-xs-9 col-sm-9 col-lg-9">
                            <div className="">
                              <div className="input-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t('briefCase.placeHolderSearch')}
                                  aria-describedby="sizing-addon2"
                                  value={filterText}
                                  onChange={e => {
                                    const newValue = e.target.value.replace(/[^a-zA-Z0-9\u00C0-\u017F]/g, '')
                                    e.target.value = newValue
                                    setFilterText(newValue)
                                  }}
                                  style={{ borderRadius: '0' }}
                                />
                                <span className="input-group-addon" id="sizing-addon2" style={{ borderRadius: '0' }}>
                                  {t('briefCase.searchButton')}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="form-group col-xs-3 col-sm-3 col-lg-3">
                            <button
                              type="button"
                              className="btn btn-default"
                              data-dismiss="modal"
                              onClick={() => history.push('/user/white_list')}>
                              <span className="glyphicon glyphicon-check" aria-hidden="true" /> {t('briefCase.trustedAddresses')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  // END Render Methods --------------------------------------------------------------------------

  // Main Render
  return (
    <Fragment>
      {modalDeleteConfirm()}
      {modalQrView()}
      {modalWList()}
      {modalAgregarWList()}

      <div className="row">
        <Alert
          error={dataTableError}
          success={dataTableSuccess}
          onError={value => {
            setDataTableError(value)
          }}
          onSuccess={value => {
            setDataTableSuccess(value)
          }}
        />
      </div>
      <DataTable
        columns={columns}
        data={filteredItems}
        defaultSortField="name"
        pagination
        highlightOnHover
        pointerOnHover
        subHeader
        subHeaderComponent={[FilterComponent()]}
      />
    </Fragment>
  )
}

export default BriefcaseDataTable
