import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import { useForm } from 'react-hook-form'

// Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyString, validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Icons Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

//Login Imports
import { signout } from '../../auth/functions'

//API Imports
import { getNotification, createNotification, updateNotification, getTypeNotifications } from '../../services/APICalls'

const initValues = {
  id: '',
  name: '',
  description: '',
  type_notification: '',
  active: '',
}

const FormNotification = () => {
  // Initial State
  const dispatch = useDispatch()
  const history = useHistory()
  //parametros que vienen por la url
  const paramsNotificationForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  const [type_notifications, setTypeNotifications] = useState([])
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getDetailsNotification = async () => {
    dispatch(showSpinner('Obteniendo Información de la Notificación'))
    getNotification(paramsNotificationForm?.idNotification)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          const newNotification = {
            id: data.data[0].id_notifications,
            name: data.data[0].name_notifications,
            description: data.data[0].description_notitications,
            type_notification: data.data[0].type_notifications,
            active: data.data[0].active_notifications,
          }
          setDefaultValues(newNotification)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const getTypeNotificationsList = async () => {
    dispatch(showSpinner('Obteniendo Información'))
    getTypeNotifications()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setTypeNotifications(data.data)
        } else {
          if (data?.status == 401) {
            //goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const onSubmitForm = notification => {
    const updaterNotification = {
      id: notification.id,
      name: notification.name,
      description: notification.description,
      type_notification: notification.type_notification,
      active: notification.active,
    }
    if (paramsNotificationForm?.idNotification !== undefined) {
      SendUpdateNotification(updaterNotification)
      goToNotificationList()
      return
    }
    sendCreateNotification(updaterNotification)
    goToNotificationList()
    return
  }

  const SendUpdateNotification = async notification => {
    try {
      await updateNotification(notification.id, notification)
      return
    } catch (error) {
      console.error(error)
    }
  }

  const sendCreateNotification = async notification => {
    try {
      await createNotification(notification)
    } catch (error) {
      console.error(error)
    }
  }

  const goToNotificationList = () => {
    history.push('/admin/notification')
  }

  useEffect(() => {
    getTypeNotificationsList()
    //en caso de que exista un id, se pone en modo editar
    if (paramsNotificationForm?.idNotification !== undefined) {
      getDetailsNotification()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsNotificationForm?.idNotification !== undefined ? 'EDICION' : 'REGISTRO'} DE NOTIFICACIÓN`}
      description={`${
        paramsNotificationForm?.idNotification !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de notificación`}
      showBreadcrumb={true}
      currentPage={`${paramsNotificationForm?.idNotification !== undefined ? 'EDICION' : 'REGISTRO'} DE NOTIFICACIÓN`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToNotificationList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyString(),
                    message: 'Solo se permiten letras',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('type_notification')?.toString()}
                label="Tipo"
                name="type_notification"
                options={type_notifications}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      type_notification: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo se permiten caracteres válidos',
                  },
                })}
                label="Descripción"
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormNotification
