import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'
import SelectForm from '../../components/form/SelectForm'

import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'

// Custom Component Imports
import Layout from '../../core/Layout'
import { validateOnlyString, validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

//API Imports
import { getTax, createTax, updateTax, getCountries } from '../../services/APICalls'

//Valores Iniciales
const initValues = {
  tax_description: '',
  id_country: '',
  name: '',
  id: '',
  rate: '',
  status: '',
  id_type: '',
  country_name: '',
}

const FormTimeTimeouts = () => {
  // Initial state
  const dispatch = useDispatch()
  const history = useHistory()
  //params que vienen por la url
  const paramsTaxForm = useParams({})

  //valores por defecto reciben valores iniciales
  const [defaultValues, setDefaultValues] = useState(initValues)
  //estado para los paises
  const [countries, setCountries] = useState([])

  //manejo de formulario
  const {
    handleSubmit, //manejador de evento sintetico de submit
    register, // registro de campos y validaciones
    reset, // reseteo de funciones iniciales
    watch, // observa el valor actual de un campo especifico
    getValues, // obtiene el valor de uno o todos los campos
    formState: { errors }, // estado del formulario y desestructuro los errores
  } = useForm(defaultValues) // inicializacion del formulario con los valores por defecto

  //funcion que va a traer los detalles
  const getDetailsTax = async () => {
    dispatch(showSpinner('Obteniendo Información del Impuesto/Comisión'))
    getTax(paramsTaxForm?.idTax).then(data => {
      if (data?.status >= 200 && data?.status <= 299) {
        reset(data.data[0])
      } else {
        if (data?.status == 401) {
          goToLogin()
          return
        }
        if (data?.status >= 400 || data?.status <= 599) {
          //TODO
          //useEffect(() => {
          //  changeToastInfo({ msg: data.message, severity: 'info' })
          //}, [])
        }
      }
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(hiddeSpinner())
    })
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getCountriesList = async () => {
    dispatch(showSpinner('Obteniendo Información'))
    getCountries().then(data => {
      if (data?.status >= 200 && data?.status <= 299) {
        setCountries(data.data)
      } else {
        if (data?.status == 401) {
          //goToLogin()
          return
        }
        if (data?.status >= 400 || data?.status <= 599) {
          //TODO
          //useEffect(() => {
          //  changeToastInfo({ msg: data.message, severity: 'info' })
          //}, [])
        }
      }
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(hiddeSpinner())
    })
  }

  const onSubmitForm = tax => {
    // verifico si los parametros en la url tienen algun valor
    if (paramsTaxForm?.idTax !== undefined) {
      // actualizo con lo que recibe del formulario
      SendUpdateTax(tax)
      // redirijo a la lista de taxes
      goToTaxList()
      return
    }
    //envio a crear un tax nuevo al backend
    sendCreateTax(tax)
    //redirijo a la lista de taxes
    goToTaxList()
    return
  }

  const SendUpdateTax = async tax => {
    try {
      //envio el tax a backend para actualizar
      await updateTax(tax)
      return
    } catch (error) {
      console.error(error)
    }
  }

  const sendCreateTax = async tax => {
    try {
      //envio el tax a backend para crear
      await createTax(tax)
    } catch (error) {
      console.error(error)
    }
  }

  const goToTaxList = () => {
    // redireccion a lista de taxes
    history.push('/admin/commission_taxes')
  }

  useEffect(() => {
    getCountriesList()
    //en caso de que exista un id, se pone en modo editar
    if (paramsTaxForm?.idTax !== undefined) {
      getDetailsTax()
    }
  }, [])

  function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props
    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          })
        }}
      />
    )
  }

  NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  return (
    <Layout
      title={`${paramsTaxForm?.idTax !== undefined ? 'EDICION' : 'REGISTRO'} DE TIMEOUT - ELAPSED`}
      description={`${
        paramsTaxForm?.idTax !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de impuesto o comisión`}
      showBreadcrumb={true}
      currentPage={`${paramsTaxForm?.idTax !== undefined ? 'EDICION' : 'REGISTRO'} DE TIMEOUT - ELAPSED`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToTaxList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('id_type')?.toString()}
                label="Tipo"
                name="id_type"
                options={[
                  { key: 'P2P', value: 2 },
                  { key: 'EXCHANGE', value: 1 },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      type: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyString(),
                    message: 'Solo se permiten letras',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('id_country')?.toString()}
                label="Pais"
                name="id_country"
                options={countries}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      id_country: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('rate') !== ''}
                {...register('rate', { required: 'Este campo es requerido' })}
                label="Tiempo"
                type="number"
                variant="outlined"
                helperText={errors?.rate?.message}
                error={errors?.rate?.message?.length > 0}
                value={getValues('rate')?.toString()}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('status')?.toString()}
                label="Estatus"
                name="status"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo se permiten caracteres válidos',
                  },
                })}
                label="Descripción"
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormTimeTimeouts 