import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: {
        titleIndex: 'Sign up now',
        auth: {
          title: 'Login to the system',
          email: 'Email',
          password: 'Password',
          login:'Login',
          pleaseAcceptTheCaptcha: 'Please accept the captcha',
          youDoNotHaveAnAccount: 'You do not have an account?',
          signUp: 'Sign up',

        },
        sideBar: {
          markets: 'Markets',
          cryptocurrencies: 'Cryptocurrencies',
          MyPortfolio: 'My Portfolio',
          convert: 'Convert',
          buy: 'Buy',
          send: 'Send',
          receive: 'Receive',
          userProfile: 'User Profile',
          logout: 'Log Out',
        },
        userProfile: {
          title: 'My Portfolio',
          alertCheckEmail: 'Check your email and follow the instructions we sent you to confirm your account.',
          uploadDocumentFailed: `We couldn't upload the document`,

          uploadSelfieFailed: `We couldn't upload your photo`,
          matchFailed: `"The images provided don't seem to be yours, please try uploading them again`,
          matchSuccessfully: 'We have successfully validated your face.',
          firstSectionTitle: 'Basic data',
          profileImage: 'Profile Image',
          dni: 'Identification',
          form: {
            nameInput: {
              label: 'Names',
              patternMessage: 'Minimum of 2 characters and only letters',
              required: 'This is required',
            },
            lastNameInput: {
              label: 'Last name',
              patternMessage: 'Minimum of 2 characters and only letters',
              required: 'This is required',
            },
            dateInput: {
              label: 'Date of birth',
            },
            genderInput: {
              label: 'gender',
              required: 'This is required',
            },
            emailInput: {
              label: 'Email',
              patternMessage: 'The email address is not valid.',
              required: 'This is required',
            },
            phoneNumber: {
              label: 'Phone',
            },
            addressTitle: 'Address',
            countryInput: {
              label: 'country',
              required: 'This is required',
            },
            stateInput: {
              label: 'State or province',
              required: 'This is required',
            },
            cityInput: {
              label: 'City',
              required: 'This is required',
            },
            postalCodeInput: {
              label: 'Código Postal',
              patternMessage: 'Must be at least 2 alphanumeric characters.',
              required: 'This is required',
            },
            addressInput: {
              label: 'Dirección',
              patternMessage: 'Minimum of 2 characters and only letters',
              required: 'This is required',
            },
            saveButton: 'Save',
            signOutButton: 'Logout',
          },

          // The email address is not valid.
        },
        markets: {
          totalCryptocurrencies: 'Total cryptocurrencies',
          totalExchanges: 'Total Exchanges',
          totalMarketCap: 'total Market Cap.',
          totalVolume: 'Total volume 24 hours',
          totalMarkets: 'Total markets',
        },
        cryptocurrencies: {
          title: 'Cryptocurrencies',
          price: 'Price',
          marketCap: 'Market Cap',
          dailyChange: 'Daily Change',
          seeMoreInfo: 'See more info',
          detail: {
            title: 'View more information',
            name: 'Name',
            position: 'Position',
            symbol: 'Symbol',
            price: 'Price',
          },
        },
        directConversion: {
          title: 'Direct Conversion',
          requestProcessedSuccessfully: 'Convert Successfully',
          close: 'Close',
          modalFinalSuccess: {
            result: 'Operation result',
            requestProcessedSuccessfully: 'Request Processed Successfully',
            close: 'Close',
          },
          errorMessages: {
            insufficientFunds: 'La cantidad a convertir sobrepasa la disponibilidad de fondos EF001',
            transactionNotAvailable: 'Transaccion no disponible temporalmente EF002',
            selectCryptocurrency:
              'You must select the source cryptocurrency, the amount to convert, and the destination cryptocurrency',
          },
          inactiveNotification: {
            title: 'Important Notice',
            msg: 'Inactive User due to KYC, you will be contacted by our staff to confirm your information',
          },
          modalAdvertisement: {
            title: 'CONFIRM AND PAY',
            msg1: 'You are going to proceed with a cryptocurrency conversion.',
            msg2: 'By accepting this transaction, you agree with',
            msg3: 'Fees',
            msg4: 'and the',
            msg5: ' Anti-Money Laundering policies of the CoinMax platform.',
            msg6: ` You have `,
            msg7: ' seconds to "accept`" this transaction.',
            accept: 'Accept',
            cancel: 'Cancel',
          },
          userAccount: {
            title: 'Conversion Options',
            subtitle: 'User Account',
            emailPlaceHolder: 'E-mail',
            accountBalance: 'Account balance',
            amountToConvert: 'Amount in',
            amountToBuy: 'Amount in',
            crypto: 'CRYPTO',
            select: 'Select',
            origin: 'Origin',
            quantity: 'Amount',
            numericMessageFalse: 'Amount to buy',
            numericMessageTrue: 'USD to buy',
            convertTo: 'Convert to',
            toBuy: 'amount to pay',
            receive: 'Receive',
            convert: 'Convert',
          },
        },
        briefCase: {
          title: 'My Portfolio',
          userAccount: 'User Account',
          placeHolderSearch: 'Name',
          searchButton: 'Search',
          trustedAddresses: 'Trusted Addresses',
          initials: 'Initials',
          name: 'Name',
          amount: 'Amount',
          usd: 'USD',
          actions: 'Actions',
          titleGraphic: 'TOTAL USD FOR CRYPTOCURRENCIES',
          trustedPickupAddresses: 'Trusted Pickup Addresses',
          closButtonTrustedPickupAddresses: 'Close',
        },
        receiveCrypto: {
          title: 'Receive CryptoCurrencies',
          userAccount: 'User Account',
          crypto: 'Crypto',
          address: 'Address',
          actions: 'Actions',
          qrCode: 'scan a QR code',
          copyToClipboard: 'Copy to clipboard',
        },
        footer: {
          contactUs: 'Contact us',
          categories: 'Categories',
          marketValues: 'Market Values',
          exchange: 'Exchange',
          remittances: 'Remittances',
          ATMs: 'ATMs',
          pos: 'Point of Sale',
          agencies: 'Agencies',
          information: 'Information',
          contact: 'Contact to EEUU',
          termsAndConditions: 'Terms and Conditions',
          shippingAndDispatchOfCards: 'Shipping and Dispatch of Cards',
          privacyPolicies: 'Privacy Policies',
          allRightsReserved: 'All Rights Reserved',
          home: 'Home',
        },
        buyCriptoCurrencies: {
          title: 'Buy cryptocurrencies',
          modalFinalSuccess: {
            result: 'Operation result',
            requestProcessedSuccessfully: 'Request Processed Successfully',
            close: 'Close',
          },
          errorMessages: {
            amountLessThanOne: 'The purchase amount equivalent in USD must be greater than 1',
            insufficientFunds: 'Temporarily the operation cannot be performed, please try again later. E001F',
            processPayment: 'Error processing payment in gateway',
            conexionGateway: 'Error initiating connection to gateway',
          },
          inactiveNotification: {
            title: 'Important Notice',
            msg: 'Inactive User due to KYC, you will be contacted by our staff to confirm your information',
          },
          modalAdvertisement: {
            title: 'CONFIRM AND PAY',
            msg1: 'You are going to proceed with a cryptocurrency conversion.',
            msg2: 'By accepting this transaction, you agree with',
            msg3: 'Fees',
            msg4: 'and the',
            msg5: ' Anti-Money Laundering policies of the CoinMax platform.',
            msg6: ` You have `,
            msg7: ' seconds to "accept`" this transaction.',
            accept: 'Accept',
            cancel: 'Cancel',
          },
          userAccount: {
            title: 'Conversion Options',
            subtitle: 'User Account',
            emailPlaceHolder: 'E-mail',
            accountBalance: 'Account balance',
            amountToConvert: 'Amount in',
            amountToBuy: 'Amount in',
            crypto: 'CRYPTO',
            select: 'Select',
            origin: 'Origin',
            quantity: 'Amount',
            numericMessageFalse: 'Amount to convert',
            numericMessageTrue: 'USD to convert',
            convertTo: 'Convert to',
            toBuy: 'to Pay',
            receive: 'Receive',
            convert: 'Convert',
            paymentDetails: 'Payment Details',
            confirmAndPay: 'Confirm and Pay',
          },
        },
        sendCriptoCurrencies: {
          title: 'send cryptocurrencies',
          quantityToSend:'Quantity to send',
          fromAndToWhereToSend:'From and to where to send',
          toThirdParties:'To third parties',
          modalFinalSuccess: {
            result: 'Operation result',
            requestProcessedSuccessfully: 'Request Processed Successfully',
            close: 'Close',
          },
          errorMessages: {
            amountLessThanOne: 'The purchase amount equivalent in USD must be greater than 1',
            insufficientFunds: 'Temporarily the operation cannot be performed, please try again later. E001F',
            processPayment: 'Error processing payment in gateway',
            conexionGateway: 'Error initiating connection to gateway',
          },
          inactiveNotification: {
            title: 'Important Notice',
            msg: 'Inactive User due to KYC, you will be contacted by our staff to confirm your information',
          },
          error: {
            selectCrypto: 'Error: You must first select the type of cryptocurrency.', //'Error: Primero Debes Seleccionar el Tipo de Criptomoneda'
            amountRequired: 'The amount to send cannot be greater than the balance', //La cantidad a enviar no puede ser mayor al saldo
            requiredInputs: 'You must select the source cryptocurrency, the amount to send, and the withdrawal address', //Debe Seleccionar CriptoMoneda Origen, Cantidad a Enviar y Dirección de  Retiro,
            selectCripto: 'Error: You must first select the type of cryptocurrency', //Error: Primero Debes Seleccionar el Tipo de Criptomoneda
            tagError: 'Error: Please enter a value in the "Tag" field', //Error: Colocar Valor en el Campo "Tag"
          },
          modalAdvertisement: {
            title: 'Important Notice',
            msg1: 'You are going to proceed with a cryptocurrency send.',
            msg2: 'By accepting this transaction, you agree with',
            msg3: 'Fees',
            msg4: 'and the',
            msg5: ' Anti-Money Laundering policies of the CoinMax platform.',
            msg6: ` You have `,
            msg7: ' seconds to "accept`" this transaction.',
            accept: 'Accept',
            cancel: 'Cancel',
          },
          trustedAddresses: {
            title: 'Trusted Addresses',
            close: 'Close',
          },
          userAccount: {
            title: 'Conversion Options',
            subtitle: 'User Account',
            emailPlaceHolder: 'E-mail',
            accountBalance: 'Account balance',
            amountToConvert: 'Amount in ',
            crypto: 'CRYPTO',
            select: 'Select',
            origin: 'Origin',
            quantity: 'Amount',
            addressDestiny: 'Destiny Address',
            addressDestinyPlaceHolder: 'Beneficiary address',
            numericMessageTrue: 'USD to send',
            read: 'Read',
            directory: 'Directory',
            quickSend: 'Quick Send', //envio rapido,
            add: 'Add', // agregar
            processOrder: 'Process order', //Procesar orden
          },
          withdrawalDestiny: {
            title: 'Add withdrawal address', //  Agregar Dirección de Retiro
            AddToDirectory: 'Add to directory', //Afiliar al Directorio
            isValid: 'Is valid', //Es válida,
            codeMessage: 'Enter the code we sent to you', // Ingrese el código que enviamos a
            invalidCode: 'Invalid/Expired Code', //Codigo Invalido/Expirado
            isInvalid: 'Is Invalid', //Es inválida,
            alreadyAffiliated: 'Already Affiliated!', //Ya está Afiliada!
            closeButton: 'Close', //
          },
          address: {
            add: {},
            qrView: {
              title: 'Withdrawal Address Information',
              inputName: 'Alias or Email',
              enterCode: 'Enter the code sent to',
              invalidCode: 'Invalid/Expired Code',
              invalidAddress: 'It is invalid',
              scanning: 'Scanning',
              close: 'Close',
              readWithdrawalAddress: 'Read Withdrawal Address',
              aliasOrEmail: 'Alias or Email',
              isValid: 'Is valid',
            },
            modalUserInactiveView: {
              title: 'Important Notice',
              msg: 'Inactive user due to KYC. You will be contacted by our staff to confirm your information',
            },
            modalAddAddress: {
              title: 'Add Withdrawal Address',
              button: 'Add to Directory',
              isValid: 'Is valid',
              enterCode: 'Enter the code sent to',
              invalidCode: 'Invalid/Expired Code',
              alreadyAffiliated: 'Already Affiliated!',
              itIsVerified: 'It is verified!',
              close: 'Close',
            },
          },
        },
        modalsTermsAndConditions: {
          acceptComissions: 'Yes, I accept the fees.',
          denyComission: 'No, I do not accept the fees',
          acceptPolitics: 'Yes, I accept the anti-money laundering policy',
          denyPolitics: 'No, I do not accept the anti-money laundering policy',
        },
        administration: {
          title: 'Administration',
          description: 'System Management',
          items: {
            users: 'Users',
            profiles: 'Profiles',
            capabilities: 'Capabilities',
            countries: 'Countries',
            states: 'States',
            legalTerms: 'Legal Terms',
            warnings: 'Warnings',
            taxesFees: 'Taxes / Commissions',
            notifications: 'Notifications',
            blacklistsAddresses: 'Blacklists Addresses',
            modules: 'Modules',
            cryptocurrencies: 'Cryptocurrencies',
            typeEvents: 'Event Types',
            notificationsTypes: 'Notifications Types',
            technicalSupportTicket: 'Technical Support Ticket',
            technicalSupportIncidents: 'Technical Support Incidents',
            technicalSupportIncidentsCategory: 'Technical Support Incidents Category',
            technicalSupportSolutionArea: 'Technical Support Solution Area',
          },
        },
        users: {
          back: 'Back',
          add: 'Add',
          title: 'User Management',
          userList: 'List of users',
          firstName: 'First Name',
          lastName: 'Last Name',
          email: 'Email',
          group: 'Group',
          status: 'Status',
          actions: 'Actions',
          usersRegisterForm: {
            title: 'User and Profile Registration',
            description: 'User and Profile Registration Form',
          },
          userEditForm: {
            title: 'Edit Users and their Profiles',
            description: 'User and Profile Editing Form',
          },
          deleteModal: {
            title: 'Delete',
            text: 'Are you sure you want to delete',
            saveButton: 'Delete',
            cancelButton: 'Cancel',
          },
          form: {
            nameInput: {
              label: 'Name',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            emailInput: {
              label: 'Login',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            statusInput: {
              label: 'Status',
              required: 'This field is required',
            },
            groupNameInput: {
              label: 'Group Name',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            profilesUsersInput: {
              label: 'Profiles',
            },
            saveButton: 'Save',
          },
        },
        country: {
          back: 'Back',
          add: 'Add',
          title: 'Countries Management',
          subtitle: 'List of countries',
          searchButton: 'Search countries by name',
          usersRegisterForm: {
            title: 'User and Profile Registration',
            description: 'User and Profile Registration Form',
          },
          userEditForm: {
            title: 'Edit Users and their Profiles',
            description: 'User and Profile Editing Form',
          },
          table: {
            name: 'Name',
            status: 'Status',
            actions: 'Actions',
            activeStatus: 'Active',
            inactiveStatus: 'Inactive',
            editButton: 'Edit',
            deleteButton: 'Delete',
          },
          deleteModal: {
            title: 'Delete',
            text: 'Are you sure you want to delete',
            saveButton: 'Delete',
            cancelButton: 'Cancel',
          },
          form: {
            titleNew: 'Add a new Country',
            titleEdit: 'Edit a Country',
            nameInput: {
              label: 'Name',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            statusInput: {
              label: 'Status',
              required: 'This field is required',
            },
            saveButton: 'Save',
            cancelButton: 'Cancel',
          },
        },
        state: {
          back: 'Back',
          add: 'Add',
          title: 'Territorial Division Management',
          subtitle: 'List of Territorial Divisions',
          searchButton: 'Search territorial division by name',
          usersRegisterForm: {
            title: 'Territorial Division Registration',
            description: 'Territorial Division Registration Form',
          },
          userEditForm: {
            title: 'Territorial Division Editing',
            description: 'Territorial Division Editing Form',
          },
          table: {
            country: 'Country',
            name: 'Name',
            status: 'Status',
            actions: 'Actions',
            activeStatus: 'Active',
            inactiveStatus: 'Inactive',
            editButton: 'Edit',
            deleteButton: 'Delete',
          },
          deleteModal: {
            title: 'Delete',
            text: 'Are you sure you want to delete',
            saveButton: 'Delete',
            cancelButton: 'Cancel',
          },
          form: {
            titleNew: 'Add a new Territorial Division',
            titleEdit: 'Edit a Territorial Division',
            countryInput: {
              label: 'Country',
              placeHolderSearch: 'select a country',
              required: 'This field is required',
            },
            nameInput: {
              label: 'Name of Territorial Division',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            statusInput: {
              label: 'Status',
              required: 'This field is required',
            },
            saveButton: 'Save',
            cancelButton: 'Cancel',
          },
        },
        legalTerms: {
          back: 'Back',
          add: 'Add',
          title: 'Legal Terms Management',
          subtitle: 'List of Terms Management',
          searchButton: 'Search legal terms by name',
          usersRegisterForm: {
            title: 'Legal Terms Registration',
            description: 'Legal Terms Registration Form',
          },
          userEditForm: {
            title: 'Legal Terms Editing',
            description: 'Legal Terms Editing Form',
          },
          table: {
            name: 'Name',
            country: 'Country',
            event: 'Event',
            status: 'Status',
            description: 'Description',
            actions: 'Actions',
            activeStatus: 'Active',
            inactiveStatus: 'Inactive',
            showDescriptionButton: 'Show Description',
            editButton: 'Edit',
            deleteButton: 'Delete',
          },
          deleteModal: {
            title: 'Delete',
            text: 'Are you sure you want to delete',
            saveButton: 'Delete',
            cancelButton: 'Cancel',
          },
          form: {
            titleNew: 'Add a new Legal Terms',
            titleEdit: 'Edit a Legal Terms',
            countryInput: {
              label: 'Country',
              placeHolder: 'select a country',
              required: 'This field is required',
            },
            nameInput: {
              label: 'Name of Legal Terms',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            typeEventInput: {
              label: 'Type Event',
              placeHolder: 'select a event',
              required: 'This field is required',
            },
            descriptionInput: {
              placeHolder: 'Type your description',
              required: 'This field is required',
            },
            statusInput: {
              label: 'Status',
              required: 'This field is required',
            },
            saveButton: 'Save',
            cancelButton: 'Cancel',
          },
          modalDetail:{
            title: 'Details of',
            closeButton:'Close'
          }
        },
        warnings: {
          back: 'Back',
          add: 'Add',
          title: 'Warning Management',
          subtitle: 'List of warnings',
          searchButton: 'Search warning by name',
          warningsRegisterForm: {
            title: 'Warning Registration',
            description: 'warning Registration Form',
          },
          warningsEditForm: {
            title: 'Warning Editing',
            description: 'Warning Editing Form',
          },
          table: {
            name: 'Name',
            status: 'Status',
            module: 'Module',
            moduleDescription: 'Module Description',
            description: 'Description',
            actions: 'Actions',
            activeStatus: 'Active',
            inactiveStatus: 'Inactive',
            showDescriptionButton: 'Show Description',
            editButton: 'Edit',
            deleteButton: 'Delete',
          },
          deleteModal: {
            title: 'Delete',
            text: 'Are you sure you want to delete',
            saveButton: 'Delete',
            cancelButton: 'Cancel',
          },
          form: { 
            nameInput: {
              label: 'Name',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            moduleInput: {
              label: 'Module',
              placeHolder: 'select a module',
              required: 'This field is required',
            },
            descriptionInput: {
              label: 'Description',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            statusInput: {
              label: 'Status',
              required: 'This field is required',
            },
            saveButton: 'Save',
            cancelButton: 'Cancel',
          },
          modalDetail:{
            title: 'Details of',
            closeButton:'Close'
          }
        },
        capability: {
          back: 'Back',
          add: 'Add',
          title: 'Capability Management',
          subtitle: 'List of Capability',
          searchButton: 'Search capabilities by name',
          capabilityRegisterForm: {
            title: 'capability Registration',
            description: 'capability Registration Form',
          },
          capabilityEditForm: {
            title: 'Edit capabilities',
            description: 'capability Editing Form',
          },
          table: {
            description: 'Description',
            status: 'Status',
            actions: 'Actions',
            activeStatus: 'Active',
            inactiveStatus: 'Inactive',
            editButton: 'Edit',
            deleteButton: 'Delete',
          },
          deleteModal: {
            title: 'Delete',
            text: 'Are you sure you want to delete',
            saveButton: 'Delete',
            cancelButton: 'Cancel',
          },
          form: {
            titleNew: 'Add a new Country',
            titleEdit: 'Edit a Country',
            descriptionInput: {
              label: 'Description',
              patternMessage: 'You must enter at least 2 letters or numbers',
              required: 'This field is required',
            },
            statusInput: {
              label: 'Status',
              required: 'This field is required',
            },
            saveButton: 'Save',
            cancelButton: 'Cancel',
          },
        },
        taxes: {
          back: 'Back',
          add: 'Add',
          title: 'Warning Management',
          subtitle: 'List of warnings',
          searchButton: 'Search warning by name',
          warningsRegisterForm: {
            title: 'Warning Registration',
            description: 'warning Registration Form',
          },
          warningsEditForm: {
            title: 'Warning Editing',
            description: 'Warning Editing Form',
          },
          table: {
            type:'Type',
            name: 'Name',
            description: 'Description',
            rate:'Rate',
            country: 'Country',
            status: 'Status',
            actions: 'Actions',
            activeStatus: 'Active',
            inactiveStatus: 'Inactive',
            editButton: 'Edit',
            deleteButton: 'Delete',
          },
          deleteModal: {
            title: 'Delete',
            text: 'Are you sure you want to delete',
            saveButton: 'Delete',
            cancelButton: 'Cancel',
          },
          form: { 
            nameInput: {
              label: 'Name',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            moduleInput: {
              label: 'Module',
              placeHolder: 'select a module',
              required: 'This field is required',
            },
            descriptionInput: {
              label: 'Description',
              patternMessage: 'Only allows valid characters',
              required: 'This field is required',
            },
            statusInput: {
              label: 'Status',
              required: 'This field is required',
            },
            saveButton: 'Save',
            cancelButton: 'Cancel',
          },
          modalDetail:{
            title: 'Details of',
            closeButton:'Close'
          }
        },
        profilesAdministration: {
          back: 'Back',
          add: 'Add',
          title: 'Profile Management',
          subtitle: 'List of Profiles',
          table: {
            name: 'Name',
            description: 'Description',
            status: 'Status',
            actions: 'Actions',
            activeStatus: 'Active',
            inactiveStatus: 'Inactive',
          },
        },

        orders: {
          title: 'Orders',
          placeHolderSearch: 'Name',
          searchButton: 'Search',
          orderTable: {
            nOrder: '#Order',
            description: 'Description',
            status: 'Status',
            statusTrue: 'Pagado',
            statusFalse: 'Por Pagar',
            amount: 'Amount',
            fees: 'Fees',
            date: 'Date',
            actions: 'Actions',
          },
        },
        whiteList: {
          title: 'List of Trusted Addresses',
          description: 'List of Trusted Addresses',
          subtitle: 'Trusted Addresses',
          whiteListTable: {
            address: 'Address',
            currency: 'Currency',
            copy: 'Copy',
            active: 'Active',
            activeOption: 'Active',
            inactiveOption: 'Inactive',
          },
        },
        transactions: {
          title: 'Transactions',
          nOrder: 'Order No',
          placeHolderSearch: 'Name',
          searchButton: 'Search',
          transactionTable: {
            transaction: '#Trns',
            tx: 'Tx||Vout',
            description: 'Description',
            address: 'Address',
            amount: 'Amount',
            date: 'Date',
            actions: 'Actions',
          },
        },
        byeMsg: 'see you later!',
      },
    },








































    es: {
      translation: {
        titleIndex: 'Registrese ahora',
         auth: {
          title: 'Ingreso',
          email: 'Correo electrónico',
          password: 'Clave',
          login:'Iniciar Sesión',
          pleaseAcceptTheCaptcha: 'Por favor acepta el captcha',
          youDoNotHaveAnAccount: '¿No tienes cuenta? ',
          signUp: 'Regístrate',
        },
        sideBar: {
          markets: 'Mercados',
          cryptocurrencies: 'Criptomonedas',
          MyPortfolio: 'Mi Portafolio',
          convert: 'Convertir',
          buy: 'Comprar',
          send: 'Enviar',
          receive: 'Recibir',
          userProfile: 'Perfil de Usuario',
          logout: 'Cerrar sesión',
        },
        userProfile: {
          title: 'Mi Portafolio',
          alertCheckEmail: 'Revisa tu correo y sigas las instrucciones que le enviamos para confirmar su cuenta',
          uploadDocumentFailed: 'No logramos cargar el documento',
          uploadSelfieSuccessfully: 'Se ha cargado tu foto efectivamente',
          uploadSelfieFailed: 'No logramos cargar tu foto',
          matchFailed: 'Las imágenes suministradas no parecen ser tuyas, por favor vuelve a intentar subirlas',
          matchSuccessfully: 'Hemos validado tu rostro exitosamente',
          firstSectionTitle: 'Datos básicos',
          profileImage: 'Imagen de perfil',
          dni: 'Identificación',
          form: {
            nameInput: {
              label: 'Nombres',
              patternMessage: 'Mínimo 2 caracteres y solo letras',
              required: 'Este campo es requerido',
            },
            lastNameInput: {
              label: 'Apellidos',
              patternMessage: 'Mínimo 2 caracteres y solo letras',
              required: 'Este campo es requerido',
            },
            dateInput: {
              label: 'Fecha de Nacimiento',
            },
            genderInput: {
              label: 'Sexo',
              required: 'Este campo es requerido',
            },
            emailInput: {
              label: 'Correo electrónico',
              patternMessage: 'El correo electrónico no es válido',
              required: 'Este campo es requerido',
            },
            phoneNumber: {
              label: 'Teléfono',
            },
            addressTitle: 'Dirección',
            countryInput: {
              label: 'País',
              required: 'Este campo es requerido',
            },
            stateInput: {
              label: 'Estado o provincia',
              required: 'Este campo es requerido',
            },
            cityInput: {
              label: 'Ciudad',
              required: 'Este campo es requerido',
              patternMessage: 'Mínimo 2 caracteres y solo letras',
            },
            postalCodeInput: {
              label: 'Código Postal',
              patternMessage: 'debe ser mínimo 2 caracteres alfanuméricos',
              required: 'Este campo es requerido',
            },
            addressInput: {
              label: 'Dirección',
              patternMessage: 'Mínimo 2 caracteres y solo letras',
              required: 'Este campo es requerido',
            },
            saveButton: 'Guardar',
            signOutButton: 'Cerrar Sesión',
          },
        },
        markets: {
          totalCryptocurrencies: 'Total CriptoMonedas',
          totalExchanges: 'Total Exchanges',
          totalMarketCap: 'Total Cap. Mercado',
          totalVolume: 'Total Volumen 24h',
          totalMarkets: 'Mercados Totales',
        },
        cryptocurrencies: {
          title: 'criptomonedas',
          price: 'Pecio',
          marketCap: 'Cap. de Merfcado',
          dailyChange: 'Variación diaria',
          seeMoreInfo: 'Ver más info',
          detail: {
            title: 'Ver mas info',
            name: 'Nombre',
            position: 'Posición',
            symbol: 'Símbolo',
            price: 'Precio',
          },
        },
        directConversion: {
          title: 'Conversión Directa',
          requestProcessedSuccessfully : 'Conversión Exitosa',
          close: 'Cerrar',
          modalFinalSuccess: {
            result: 'RESULTADO DE LA OPERACIÓN',
            requestProcessedSuccessfully: 'Solicitud Procesada Exitosamente',
            close: 'Cerrar',
          },
          errorMessages: {
            insufficientFunds: 'The amount to be converted exceeds the fund availability. EF001',
            transactionNotAvailable: 'Transaction not available temporarily EF002',
            selectCryptocurrency: 'Debe Seleccionar CriptoMoneda Origen, Cantidad a Convertir y CriptoModena Destino',
          },
          inactiveNotification: {
            title: 'Aviso Importante',
            msg: 'Usuario Inactivo por KYC, revise las instrucciones enviadas asu correo electronico',
          },
          modalAdvertisement: {
            title: 'CONFIRMAR Y PAGAR',
            msg1: 'Usted va a proceder a realizar una conversión entre criptomonedas.',
            msg2: 'Al aceptar esta transacción, usted está de acuerdo con las',
            msg3: 'Comisiones',
            msg4: 'y las',
            msg5: 'Políticas Antilavado de la plataforma CoinMax.',
            msg6: ` Tiene `,
            msg7: ' segundos para "Aceptar" esta transacción.',
            accept: 'Aceptar',
            cancel: 'Cancelar',
          },
          userAccount: {
            title: 'Opciones de Conversión',
            subtitle: 'Cuenta de Usuario',
            emailPlaceHolder: 'Correo electrónico',
            accountBalance: 'Saldo en Cuenta',
            amountToConvert: 'Monto en',
            crypto: 'CRIPTO',
            select: 'Seleccione',
            origin: 'Origen',
            quantity: 'Cantidad',
            numericMessageFalse: 'cantidad a convertir',
            numericMessageTrue: 'USD a convertir',
            convertTo: 'Convertir a',
            toBuy: 'a pagar',
            receive: 'Recibe',
            convert: 'Convertir',
          },
        },
        briefCase: {
          title: 'Mi portafolio',
          userAccount: 'Cuenta de Usuario',
          placeHolderSearch: 'Nombre',
          searchButton: 'Buscar',
          trustedAddresses: 'Direcciones de confianza',
          initials: 'sigla',
          name: 'Nombre',
          amount: 'Monto',
          usd: 'USD',
          actions: 'Acciones',
          titleGraphic: 'TOTAL USD POR CRIPTOMONEDAS',
          trustedPickupAddresses: 'Direcciones de Retiro de Confianza',
          closButtonTrustedPickupAddresses: 'Cerrar',
        },
        receiveCrypto: {
          title: 'Recibir CriptoMonedas',
          userAccount: 'Cuenta de Usuario',
          crypto: 'Cripto',
          address: 'Dirección',
          actions: 'Acciones',
          qrCode: 'Ver QR',
          copyToClipboard: 'Copiar al portapapeles',
        },
        footer: {
          contactUs: 'Contáctanos',
          categories: 'Categorías',
          marketValues: 'Valores de Mercado',
          exchange: 'Intercambios',
          remittances: 'Remesas',
          ATMs: 'Telecajeros',
          pos: 'Punto de Venta',
          agencies: 'Agencias',
          information: 'Información',
          contact: 'Contacto en EEUU',
          termsAndConditions: 'Términos y Condiciones',
          shippingAndDispatchOfCards: 'Envío y Despacho de Tarjetas',
          privacyPolicies: 'Políticas de Privacidad',
          allRightsReserved: 'Todos los Derechos Reservados',
          home: 'Inicio',
        },
        buyCriptoCurrencies: {
          title: 'Comprar criptomonedas',
          modalFinalSuccess: {
            result: 'RESULTADO DE LA OPERACIÓN',
            requestProcessedSuccessfully: 'Solicitud Procesada Exitosamente',
            close: 'Cerrar',
          },
          errorMessages: {
            amountLessThanOne: 'El monto de la compra equivalente en USD debe ser mayor a 1',
            insufficientFunds: 'Temporalmente no se puede realizar la operación, intente mas tarde nuevamente E001F',
            processPayment: 'Error al procesar pago en pasarela',
            conexionGateway: 'Error iniciando conexión a pasarela',
          },
          inactiveNotification: {
            title: 'Aviso Importante',
            msg: 'Usuario Inactivo por KYC, revise las instrucciones enviadas asu correo electronico',
          },
          modalAdvertisement: {
            title: 'CONFIRMAR Y PAGAR',
            msg1: 'Usted va a proceder a realizar una conversión entre criptomonedas.',
            msg2: 'Al aceptar esta transacción, usted está de acuerdo con las',
            msg3: 'Comisiones',
            msg4: 'y las',
            msg5: 'Políticas Antilavado de la plataforma CoinMax.',
            msg6: ` Tiene `,
            msg7: ' segundos para "Aceptar" esta transacción.',
            accept: 'Aceptar',
            cancel: 'Cancelar',
          },
          userAccount: {
            title: 'Opciones de Conversión',
            subtitle: 'Cuenta de Usuario',
            emailPlaceHolder: 'Correo electrónico',
            accountBalance: 'Saldo en Cuenta',
            amountToConvert: 'Monto en',
            amountToBuy: 'Monto en',
            crypto: 'CRIPTO',
            select: 'Seleccione',
            origin: 'Origen',
            quantity: 'Cantidad',
            numericMessageFalse: 'cantidad a convertir',
            numericMessageTrue: 'USD a convertir',
            convertTo: 'Convertir a',
            toBuy: 'a pagar',
            receive: 'Recibe',
            convert: 'Convertir',
            paymentDetails: 'Detalles del pago',
            confirmAndPay: 'Confirmar y Pagar',
          },
        },
        sendCriptoCurrencies: {
          title: 'enviar criptomonedas',
          quantityToSend:'Cantidad a enviar',
          fromAndToWhereToSend:'¿Desde y hacia dónde enviar',
          toThirdParties:'A terceros',
          modalFinalSuccess: {
            result: 'Resultado de la operación',
            requestProcessedSuccessfully: 'Solicitud procesada correctamente',
            close: 'Cerrar',
          },
          errorMessages: {
            amountLessThanOne: 'El monto de compra equivalente en USD debe ser mayor a 1',
            insufficientFunds:
              'Temporalmente la operación no puede ser realizada, por favor intenta de nuevo más tarde. E001F',
            processPayment: 'Error procesando el pago en la pasarela',
            conexionGateway: 'Error iniciando conexión a la pasarela',
          },
          inactiveNotification: {
            title: 'Aviso importante',
            msg: 'Usuario inactivo debido a KYC, nuestro equipo se pondrá en contacto contigo para confirmar tu información',
          },
          error: {
            selectCrypto: 'Error: Primero debes seleccionar el tipo de criptomoneda.',
            amountRequired: 'La cantidad a enviar no puede ser mayor al saldo',
            requiredInputs: 'Debes seleccionar criptomoneda origen, cantidad a enviar y dirección de retiro',
            selectCripto: 'Error: Primero debes seleccionar el tipo de criptomoneda',
            tagError: 'Error: Colocar valor en el campo "Tag"',
          },
          modalAdvertisement: {
            title: 'Aviso importante',
            msg1: 'Vas a proceder con el envío de una criptomoneda.',
            msg2: 'Al aceptar esta transacción, estás de acuerdo con',
            msg3: 'Tarifas',
            msg4: 'y las políticas',
            msg5: 'contra el lavado de dinero de la plataforma CoinMax.',
            msg6: 'Tienes',
            msg7: ' segundos para "aceptar" esta transacción.',
            accept: 'Aceptar',
            cancel: 'Cancelar',
          },
          trustedAddresses: { 
            title: 'Direcciones confiables',
            close: 'Cerrar' 
          },
          userAccount: {
            title: 'Opciones de conversión',
            subtitle: 'Cuenta de usuario',
            emailPlaceHolder: 'Correo electrónico',
            accountBalance: 'Saldo de la cuenta',
            amountToConvert: 'Monto en',
            crypto: 'CRYPTO',
            select: 'Seleccionar',
            origin: 'Origen',
            quantity: 'Cantidad',
            addressDestiny: 'Dirección de destino',
            addressDestinyPlaceHolder: 'Dirección del beneficiario',
            numericMessageTrue: 'USD a enviar',
            read: 'Leer',
            directory: 'Directorio',
            quickSend: 'Envío rápido',
            add: 'Agregar',
            processOrder: 'Procesar orden',
          },
          withdrawalDestiny: {
            title: 'Agregar dirección de retiro',
            AddToDirectory: 'Agregar al directorio',
            isValid: 'Es válida',
            codeMessage: 'Ingresa el código que te enviamos',
            invalidCode: 'Código inválido/expirado',
            isInvalid: 'Es inválida',
            alreadyAffiliated: '¡Ya está afiliada!',
            closeButton: 'Cerrar',
          },
          address: {
            add: {},
            qrView: {
              title: 'Datos Dirección de Retiro',
              inputName: 'Alias o Email',
              enterCode: 'Ingrese el código enviado a ',
              invalidCode: 'Código Inválido/Expirado',
              invalidAddress: 'Es Inválida',
              scanning: 'Escaneando',
              close: 'Cerrar',
              readWithdrawalAddress: 'Leer Dirección de Retiro',
              isValid: 'Es Válida',
            },
            modalUserInactiveView: {
              title: 'Aviso Importante',
              msg: 'Usuario Inactivo por KYC, revise las instrucciones enviadas asu correo electronico',
            },
            modalAddAddress: {
              title: 'Agregar Dirección de Retiro',
              button: ' Afiliar al Directorio',
              isValid: 'Es Válida',
              enterCode: 'Ingrese el código enviado a ',
              invalidCode: 'Código Inválido/Expirado',
              alreadyAffiliated: 'Ya está Afiliada!',
              itIsVerified: 'Está Verificada!',
              close: 'Cerrar',
            },
          },
        },
        modalsTermsAndConditions: {
          acceptComissions: 'SI, Acepto las comisiones',
          denyComission: 'NO, Acepto las comisiones',
          acceptPolitics: 'SI, Acepto la política de antilavado',
          denyPolitics: 'NO, Acepto la política de antilavado',
        },
        administration: {
          title: 'Administración',
          description: 'Gestión del Sistema',
          items: {
            users: 'Usuarios',
            profiles: 'Perfiles',
            capabilities: 'Capacidades',
            countries: 'Países',
            states: 'Estados',
            legalTerms: 'Términos Legales',
            warnings: 'Advertencias',
            taxesFees: 'Taxes / Commissions',
            notifications: 'Notificaciones',
            blacklistsAddresses: 'Listas Negras Direcciones',
            modules: 'Módulos',
            cryptocurrencies: 'Cryptocurrencies',
            typeEvents: 'Tipos de Eventos',
            notificationsTypes: 'Tipo de Notificaciones',
            technicalSupportTicket: 'Ticket de Soporte Técnico',
            technicalSupportIncidents: 'Incidentes de Soporte Técnico',
            technicalSupportIncidentsCategory: 'Categoría de Incidentes de Soporte Técnico',
            technicalSupportSolutionArea: 'Área Resolutoria de Soporte Técnico',
          },
        },
        users: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de Usuarios',
          userList: 'Lista de Usuarios',
          firstName: 'Nombre',
          lastName: 'Apellido',
          email: 'Correo electrónico',
          group: 'Grupo',
          status: 'Estatus',
          actions: 'Acciones',
          usersRegisterForm: {
            title: 'Registro de Usuarios y sus Perfiles',
            description: 'Formulario de Registro de usuarios y sus perfiles',
          },
          userEditForm: {
            title: 'Edición de Usuarios y sus Perfiles',
            description: 'Formulario de Edición de usuarios y sus perfiles',
          },
          deleteModal: {
            title: 'Eliminar',
            text: '¿Está seguro que desea eliminar',
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          form: {
            nameInput: {
              label: 'Nombre',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            emailInput: {
              label: 'Login',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            statusInput: {
              label: 'Estatus',
              required: 'Este campo es requerido',
            },
            groupNameInput: {
              label: 'Nombre de Grupo',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            profilesUsersInput: {
              label: 'Perfiles',
            },
            saveButton: 'Guardar',
          },
        },
        country: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de Países',
          subtitle: 'Lista de Países',
          searchButton: 'Buscar países por nombre',
          usersRegisterForm: {
            title: 'Registro de Países y sus Perfiles',
            description: 'Formulario de Registro de países y sus perfiles',
          },
          userEditForm: {
            title: 'Edición de países y sus Perfiles',
            description: 'Formulario de Edición de países y sus perfiles',
          },
          table: {
            name: 'Nombre',
            description: 'Descripción',
            status: 'Status',
            actions: 'Acciones',
            activeStatus: 'Activo',
            inactiveStatus: 'Inactivo',
            editButton: 'Editar',
            deleteButton: 'Eliminar',
          },
          deleteModal: {
            title: 'Eliminar',
            text: '¿Está seguro que desea eliminar',
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          form: {
            titleNew: 'Agregar un nuevo País',
            titleEdit: 'Editar un País',
            nameInput: {
              label: 'Nombre de País',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            status: {
              label: 'Estatus',
              required: 'Este campo es requerido',
            },
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
        },
        state: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de División territorial',
          subtitle: 'Lista de División territorial',
          searchButton: 'Buscar división territorial por nombre',
          usersRegisterForm: {
            title: 'Registro de División territorial',
            description: 'Formulario de Registro de división territorial',
          },
          userEditForm: {
            title: 'Edición de división territorial',
            description: 'Formulario de Edición de división territorial',
          },
          table: {
            country: 'País',
            name: 'Nombre',
            status: 'Status',
            actions: 'Acciones',
            activeStatus: 'Activo',
            inactiveStatus: 'Inactivo',
            editButton: 'Editar',
            deleteButton: 'Eliminar',
          },
          deleteModal: {
            title: 'Eliminar',
            text: '¿Está seguro que desea eliminar',
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          form: {
            titleNew: 'Agregar una nueva división territorial',
            titleEdit: 'Editar una división territorial',
            countryInput: {
              label: 'Nombre de País',
              placeHolderSearch: 'Selecciona un país',
              required: 'Este campo es requerido',
            },
            nameInput: {
              label: 'Nombre de la división territorial',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            statusInput: {
              label: 'Estatus',
              required: 'Este campo es requerido',
            },
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
        },
        legalTerms: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de términos legales',
          subtitle: 'Lista de términos legales',
          searchButton: 'Buscar términos legales por nombre',
          usersRegisterForm: {
            title: 'Registro de términos legales',
            description: 'Formulario de Registro de términos legales',
          },
          userEditForm: {
            title: 'Edición de términos legales',
            description: 'Formulario de Edición de términos legales',
          },
          table: {
            name: 'Nombre',
            country: 'País',
            event: 'Evento',
            status: 'Status',
            description: 'Descripción',
            actions: 'Acciones',
            activeStatus: 'Activo',
            inactiveStatus: 'Inactivo',
            showDescriptionButton: 'Ver descripción',
            editButton: 'Editar',
            deleteButton: 'Eliminar',
          },
          form: {
            titleNew: 'Agregar una nueva tégrminos legales',
            titleEdit: 'Editar una tégrminos legales',
            countryInput: {
              label: 'Nombre de País',
              placeHolderSearch: 'Selecciona un país',
              required: 'Este campo es requerido',
            },
            nameInput: {
              label: 'Nombre del término legal',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            typeEventInput: {
              label: 'Tipo de Evento',
              placeHolder: 'Selecciona un evento',
              required: 'Este campo es requerido',
            },
            descriptionInput: {
              placeHolder: 'Tú descripción va aquí!',
            },
            statusInput: {
              label: 'Estatus',
              required: 'Este campo es requerido',
            },
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          deleteModal: {
            title: 'Eliminar',
            text: '¿Está seguro que desea eliminar',
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          modalDetail:{
            title: 'Detalles de',
            closeButton:'Cerrar'
          }
        },
        warnings: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de Advertencias',
          subtitle: 'Lista de Advertencias',
          searchButton: 'Buscar advertencias por nombre',
          warningsRegisterForm: {
            title: 'Registro de advertencias',
            description: 'Formulario de Registro de advertencias',
          },
          warningsEditForm: {
            title: 'Edición de advertencias',
            description: 'Formulario de Edición de advertencias',
          },
          table: {
            name: 'Nombre',
            module: 'Módulo',
            moduleDescription: 'Desc del Módulo',
            status: 'Status',
            description: 'Descripción',
            actions: 'Acciones',
            activeStatus: 'Activo',
            inactiveStatus: 'Inactivo',
            showDescriptionButton: 'Ver descripción',
            editButton: 'Editar',
            deleteButton: 'Eliminar',
          },
          form: {
            titleNew: 'Agregar una nueva advertencias',
            titleEdit: 'Editar una advertencias',
            nameInput: {
              label: 'Nombre',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            moduleInput: {
              label: 'Modulo',
              placeHolder: 'Selecciona un modulo',
              required: 'Este campo es requerido',
            },
            descriptionInput: {
              label: 'Descripción',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            statusInput: {
              label: 'Estatus',
              required: 'Este campo es requerido',
            },
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          deleteModal: {
            title: 'Eliminar',
            text: '¿Está seguro que desea eliminar',
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          modalDetail:{
            title: 'Detalles de',
            closeButton:'Cerrar'
          }
        },
        capability: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de Capacidades',
          subtitle: 'Lista de Capacidades',
          searchButton: 'Buscar capacidades por nombre',
          capabilityRegisterForm: {
            title: 'Registro de capacidades',
            description: 'Formulario de Registro de capacidades',
          },
          capabilityEditForm: {
            title: 'Edición de capacidades ',
            description: 'Formulario de Edición de capacidades',
          },
          table: {
            description: 'Descripción',
            status: 'Status',
            actions: 'Acciones',
            activeStatus: 'Activo',
            inactiveStatus: 'Inactivo',
            editButton: 'Editar',
            deleteButton: 'Eliminar',
          },
          deleteModal: {
            title: 'Eliminar',
            text: '¿Está seguro que desea eliminar',
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          form: {
            titleNew: 'Agregar un nuevo País',
            titleEdit: 'Editar un País',
            descriptionInput: {
              label: 'Descripción',
              patternMessage: 'Debes escribir al menos 2 letras o números',
              required: 'Este campo es requerido',
            },
            statusInput: {
              label: 'Estatus',
              required: 'Este campo es requerido',
            },
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
        },
        taxes: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de Impuestos y Comisiones',
          subtitle: 'Lista de Impuestos y Comisiones',
          searchButton: 'Buscar Impuestos y Comisiones por nombre',
          warningsRegisterForm: {
            title: 'Registro de Impuestos y Comisiones',
            description: 'Formulario de Registro de Impuestos y Comisiones',
          },
          warningsEditForm: {
            title: 'Edición de Impuestos y Comisiones',
            description: 'Formulario de Edición de Impuestos y Comisiones',
          },
          table: {
            type:'Tipo',
            name: 'Nombre',
            description: 'Descripción',
            rate:'Tasa',
            status: 'Estatus',
            country: 'País',
            actions: 'Acciones',
            activeStatus: 'Activo',
            inactiveStatus: 'Inactivo',
            editButton: 'Editar',
            deleteButton: 'Eliminar',
          },
          form: {
            titleNew: 'Agregar una nueva advertencias',
            titleEdit: 'Editar una advertencias',
            nameInput: {
              label: 'Nombre',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            moduleInput: {
              label: 'Modulo',
              placeHolder: 'Selecciona un modulo',
              required: 'Este campo es requerido',
            },
            descriptionInput: {
              label: 'Descripción',
              patternMessage: 'Solo permite caracteres válidos',
              required: 'Este campo es requerido',
            },
            statusInput: {
              label: 'Estatus',
              required: 'Este campo es requerido',
            },
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          deleteModal: {
            title: 'Eliminar',
            text: '¿Está seguro que desea eliminar',
            saveButton: 'Guardar',
            cancelButton: 'Cancelar',
          },
          modalDetail:{
            title: 'Detalles de',
            closeButton:'Cerrar'
          }
        },
        profilesAdministration: {
          back: 'Atrás',
          add: 'Agregar',
          title: 'Gestión de Perfiles',
          subtitle: 'Lista de Perfiles',
          table: {
            name: 'Nombre',
            status: 'Status',
            actions: 'Acciones',
            activeStatus: 'Activo',
            inactiveStatus: 'Inactivo',
          },
        },
        orders: {
          title: 'ORDENES',
          placeHolderSearch: 'Nombre',
          searchButton: 'Buscar',
          orderTable: {
            nOrder: '#Orden',
            description: 'Descripción',
            status: 'Status',
            amount: 'Monto',
            statusTrue: 'Paid',
            statusFalse: 'Unpaid',
            fees: 'Fees',
            date: 'Fecha',
            actions: 'Acciones',
          },
        },
        whiteList: {
          title: 'Lista de Direcciones de confianza',
          description: 'Lista de Direcciones de Confianza',
          subtitle: 'Direcciones de confianza',
          whiteListTable: {
            address: 'Address',
            currency: 'Moneda',
            copy: 'Copiar',
            active: 'Activo',
            activeOption: 'Activo',
            inactiveOption: 'Inactivo',
          },
        },
        transactions: {
          title: 'Transacciones',
          nOrder: 'Orden No',
          placeHolderSearch: 'Nombre',
          searchButton: 'Buscar',
          transactionTable: {
            transaction: '#Trns',
            tx: 'Tx||Vout',
            description: 'Description',
            address: 'Dirección',
            amount: 'Monto',
            date: 'Fecha',
            actions: 'Acciones',
          },
        },
        byeMsg: 'Hasta luego!',
      },
    },
  },
})

export default i18n
