// API Imports
import { uploadFile, uploadSelfie } from "./APICalls";

const upload = (file, id) => {
  let formData = new FormData();

  formData.append("file", file);

  return uploadFile(formData, id).then(data => {
    if (data.error) {
      console.log('Hubo error');
      console.log(data.error);
    }
    else {
      console.log('Subida exitosa', data)
      return data
    }
  });

};


const uploadShot = (data, id) => {

  return uploadSelfie(data, id).then(data => {
    if (data.error) {
      console.log('Hubo error');
      console.log(data.error);
    }
    else {
      console.log('Subida Selfie exitosa', data)
      return data
    }
  });
}


/*
const getFiles = () => {
  return http.get("/files");
};
*/
export default {
  upload,
  uploadShot
  //getFiles,
};
