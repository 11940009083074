import React from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material'
// @material-ui/icons
import Menu from '@material-ui/icons/Menu'
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js'
import { useLocation } from 'react-router-dom'
import { isAuthenticated } from '../../auth/functions.js'

import { grayColor } from '../../assets/jss/material-dashboard-react.js'

export default function Header({ color, routes = [], handleDrawerToggle = () => {}, rtlActive }) {
  const location = useLocation()
  const { token } = isAuthenticated()
  function makeBrand() {
    const findRoute = routes.find(({ layout, path }) => location?.pathname === `${layout}${path}`)
    const layoutName = rtlActive ? findRoute?.rtlName : findRoute?.name
    return layoutName
  }
  return (
    <AppBar
      sx={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderBottom: '0',
        marginBottom: '0',
        position: 'absolute',
        width: '100%',
        paddingTop: '10px',
        zIndex: '1029',
        color: color ?? grayColor[7],
        border: '0',
        borderRadius: '3px',
        padding: '10px 0',
        transition: 'all 150ms ease 0s',
        minHeight: '50px',
        display: 'block',
      }}>
      <Toolbar sx={{ paddingX: '1rem', marginX: 'auto', minHeight: '50px' }}>
        <Box flex={1}>
          {/* Here we create navbar brand, based on route name */}
          <Button
            sx={{
              fontWeight: '300',
              lineHeight: '1.5em',
              letterSpacing: 'unset',
              lineHeight: '30px',
              fontSize: '18px',
              borderRadius: '3px',
              textTransform: 'none',
              // color: 'inherit',
              cursor: 'default',
              margin: '0',
              '&:hover,&:focus': {
                background: 'transparent',
              },
            }}>
            {makeBrand()}
          </Button>
        </Box>
        {token !== undefined && token !== null && token !== '' && (
          <Box display={{ xs: 'none', md: 'block' }}>
            <AdminNavbarLinks />
          </Box>
        )}
        <Box display={{ xs: 'bloc', md: 'none' }}>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
}
