import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton, Tooltip } from '@mui/material'
import { useForm } from 'react-hook-form'

// Icons Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns, validateOnlyStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

// API Imports
import { getCaveat, createCaveat, updateCaveat, getModulesAPIList } from '../../services/APICalls'
import { useTranslation } from 'react-i18next'

const initValues = {
  id: '',
  id_module: '',
  name: '',
  description: '',
  module: '',
  module_description: '',
  type: '',
  status: '',
}

const FormCaveats = () => {
  // Initial State
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  //params que vienen por la url
  const paramsCaveatForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  const [modules, setModules] = useState([])
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const getDetailsCaveat = async () => {
    dispatch(showSpinner('Obteniendo Información de la Advertencia'))
    getCaveat(paramsCaveatForm?.idCaveat)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          const newCaveat = {
            id: data.data[0].id,
            id_module: data.data[0].id_module,
            name: data.data[0].name,
            description: data.data[0].description,
            active: data.data[0].active,
          }
          setDefaultValues(newCaveat)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getModulesList = () => {
    dispatch(showSpinner('Consultando Lista de Advertencias'))
    getModulesAPIList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setModules(data.data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const onSubmitForm = caveat => {
    const updaterCaveat = {
      id: caveat.id,
      id_module: caveat.id_module,
      name: caveat.name,
      description: caveat.description,
      active: caveat.active,
    }
    if (paramsCaveatForm?.idCaveat !== undefined) {
      SendUpdateCaveat(updaterCaveat)
      goToCaveatList()
      return
    }
    sendCreateCaveat(updaterCaveat)
    goToCaveatList()
    return
  }

  const SendUpdateCaveat = async caveat => {
    try {
      await updateCaveat(caveat.id, caveat)
      return
    } catch (error) {
      console.error(error)
    }
  }

  const sendCreateCaveat = async caveat => {
    try {
      await createCaveat(caveat)
    } catch (error) {
      console.error(error)
    }
  }

  const goToCaveatList = () => {
    history.push('/admin/caveat')
  }

  useEffect(() => {
    getModulesList()
    //en caso de que exista un id, se pone en modo editar
    if (paramsCaveatForm?.idCaveat !== undefined) {
      getDetailsCaveat()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={
        paramsCaveatForm?.idCaveat !== undefined
          ? t('warnings.warningsEditForm.title')
          : t('warnings.warningsRegisterForm.title')
      }
      description={
        paramsCaveatForm?.idCaveat !== undefined
          ? t('warnings.warningsEditForm.description')
          : t('warnings.warningsRegisterForm.description')
      }
      showBreadcrumb={true}
      currentPage={
        paramsCaveatForm?.idCaveat !== undefined
          ? t('warnings.warningsEditForm.title')
          : t('warnings.warningsRegisterForm.title')
      }>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Tooltip title={t('warnings.back')} arrow>
          <IconButton aria-label={t('warnings.back')} onClick={() => goToCaveatList()}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required:  t('warnings.form.nameInput.required'),
                  pattern: {
                    value: validateOnlyStringSigns(),
                    message: t('warnings.form.nameInput.patternMessage'),
                  },
                })}
                label={t('warnings.form.nameInput.label')}
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('id_module')?.toString()}
                label={t('warnings.form.moduleInput.label')}
                name="id_module"
                options={modules}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      id_module: target.value,
                    })),
                  required:  t('warnings.form.moduleInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label={t('warnings.form.statusInput.label')}
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: t('warnings.form.statusInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required: t('warnings.form.descriptionInput.required'),
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: t('warnings.form.descriptionInput.patternMessage')
                  },
                })}
                label={t('warnings.form.descriptionInput.label')}
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
              {t('warnings.form.saveButton')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormCaveats
