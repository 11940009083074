import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TableContainer from '@mui/material/TableContainer'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'

// Icons Imports
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// Custom Component Imports
import Layout from '../../core/Layout'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

// API Imports
import { getBListAPIList, deleteBList } from '../../services/APICalls'

const AdminBList = () => {
  // Initial State
  const dispatch = useDispatch()
  let history = useHistory()
  const [data, setData] = useState([])
  const [detailsListBlackWhite, setDetailsListBlackWhite] = useState({})
  const [open, setOpen] = React.useState(false)

  const handleOpenDeleteModal = bw => {
    setDetailsListBlackWhite(bw)
    setOpen(true)
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const columns = [
    { field: 'crypto_id', headerName: 'ID Cripto', width: 150, flex: 1, renderCell: params => <>{params.value}</> },
    { field: 'address', headerName: 'Dirección', width: 130, flex: 1, renderCell: params => <>{params.value}</> },
    {
      field: 'currency_acronym',
      headerName: 'Moneda',
      width: 130,
      flex: 1,
      renderCell: params => <>{params.value}</>,
    },
    {
      field: 'active',
      headerName: 'Activo',
      width: 130,
      flex: 1,
      renderCell: params => <>{params.value ? 'Activo' : 'Inactivo'}</>,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 130,
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title="Editar" arrow>
                <IconButton aria-label="Editar" onClick={() => goToPageListBlackWhiteDetails(params.row.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar" arrow onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton aria-label="Eliminar">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const openModalDeleteListBlackWhite = async detailsListBlackWhite => {
    const { id: idBList } = detailsListBlackWhite
    await deleteBList(idBList)
    handleCloseDeleteModal()
    getListBlackWhiteList()
  }

  //Obtener Lista Negra
  const getListBlackWhiteList = () => {
    dispatch(showSpinner('Consultando Lista Negra de Direcciones'))
    getBListAPIList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const goToPageListBlackWhiteDetails = idBList => {
    history.push(`/admin/blacklist/${idBList}`)
  }

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getListBlackWhiteList()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
      title="GESTIÓN DE LISTA NEGRA"
      description="Gestión de Lista Negra"
      showBreadcrumb={true}
      currentPage="GESTIÓN DE LISTA NEGRA">
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}></Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
              <Tooltip title="Volver" arrow>
                <IconButton aria-label="Volver" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">Lista Negra de Direcciones</Typography>
              <Tooltip title="Agregar" arrow>
                <IconButton
                  aria-label="add icon"
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/blacklist/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <TableContainer component={Paper}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={row => row.id}
                autoHeight
              />
            </TableContainer>
          </GridItem>
        </GridContainer>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Está seguro que desea eliminar? <br></br>
            {detailsListBlackWhite?.address}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => openModalDeleteListBlackWhite(detailsListBlackWhite)}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminBList
