import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Chip, Grid, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TableContainer from '@mui/material/TableContainer'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'

//Icons Imports
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

// API Impprts
import { getTimeouts, deleteTimeouts } from '../../services/APICalls.js'

// Custom Component Imports
import Layout from '../../core/Layout.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice.js'

//Login Imports
import { signout } from '../../auth/functions.js'
import { useTranslation } from 'react-i18next'

const TimeTimeouts = () => {
  //Initial States
  const dispatch = useDispatch()
  let history = useHistory()
  const { t } = useTranslation()
  //valores que va a tener la lista, se inicializa como un arreglo vacio
  const [data, setData] = useState([])
  //valores que va a tener el modal de eliminar
  const [detailsTax, setDetailsTax] = useState({})
  //estado para la modal de eliminar
  const [open, setOpen] = React.useState(false)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  //función para ejecutar el modal
  const handleOpenDeleteModal = tax => {
    //recibo el tax y seteo la información en el estado
    setDetailsTax(tax)
    //abro el modal
    setOpen(true)
  }

  //función para cerrar el modal
  const handleCloseDeleteModal = () => {
    //cierro el modal
    setOpen(false)
  }

  //función para lanzar la petición de eliminar, recibe el objeto tax y es asincrona (async)
  const openModalDeleteTax = async detailsTax => {
    //desestructuro el id y lo renombro taxId
    const { id: taxId } = detailsTax
    //como la función es asincrona uso await para esperar que la promesa de eliminar responda
    await deleteTimeouts(taxId)
    //cierro el modal
    handleCloseDeleteModal()
    // se llama la lista de taxes de nuevo
    getTimeoutList()
  }

  const getTimeoutList = () => {
    dispatch(showSpinner('Obteniendo Lista de Impuestos y Comisiones'))
    getTimeouts()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const columns = [
    { field: 'type_name', headerName: 'Type', width: 150, flex: 1, renderCell: params => <>{params.value}</> },
    { field: 'name', headerName:'Name', width: 130, flex: 1, renderCell: params => <>{params.value}</> },
    {
      field: 'description',
      headerName: 'Description',
      width: 130,
      flex: 1,
      renderCell: params => <>{params.value}</>,
    },
    { field: 'time', headerName: 'Time', width: 130, flex: 1, renderCell: params => <>{params.value}</> },
    { field: 'country_name', headerName: 'Country', width: 130, flex: 1, renderCell: params => <>{params.value}</> },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      flex: 1,
      renderCell: params => (
        <>
          {params?.value ? (
            <Chip label={t('state.table.activeStatus')} color="success" variant="outlined" />
          ) : (
            <Chip label={t('state.table.inactiveStatus')} color="error" variant="outlined" />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip  title={'Edit'} arrow>
                <IconButton  aria-label={'Edit'} onClick={() => goToPageTimeDetails(params.row.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete'} arrow onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton  aria-label={'Delete'}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const goToPageTimeDetails = idTax => {
    history.push(`/admin/time_timeouts/${idTax}`)
  }

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    //ejecuto la función
    getTimeoutList()
    //Se ejecuta una sola vez ya que en el arreglo no tiene parametros que revisar
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
        title={'Warning Management'}
        description={'Timeout Elapsed'}
        showBreadcrumb={true}
        currentPage={'Timeouts'}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}></Grid>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
            <Tooltip title={'Back'} arrow>
                <IconButton aria-label={'Back'} onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">{'Timeouts Elapsed'}</Typography>
              <Tooltip title={'New'} arrow>
                <IconButton
                  aria-label={'New'}
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/time_timeouts/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <TableContainer component={Paper}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={row => row.id}
                autoHeight
              />
            </TableContainer>
          </GridItem>
        </GridContainer>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{'Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          {'Are you sure you want to delete'}<br></br>
            {detailsTax?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>{'Cancel'}</Button>
          <Button variant="contained" onClick={() => openModalDeleteTax(detailsTax)}>
          {'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default TimeTimeouts
