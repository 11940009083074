import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const axios = require("axios");


const cryptoApiHeaders = {
  'x-rapidapi-host': 'coinranking1.p.rapidapi.com',
  'x-rapidapi-key': '6b33b819d1msh9a50c4eb1c8417bp170a51jsnb0302616c0c5',
};
const createRequest = (url) => ({ url, headers: cryptoApiHeaders });

export const cryptoApi = createApi({
  reducerPath: 'cryptoApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://coinranking1.p.rapidapi.com' }),
  endpoints: (builder) => ({

    getCryptoDetails: builder.query({
      query: (coinId) => createRequest(`/coin/${coinId}`),
    }),

    // Note: Change the coin price history endpoint from this - `coin/${coinId}/history/${timeperiod} to this - `coin/${coinId}/history?timeperiod=${timeperiod}`
    getCryptoHistory: builder.query({
      query: ({ coinId, timeperiod }) => createRequest(`coin/${coinId}/history?timeperiod=${timeperiod}`),
    }),

    // Note: To access this endpoint you need premium plan
    getExchanges: builder.query({
      query: () => createRequest('/exchanges'),
    }),


  }),
});

/*export const useGetCryptosQuery1 = (count) => {
  const options = {
    method: 'GET',
    url: 'https://coinranking1.p.rapidapi.com/coins',
    params: {
      limit: count
    },
    headers: {
      'X-RapidAPI-Key': '6b33b819d1msh9a50c4eb1c8417bp170a51jsnb0302616c0c5',
      'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com'
    }
  };

  axios.request(options).then(function (response) {
    console.log(response.data);
    return response
  }).catch(function (error) {
    console.error(error);
  });
};*/


export const useGetCryptosQuery = async (count) => {
console.log('VALOR DE COUNT:',count)
  await axios.get('https://coinranking1.p.rapidapi.com/coins?limit=10', {
    headers: {
      'X-RapidAPI-Key': '6b33b819d1msh9a50c4eb1c8417bp170a51jsnb0302616c0c5',
      'X-RapidAPI-Host': 'coinranking1.p.rapidapi.com'
    }
  })
    .then(function (response2) {
      console.log('RESPONSE DE AXIOS:', response2);
      return response2
      //response.status(201).json(resultado);
    });
 
}

/* 
var config = {
                responseType: 'json'
            }
            //return await axios.get('https://api.binance.com/api/v3/avgPrice?symbol=BTCUSDT', config)
            return await axios.get('https://api.coincap.io/v2/assets/bitcoin', config)
                .then(function (response) {
                    console.log('RESPONSE FROM API:', response);
                    var cotizacion_btc = Number(parseFloat((response.data.data.priceUsd)).toFixed(8));
                    console.log('cotizacion_btc', cotizacion_btc);
                    return cotizacion_btc;

                })
*/

export const {
  useGetCryptoDetailsQuery,
  useGetExchangesQuery,
  useGetCryptoHistoryQuery,
} = cryptoApi;