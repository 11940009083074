import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import SelectForm from "../../components/form/SelectForm";
import { Button, Box, Grid, IconButton } from "@mui/material";
import Layout from "../../core/Layout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";

import { getWList, createWList, getCriptoCurrencyAPIList } from "../../services/APICalls";

const initValues = {
  id: "",  
  address: "",
  crypto_id: "",
  active: "",
};

const FormWList = () => {
  const history = useHistory();
  //params que vienen por la url
  const paramsWListForm = useParams({});
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues);
  const [CriptoCurrency, setCriptoCurrency] = useState([]);
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues);

  const getDetailsWList = async () => {
    try {
      const { 0: white_list } = await getWList(paramsWListForm?.idWList);
      const newWList = {
        id: white_list.id,       
        address: white_list.address,
        crypto_id: white_list.crypto_id,
        active: white_list.active,
      };
      setDefaultValues(newWList);
    } catch (error) {
      console.log(error);
    }
  };

  const getCriptoCurrencyList = async () => {
    const response = await getCriptoCurrencyAPIList();
    if (Array.isArray(response)) {
      setCriptoCurrency(response);
    }
  };

  const onSubmitForm = (white_list) => {
    const updateWList = {
      id: white_list.id,     
      address: white_list.address,
      crypto_id: white_list.crypto_id,
      active: white_list.active,
    };
    sendCreateWList(updateWList);
    goToWListList();
    return;
  };

  const sendCreateWList = async (white_list) => {
    try {
      await createWList(white_list);
    } catch (error) {
      console.error(error);
    }
  };

  const goToWListList = () => {
    history.push("/user/white_list");
  };

  useEffect(() => {
    getCriptoCurrencyList();
    //en caso de que exista un id, se pone en modo editar
    if (paramsWListForm?.idWList !== undefined) {
      getDetailsWList();
    }
  }, []);

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <Layout
      title={`${
        paramsWListForm?.idWList !== undefined ? "EDICION" : "REGISTRO"
      } DE DIRECCIÓN EN LISTA DE CONFIANZA`}
      description={`${
        paramsWListForm?.idWList !== undefined
          ? "Formulario de Edición"
          : "Formulario de Registro"
      } de lista negra`}
      showBreadcrumb={true}
      currentPage={`${
        paramsWListForm?.idWList !== undefined ? "EDICION" : "REGISTRO"
      } DE DIRECCIÓN EN LISTA DE CONFIANZA`}
    >
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton title="Volver" aria-label="volver" onClick={() => goToWListList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
            <SelectForm
                defaultValue={getValues("crypto_id")?.toString()}
                label="CriptoMoneda"
                name="crypto_id"
                options={CriptoCurrency}
                optionsKey="acronym"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      crypto_id: target.value,
                    })),
                  required: "Este campo es requerido",
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues("active")?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: "Activo", value: true },
                  { key: "Inactivo", value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: "Este campo es requerido",
                }}
                watch={watch}
                error={errors}
              />
            </Grid>

            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch("address") !== ""}
                {...register("address")}
                label="Dirección"
                type="text"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  );
};

export default FormWList;
