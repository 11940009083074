import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Grid, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'

// Custom Component Imports
import Layout from '../../core/Layout'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

// API Impports
import { getTypeNotifications, deleteTypeNotification } from '../../services/APICalls'

const AdminTypeNotifications = () => {
  // Initial State
  const dispatch = useDispatch()
  let history = useHistory()
  const [data, setData] = useState([])
  const [detailsTypeNotifications, setDetailsTypeNotifications] = useState({})
  const [open, setOpen] = React.useState(false)

  const handleOpenDeleteModal = TypeNotifications => {
    setDetailsTypeNotifications(TypeNotifications)
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const openModalDeleteTypeNotifications = async detailsTypeNotifications => {
    const { id: IdTypeNotifications } = detailsTypeNotifications
    await deleteTypeNotification(IdTypeNotifications)
    handleCloseDeleteModal()
    getTypeNotificationsList()
  }

  const getTypeNotificationsList = () => {
    dispatch(showSpinner('Consultando Lista de Tipos de Notificaciones'))
    getTypeNotifications().then(response => {
      if (Array.isArray(response)) {
        setData(response)
      }
      dispatch(hiddeSpinner())
    })
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1,
      renderCell: params => <>{params.value}</>,
    },
    {
      field: 'active',
      headerName: 'Estatus',
      flex: 1,
      renderCell: params => <>{params.value ? 'Activo' : 'Inactivo'}</>,
    },

    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title="Editar" arrow>
                <IconButton aria-label="Editar" onClick={() => goToPageTypeNotificationsDetails(params?.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar" arrow onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton aria-label="Eliminar">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const goToPageTypeNotificationsDetails = idTypeNotifications => {
    history.push(`/admin/type_notifications/${idTypeNotifications}`)
  }

  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getTypeNotificationsList()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
      title="GESTIÓN DE TIPOS DE NOTIFICACIONES"
      description="Gestión de Tipos de Notificaciones"
      showBreadcrumb={true}
      currentPage="GESTIÓN DE TIPOS DE NOTIFICACIONES">
      {' '}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}></Grid>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
              <Tooltip title="Agregar" arrow>
                <IconButton aria-label="Volver" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">Lista de Tipos de Notificaciones</Typography>
              <Tooltip title="Agregar" arrow>
                <IconButton
                  aria-label="add icon"
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/type_notifications/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowId={row => row.id}
              autoHeight
            />
          </GridItem>
        </GridContainer>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Está seguro que desea eliminar? <br></br>
            {detailsTypeNotifications?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => openModalDeleteTypeNotifications(detailsTypeNotifications)}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminTypeNotifications