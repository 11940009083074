import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'

//Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

//API Imports
import { getTechnicalSupport, createTechnicalSupport, updateTechnicalSupport, getIncidentsCategoryList, getResolutionAreaAPIList, } from '../../services/APICalls'

const initValues = {
  id: '',
  name: '',
  description: '',
  active: '',
}

const FormTechnicalSupport = () => {
  // Initial State
  const dispatch = useDispatch()
  const history = useHistory()
  //params que vienen por la url
  const paramsTechnicalSupportForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  const [resolutionArea, setResolutionArea] = useState([])
  const [categoryIncident, setCategoryIncident] = useState([])
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getCategoryIncident = () => {
    dispatch(showSpinner('Consultando Información'))
    getIncidentsCategoryList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setCategoryIncident(data.data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const getResolutionArea = () => {
    dispatch(showSpinner('Consultando Información'))
    getResolutionAreaAPIList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setResolutionArea(data.data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }


  const getDetailsTechnicalSupport = async () => {
    dispatch(showSpinner('Obteniendo Información del Ticket de Soporte Técnico'))

    getTechnicalSupport(paramsTechnicalSupportForm?.idSupport)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setDefaultValues(data.data[0])
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const onSubmitForm = TechnicalSupport => {
    const updaterTechnicalSupport = {
      id: TechnicalSupport.id,
      name: TechnicalSupport.name,
      description: TechnicalSupport.description,
      active: TechnicalSupport.active,
    }
    if (paramsTechnicalSupportForm?.idSupport !== undefined) {
      SendUpdateTechnicalSupport(updaterTechnicalSupport)
      goToTechnicalSupportList()
      return
    }
    sendCreateTechnicalSupport(updaterTechnicalSupport)
    goToTechnicalSupportList()
    return
  }

  const SendUpdateTechnicalSupport = async TechnicalSupport => {
    dispatch(showSpinner('Modificando Información del módulo'))
    try {
      await updateTechnicalSupport(TechnicalSupport.id, TechnicalSupport)
      dispatch(hiddeSpinner())
      return
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const sendCreateTechnicalSupport = async TechnicalSupport => {
    dispatch(showSpinner('Registrando Información del módulo'))
    try {
      await createTechnicalSupport(TechnicalSupport)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToTechnicalSupportList = () => {
    history.push('/admin/technical_support')
  }

  useEffect(() => {
    getResolutionArea()
    getCategoryIncident()
    //en caso de que exista un id, se pone en modo editar
    if (paramsTechnicalSupportForm?.idSupport !== undefined) {
      getDetailsTechnicalSupport()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsTechnicalSupportForm?.idSupport !== undefined ? 'EDICION' : 'REGISTRO'} DE TICKET`}
      description={`${
        paramsTechnicalSupportForm?.idSupport !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de ticket`}
      showBreadcrumb={true}
      currentPage={`${paramsTechnicalSupportForm?.idSupport !== undefined ? 'EDICION' : 'REGISTRO'} DE TICKET`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToTechnicalSupportList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('id_category_incident')?.toString()}
                label="Categoría"
                name="id_category_incident"
                options={categoryIncident}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      id_category_incident: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('id_resolution_area')?.toString()}
                label="Área Resolutoria"
                name="id_resolution_area"
                options={resolutionArea}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      id_resolution_area: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Descripción"
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormTechnicalSupport