import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import ButtonGroup from '@mui/material/ButtonGroup'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import TableContainer from '@mui/material/TableContainer'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'
import SelectForm from '../../components/form/SelectForm'
import TextField from '@mui/material/TextField'
import { useForm } from 'react-hook-form'

//Icons Imports
import AddCircleIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import SearchIcon from '@mui/icons-material/Search'

// Custom Component Imports
import Layout from '../../core/Layout'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

// API Impports
import { getTechnicalSupportList, getIncidentsCategoryList, getResolutionAreaAPIList, deleteIncidentCategory } from '../../services/APICalls'

const AdminTechnicalSupport = () => {
  // Initial State
  const dispatch = useDispatch()
  let history = useHistory()
  const [data, setData] = useState([])  
  const [defaultValues, setDefaultValues] = useState()
  const [detailsTechnicalSupport, setDetailsTechnicalSupport] = useState({})
  const [resolutionArea, setResolutionArea] = useState([])
  const [categoryIncident, setCategoryIncident] = useState([])
  const [open, setOpen] = React.useState(false)

  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)
  
  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getCategoryIncident = () => {
    dispatch(showSpinner('Consultando Información'))
    getIncidentsCategoryList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setCategoryIncident(data.data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const getResolutionArea = () => {
    dispatch(showSpinner('Consultando Información'))
    getResolutionAreaAPIList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setResolutionArea(data.data)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const handleOpenDeleteModal = technical_support => {
    setDetailsTechnicalSupport(technical_support)
    setOpen(true)
  }

  const handleCloseDeleteModal = () => {
    setOpen(false)
  }

  const openModalDeleteTechnicalSupport = async detailsTechnicalSupport => {
    const { id: IdSupport } = detailsTechnicalSupport
    await deleteIncidentCategory(IdSupport)
    handleCloseDeleteModal()
    getTicketSupport()
  }

  //Obtener Lista de Soporte Tecnico
  const getTicketSupport = () => {
    dispatch(showSpinner('Obteniendo Lista de Tickets de Soporte Técnico'))
    getTechnicalSupportList()
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          setData(data.data)
        } else {
          if (data?.status == 401) {
            dispatch(hiddeSpinner())
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  

  const columns = [
    { field: 'name', headerName: 'Nombre', width: 130, flex: 1, renderCell: params => <>{params.value}</> },
    {
      field: 'description',
      headerName: 'Descripción',
      width: 130,
      flex: 2,
      renderCell: params => <>{params.value}</>,
    },
    {
      field: 'active',
      headerName: 'Estatus',
      width: 130,
      flex: 0.5,
      renderCell: params => <>{params.value ? 'Activo' : 'Inactivo'}</>,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 130,
      flex: 0.5,
      renderCell: params => (
        <>
          {
            <ButtonGroup>
              <Tooltip title="Editar" arrow>
                <IconButton aria-label="Editar" onClick={() => goToPageTechnicalSupportDetails(params.row.id)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar" arrow onClick={() => handleOpenDeleteModal(params.row)}>
                <IconButton aria-label="Eliminar">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ButtonGroup>
          }
        </>
      ),
    },
  ]

  const goToPageTechnicalSupportDetails = idSupport => {
    history.push(`/admin/technical_support/${idSupport}`)
  }

  const searchTechnicalSupportDetails = () => {
    console.log('Busqueda de datos')
  }
 
  // React Effects -------------------------------------------------------------------------------

  useEffect(() => {
    getTicketSupport()
    getResolutionArea()
    getCategoryIncident()
  }, [])

  // END React Effects ---------------------------------------------------------------------------

  return (
    <Layout
      title="GESTIÓN DE SOPORTE TÉCNICO"
      description="Gestión de Soporte Técnico"
      showBreadcrumb={true}
      currentPage="GESTIÓN DE SOPORTE TÉCNICO">
      {' '}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
              <Grid item xs={3} sm={3} lg={3} sx={{ mt: 1 }}>
              <SelectForm
                defaultValue={getValues('id_category_incident')?.toString()}
                label="Categoría"
                name="id_category_incident"
                options={categoryIncident}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      id_category_incident: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
              </Grid>
              <Grid item xs={3} sm={3} lg={3} sx={{ mt: 1 }}>
              <SelectForm
                defaultValue={getValues('id_resolution_area')?.toString()}
                label="Área Resolutoria"
                name="id_resolution_area"
                options={resolutionArea}
                optionsKey="name"
                optionsValue="id"
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      id_resolution_area: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
              </Grid>
              <Grid item xs={3} sm={3} lg={3} sx={{ mt: 1 }}>
                <TextField
                  fullWidth
                  label="N° de Ticket"
                  name="numero_ticket"
                  type="text"
                  variant="outlined"                 
                />
              </Grid>
              <Tooltip title="Buscar" arrow>
                <IconButton aria-label="Buscar" sx={{ mr: 2 }} color="primary"onClick={() => searchTechnicalSupportDetails()}>
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </GridItem>
        </GridContainer>
        <br></br>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={6}>
              <Tooltip title="Volver" arrow>
                <IconButton aria-label="Volver" onClick={() => history.push('/admin')}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h5">Lista de Tickets</Typography>
              <Tooltip title="Agregar" arrow>
                <IconButton
                  aria-label="add icon"
                  sx={{ mr: 2 }}
                  color="primary"
                  onClick={() => history.push('/admin/technical_support/new')}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </GridItem>
        </GridContainer>
        <br></br>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TableContainer component={Paper}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                getRowId={row => row.id}
                autoHeight
                // localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              />
            </TableContainer>
          </GridItem>
        </GridContainer>
      </Box>
      <Dialog
        open={open}
        keepMounted
        onClose={handleCloseDeleteModal}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Eliminar</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            ¿Está seguro que desea eliminar? <br></br>
            {detailsTechnicalSupport?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => openModalDeleteTechnicalSupport(detailsTechnicalSupport)}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  )
}

export default AdminTechnicalSupport