// Core Imports
import React from 'react';

// Custom Component Imports
import Breadcrumb from './Breadcrumb';
import Footer from './Footer';


// Function Imports
import useScript from '../utils/customHooks';

// TODO: Explanation
// TODO: title and description params are not being used
const Layout = ({
    currentPage = "Current", 
    className,
    children,
    showBreadcrumb = false,
}) => {
    //console.log("🚀 ~ file: Layout.js:20 ~ currentPage", currentPage)

    useScript(window.location.origin+'/js/main.js');

    // Main Render
    return (
        <>
            {showBreadcrumb && <Breadcrumb currentPage={currentPage} />}
            <div className={className}>
                <div className="body__overlay"></div>
                {children}
            </div>
            <Footer/>
        </>  
    );
};

export default Layout;