import React, { useMemo } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { theme } from './'
import { useTranslation } from 'react-i18next'
import { esES, enUS } from '@mui/x-data-grid'


const allLocales= {
  en: enUS,
  es: esES
}

export const AppTheme = ({ children }) => {
  const { i18n:{language} } = useTranslation()  

  const themeWithLocale =useMemo(
    () => {
      const local = allLocales[language] ?? allLocales['en']
      return {...theme, ...local}
    },
    [language, theme],
  );
  return (
    <ThemeProvider theme={themeWithLocale}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
