import React from 'react'
import { Avatar } from '@mui/material'

export const AvatarLetter = ({ name = 'User', styles, url = null }) => {
  const stringToColor = (string) => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  const stringAvatar = () => {
    const nameSplit = name.split(' ')

    if (nameSplit.length == 1) return nameSplit[0]
    if (nameSplit.length == 2) return `${nameSplit[0][0]} ${nameSplit[1][0]}`
    if (nameSplit.length == 4) return `${nameSplit[0][0]} ${nameSplit[2][0]}`
  }

  return (
    <>
      <Avatar
        variant='rounded'
        alt={`avatar de ${name}`}
        sx={{ bgcolor: url == null && stringToColor(name), ...styles }}
        src={url}
      >
        {url !== null && stringAvatar()}
      </Avatar>
    </>
  )
}
