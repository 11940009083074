// Core Imports
import React, { useState, useEffect, Fragment } from 'react';
import DataTable from 'react-data-table-component';
import QRCode from 'react-qr-code';
import { useHistory } from "react-router-dom";

// Custom Component Imports

import Alert from '../common/Alert'

// Selector Imports
import { isAuthenticated, signout } from '../auth/functions';

// API Imports
import { getBriefcase, getNewAddressDeposit } from '../services/APICalls';
import { useTranslation } from 'react-i18next';

const styles = {
    button: {
        backgroundColor: "#8f8f8f",
        border: 0,
        borderRadius: 4,
        color: "#fff",
        padding: "5px 10px",
        fontSize: 20
    }
};


// TODO: Explanation
const ReceiveCryptoDataTable = ({ userInput }) => {
    let history = useHistory();
    const { t } = useTranslation()
    console.log('INSIDE userInput: ', userInput)
    // Init state
    const [briefcase, setBriefcase] = useState(0);
    const [selectedBriefcase, setSelectedBriefcase] = useState('');
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState('');
    const [dataTableError, setDataTableError] = useState(false);
    const [dataTableSuccess, setDataTableSuccess] = useState(false);

    const [userSelected, setUserSelected] = useState(userInput);

    const { user, token } = isAuthenticated();

    const goToLogin = () => {
        localStorage.removeItem('currentUser')
        signout(() => history.push('/user/auth'))
    }

    // Initial columns configuration
    const columns = [
        {
            name: t('receiveCrypto.crypto'),
            cell: row => <div><img style={{ width: '35px', height: '35px' }} src={window.location.origin + "/images/currencies/" + row.cripto_id + ".png"} alt="loading" />&nbsp;&nbsp;{row.acronym}</div>
        },
        {
            name: t('receiveCrypto.address'),
            selector: 'wallet',
            sortable: true
        },
        {
            name: t('receiveCrypto.actions'),
            cell: row => actionsBtns(row),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ];

    // React Effects -------------------------------------------------------------------------------


    useEffect(() => {


        if (briefcase) {
            const items = briefcase.filter(item => item.name && item.name.toUpperCase().includes(filterText.toUpperCase()));
            setFilteredItems(items);
        }

    }, [filterText, briefcase]);

    useEffect(() => {
        if (userInput) {
            loadBriefcase(userInput);
        }
        else {
            setUserSelected(user.id);
        }
    }, [userInput]);

    // END React Effects ---------------------------------------------------------------------------

    // Methods -------------------------------------------------------------------------------------

    const loadBriefcase = (userInput) => {
        getBriefcase(userInput, token).then(data => {
            if (data.status == 401) {
                goToLogin();
            }
            if (data.status >= 400 && data.status <= 599) {
                //TODO
            }
            else {
                setBriefcase(data.data);
            }
        });
    }

    const copiarAlPortapapeles = (id_elemento) => {
        console.log('VALOR DE LA WALLET', id_elemento);
        var aux = document.createElement("input");
        aux.setAttribute("value", document.getElementById(id_elemento).innerHTML);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    }

    // END Methods ---------------------------------------------------------------------------------

    // Events --------------------------------------------------------------------------------------


    // END Events ----------------------------------------------------------------------------------

    // Render Methods ------------------------------------------------------------------------------

    const actionsBtns = row => (
        <>
            <button
                type="button"
                title={t('receiveCrypto.copyToClipboard')}
                style={styles.button}
                onClick={() => { navigator.clipboard.writeText(row.wallet) }}
            >
                <span className="glyphicon glyphicon-book" aria-hidden="true"></span>
            </button>
            &nbsp;
            <button
                type="button"
                title={t('receiveCrypto.qrCode')}
                style={styles.button}
                data-toggle="modal"
                data-target="#qrModal"
                aria-label="qr"
                onClick={() => {
                    if (row.active == false) {
                        getNewAddressDeposit({ cripto_id: row.cripto_id }, user.id).then(data => {
                            console.log('data New Address::::', data);
                            if (data.status == 401) {
                                goToLogin();
                            }
                            if (data.status >= 400 && data.status <= 599) {
                                //TODO
                            }
                            else {
                                let wallet = data.message;
                                row.wallet = wallet;
                                loadBriefcase(user.id);
                                setSelectedBriefcase(row);
                            }
                        });
                    }
                    else {
                        console.log('selected row:///{{}}}', row)
                        setSelectedBriefcase(row);
                    }
                }}>
                <span className="glyphicon glyphicon-qrcode" aria-hidden="true"></span>
            </button>
        </>
    );

    const modalDeleteConfirm = () => {
        return (
            <div className="modal fade" id="deleteModal" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title" id="myModalLabel">Confirmar Borrado</h4>
                        </div>
                        <div className="modal-body">
                            {selectedBriefcase && `Desea eliminar la wallet ${selectedBriefcase.name}?`}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cancelar</button>
                            <button
                                type="button"
                                className="btn btn-dark"
                                data-dismiss="modal"
                            >
                                Eliminar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const modalQrView = () => {
        return (
            <div className="modal fade" id="qrModal" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title modal-body-center" id="myModalLabel">Direccion de Deposito {selectedBriefcase.name ? ` - Código QR  ${selectedBriefcase.name}` : ""}</h4>
                        </div>
                        <div className="modal-body modal-body-center">
                            <QRCode
                                title={selectedBriefcase.name ? `Código QR de  ${selectedBriefcase.name}` : ""}
                                value={selectedBriefcase.wallet ? selectedBriefcase.wallet : ""}
                                bgColor={"white"}
                                fgColor={"black"}
                                size={150}
                            />

                        </div>
                        <div className="modal-body modal-body-center">
                            {selectedBriefcase.wallet ? selectedBriefcase.wallet : ""}
                        </div>
                        {selectedBriefcase.memo &&
                            <div>
                                <div className="modal-body modal-body-center">
                                    Memo/Tag: {selectedBriefcase.memo}
                                    <br></br>
                                </div>
                                <div className="modal-body modal-body-center">

                                    <br></br>
                                    <b>El tag es obligatorio para poder recibir dinero en nuestra plataforma</b>
                                </div>
                            </div>
                        }
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" data-dismiss="modal">Cerrar</button>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // END Render Methods --------------------------------------------------------------------------

    // Main Render
    return (
        <Fragment>
            {modalDeleteConfirm()}
            {modalQrView()}
            <div className="row">
                <Alert
                    error={dataTableError}
                    success={dataTableSuccess}
                    onError={(value) => { setDataTableError(value) }}
                    onSuccess={(value) => { setDataTableSuccess(value) }}
                />
            </div>
            <DataTable
                columns={columns}
                data={filteredItems}
                defaultSortField="name"
                pagination
                highlightOnHover
                pointerOnHover
                subHeader
            />
        </Fragment>
    );
}

export default ReceiveCryptoDataTable;