import React from 'react'
import { Controller } from 'react-hook-form'
import MuiPhoneNumber from 'material-ui-phone-number-2'

const PhoneNumberForm = ({
  name, // Nombre del campo
  label = null, // label del campo
  variant = 'outlined', // Variante de la caja de texto
  className = {}, // estilos para la caja de el input
  control, // control proveniente de react-hook-form
  validations = {}, // Objeto con las validaciones
  disabled = false, // Para deshabilitar el componente
  fullWidth = false, // Si ocupa o no toda la caja donde se encuentra
  placeholder = '', // Placeholder
  inputProps = {}, // Propiedades del input como maxLenght
  InputProps = {}, // Propiedades del input de MUI
  required = false, // Marcar campo como requerido
  replace = val => val, // Regex aplicado al valor para evitar carga de datos incorrecta
  defaultValue = '', // No explico
  onClick = () => {}, // Evento cuando pulse la caja de texto
  type = 'text', // Tipo de la caja de texto
  onKeyDown = () => {}, // Funcion para cuando pulse una tecla,
  multiline = false,
  rows,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={validations}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => (
        <MuiPhoneNumber
          name={name}
          id={name}
          variant={variant}
          placeholder={placeholder}
          type={type}
          defaultCountry={'ve'}
          fullWidth={fullWidth}
          label={label}
          disabled={disabled}
          aria-describedby={name}
          onKeyDown={onKeyDown}
          multiline={multiline}
          rows={rows}
          {...field}
          helperText={fieldState?.errors?.message}
          error={fieldState?.errors?.message?.length > 0}
          {...props}
        />
      )}
    />
  )
}

export default PhoneNumberForm
