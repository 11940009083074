import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/system'

export const TermsAndConditionsModal = ({
  open,
  handleClose,
  tacText,
  saveTermsAndCondition = () => {},
  accepetText,
  denyText,
}) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle paddingTop={4}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box paddingX={4} dangerouslySetInnerHTML={{ __html: tacText }}></Box>
        </DialogContent>
        <DialogActions sx={{ mt: 2 }}>
          {denyText !== null && denyText !== undefined && denyText !== '' && (
            <Button variant="outlined" onClick={handleClose} sx={{ paddingY: 1.7, mr: 2 }}>
              {denyText}
            </Button>
          )}
          <Button variant="contained" onClick={saveTermsAndCondition} sx={{ paddingY: 1.7 }}>
            {accepetText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
