import React, { useState } from 'react'
import { ToastContext } from './toastContext'

export const ToastProvider = ({ children }) => {
  const [toastInfo, setToastInfo] = useState({
    msg: '',
    severity: 'success',
    isOpen: false,
    autoHideDuration: 6000,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  })

  const changeToastInfo = ({
    msg = '',
    severity = 'success',
    isOpen = true,
    autoHideDuration = 6000,
    anchorOrigin = { vertical: 'bottom', horizontal: 'center' },
  }) => {
    setToastInfo({ msg, severity, isOpen, autoHideDuration, anchorOrigin })
  }
  return <ToastContext.Provider value={{ toastInfo, changeToastInfo }}>{children}</ToastContext.Provider>
}
