import Config from '../config'
import { isAuthenticated } from '../auth/functions'
import { axiosInstance } from './AxiosInstance'
import axios from 'axios'
const { token } = isAuthenticated()

//MÓDULO DE GESTION DEL TOP DE CRIPTOMONEDAS Y CAPITALIZACIÓN DE MERCADOS

// LISTA EL TOP 10 DE LAS CRIPTOMONEDAS
export const getRanking = () => axiosInstance.get(`/currencies/coinranking`)

// MODULO DE MI PORTAFOLIO

// LISTA MI PORTAFOLIO
export const getBriefcase = user_id => axiosInstance.get(`/briefcase/${user_id}`)

/// LISTA MI PORTAFOLIO FROM P2P
export const getBriefcaseFromP2p = user_id => axiosInstance.get(`/briefcase/p2p/${user_id}`)

// MODULO DE ENVIOS DE CRIPTOMONEDAS

// REGISTRA EL ENVIO DE CRIPTOMONEDA
export const newSendCrypto = (userId, body) => axiosInstance.post(`/currencies/send/${userId}`, body)

// REGISTRA EL ENVIO DE CRIPTOMONEDA DESDE EXCHANGE HACIA P2P
export const newSendCryptoExchangeToP2p = (userId, body) => axiosInstance.post(`/currencies/goto/p2p/send/${userId}`, body)

// REGISTRA EL ENVIO DE CRIPTOMONEDA DESDE P2P HACIA EXCHANGE
export const newSendCryptoP2pToExchange = (userId, body) => axiosInstance.post(`/currencies/goto/exchange/send/${userId}`, body)


// MODULO DE COMPRA DE CRIPTOMONEDAS

// REGISTRA LA COMPRA DE UNA CRIPTOMONEDA
export const newBuy = (body, userId) => axiosInstance.post(`/currencies/buy/${userId}`, body)

//MODULO DE CONVERSION DE CRIPTOMONEDAS

//REGISTRA LA CONVERSION DE CRIPTOMONEDAS
export const convertCrypto = (userId, dataCurrencies) =>
  axiosInstance.post(`/currencies/convert/${userId}`, dataCurrencies)

// MODULO DE PASARELA DE PAGO

// LISTA EL TOKEN DE LA PASARELA DE PAGO
export const getBraintreeClientToken = () => axiosInstance.get(`/braintree/getToken`)

// REGISTRA EL PAGO POR LA PASARELA DE PAGO
export const processPayment = paymentData => axiosInstance.post(`/braintree/processPayment`, paymentData)

// MÓDULO DE GESTIÓN DE CRIPTOMONEDAS

// LISTA TODAS LAS CRIPTOMONEDAS
export const getCriptoCurrencyAPIList = () => axiosInstance.get(`/currencies/list`)

// LISTA UNA CRIPTOMONEDA
export const getCriptoCurrency = idCriptoCurrency => axiosInstance.get(`/currencies/${idCriptoCurrency}`)

// LISTA EL PRECIO DE UNA CRIPTOMONEDA
export const getCurrencyPrice = criptoSource => axiosInstance.get(`/currencies/price/${criptoSource}`)

// REGISTRA UNA CRIPTOMONEDA
export const createCriptoCurrency = newCriptoCurrency => axiosInstance.post(`/currencies`, newCriptoCurrency)


// ACTUALIZA UNA CRIPTOMONEDA
export const updateCriptoCurrency = (idCriptoCurrency, CriptoCurrency) => axiosInstance.put(`/currencies/${idCriptoCurrency}`, CriptoCurrency)

// ELIMINA UNA CRIPTOMONEDA
export const deleteCriptoCurrency = idCriptoCurrency => axiosInstance.delete(`/currencies/${idCriptoCurrency}`)


// VERIFICA UNA CRIPTOMONEDA POR TIPO Y DIRECCION
export const getCurrencyAddressVerify = (cripto_id, addresss) => axiosInstance.get(`/currencies/${cripto_id}/addresses/${addresss}`)


// MÓDULO DE GESTION DE DIRECCIONES DE CONFIANZA

// LISTA TODAS LAS DIRECCIONES DE CONFIANZA
export const getWListAPIList = () => axiosInstance.get(`/wlist`)


// LISTA UNA DIRECCIÓN DE CONFIANZA
export const getWList = idWList => axiosInstance.get(`/wlist/${idWList}`)


//LISTA TODAS LAS DIRECCIONES DE CONFIANZA POR USUARIO
export const getWListUsers = idUser => axiosInstance.get(`/wlist/users/${idUser}`)

// LISTA LA DIRECCIÓN DE CONFIANZA POR CORREO Y CRIPTOMONEDA (ENVIO RAPIDO)
export const getWListByEmail = (email, crypto_id) => axiosInstance.get(`/wlist/users/emails/${email}/currencies/${crypto_id}`)

// LISTA UNA DIRECCION DE CONFIANZA POR USUARIO, CRIPTOMONEDA Y DIRECCIÓN
export const getAddressByUserAndCrypto = (userId, criptoSource, address) => axiosInstance.get(`/wlist2/users/${userId}/address/${address}/currencies/${criptoSource}`)

//REGISTRA UNA DIRECCION DE CONFIANZA
export const createWList = newWList => axiosInstance.post(`/wlist/`, newWList)

// REGISTRA UNA DIRECCIÓN DE CONFIANZA USANDO DIRECCION, CRIPTOMONEDA Y USUARIO
export const createNewAddressInWlist = async (address, crypto_id, user_id, tokenacces, memo) => {
  ///let body = JSON.stringify({
  // address, crypto_id, user_id
  //});
  //return axiosInstance.post(`/wlist`, body)

  return await fetch(`${Config.API}/wlist`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': tokenacces,
    },
    body: JSON.stringify({
      address,
      crypto_id,
      user_id,
      memo
    }),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.log(error)
    })
}

// ELIMINA UNA DIRECCIÓN DE CONFIANZA
export const deleteWList = idWList => axiosInstance.delete(`/wlist/${idWList}`)

// MODULO DE VERIFICACION DE DIRECCION DE CONFIANZA

// REGISTRA LA VERIFICACION DE LA DIRECCION DE CONFIANZA CON DIRECCION Y SMS
export const smsVerifyAddressInWlist = (code, address, tokenacces, user_id, crypto_id, added_id, nickname) => {
  //return axiosInstance.post(`/wlist/sms/verify`, body)

  return fetch(`${Config.API}/wlist/sms/verify`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code,
      address,
      user_id,
      crypto_id,
      added_id,
      nickname
    }),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.log(error)
    })
}

// REGISTRA LA VERIFICACION DE LA DIRECCION DE CONFIANZA POR CORREO
export const wlistCheckMailId = body => axiosInstance.post(`/wlist/email/verify`, body)

// REGISTRA LA VERIFICACIÓN DE CONFIANZA CON DIRECCION, TELEFONO Y CORREO
export const requestVerifyAddressWlist = async (address, phoneNumber, email, tokenacces) => {
  //return axiosInstance.post(`/wlist/address/request`, body)

  return await fetch(`${Config.API}/wlist/address/request`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': tokenacces,
    },
    body: JSON.stringify({
      address,
      phoneNumber,
      email,
    }),
  })
    .then(response => {
      return response.json()
    })
    .catch(error => {
      console.log(error)
    })
}

// MÓDULO DE TRANSACCIONES

// LISTA LAS TRANSACCIONES POR USUARIO
export const getTransactionsByUserId = userId => axiosInstance.get(`/transactions/users/${userId}`)

// LISTA LAS TRANSACCIONES POR USUARIO, ORDEN Y CRIPTOMONEDA
export const getTransactionsByUserIdAndOrderId = (userId, order_id, cripto_id) => axiosInstance.get(`/transactions/users/${userId}/orders/${order_id}/currencies/${cripto_id}`)

// MÓDULO DE ORDENES

// LISTA LAS ORDENES POR USUARIO
export const getOrdersByUserId = userId => axiosInstance.get(`/orders/users/${userId}`)

// LISTA LAS ORDENES POR USUARIO Y CRIPTOMONEDA
export const getOrdersByUserIdAndCriptoId = (userId, cripto_id) => axiosInstance.get(`/orders/users/${userId}/currencies/${cripto_id}`)

//MÓDULO DE GESTION DE MEMOS

// LISTA EL MEMO POR USUARIO, CRIPTOMONEDA, DIRECCIÓN Y MEMO
export const getMemoByUserAndCrypto = (userId, criptoSource, address, memo) => axiosInstance.get(`/wlist2/users/${userId}/address/${address}/memo/${memo}/currencies/${criptoSource}`)



//MODULO DE GESTION DE USUARIO

// LISTA EL BALANCE DE CUENTA DE UN USUARIO
export const getBalanceAccount = async (userId, criptoSource) => await axiosInstance.get(`/currencies/${userId}/currency/${criptoSource}`)

// LISTA EL BALANCE DE UN USUARIO Y CRIPTOMONEDA EN EXCHANGR
export const getBalanceAccountOnlyCrypto = (userId, criptoSource) => axiosInstance.get(`/currencies/${userId}/onlycurrency/${criptoSource}`)

// DEVUELVE BALANCE DE UN USUARIO Y CRIPTOMONEDA EN P2P
export const getBalanceAccountOnlyCryptoP2p = (userId, criptoSource) => axiosInstance.get(`/currencies/${userId}/onlycurrency/p2p/${criptoSource}`)

// LISTA LA DIRECCIÓN DE DEPOSITO POR USUARIO
export const getNewAddressDeposit = (body, userId) => axiosInstance.post(`/currencies/getdepositaddress/users/${userId}`, body)



// LISTA TODOS LOS USUARIOS Y SUS PERFILES
export const getUsersAPIList = () => axiosInstance.get(`/userprofiles`)

// LISTA UN USUARIO Y SU PERFIL
export const getUserProfile = userId => axiosInstance.get(`/userprofiles/user/${userId}`)


// LISTA UN USUARIO
export const getUser = userId => axiosInstance.get(`/users/${userId}`)


// REGISTRA UN USUARIO
export const createUser = newUser => axiosInstance.post(`/users/`, newUser)


// ACTUALIZA UN USUARIO
// revisar
export const updateUser = (userId, token, user) => axiosInstance.put(`/users/${userId}`, user)

// ELIMINA UN USUARIO
export const deleteUser = idUser => axiosInstance.delete(`users/${idUser}`)


// VERIFICA EL CORREO DEL USUARIO
// revisar
export const checkMailId = id => axiosInstance.post(`/users/checkmail/${id}`)



// CARGA DE IMAGEN DE USUARIO
export const uploadFile = (file, user_id) => axiosInstance.post(`/upload/${user_id}`, file)


// CARGA DE IMAGEN USANDO LA CAMARA
//revisar
export const uploadSelfie = (imageSrc, user_id) => {
  const data = imageSrc.toString().replace(/^data:image\/jpeg;base64,/, '')
  return axiosInstance.post(`/upload/webcam/${user_id}`, { base64: data })
}

// MODULO DE GESTION DE PERFILES

export const getProfilesAPIList = () => axiosInstance.get(`/profiles`)

export const getProfilesId = id => axiosInstance.get(`/profiles/${id}`)

export const createProfiles = newProfile => axiosInstance.post(`/profiles`, newProfile)

export const updateProfiles = (id, Profile) => axiosInstance.put(`/profiles/${id}`, Profile)

export const deleteProfiles = idProfile => axiosInstance.delete(`/profiles/${idProfile}`)

// MÓDULO DE GESTION DE PAISES

// LISTA TODOS LOS PAISES
export const getCountries = async () => await axiosInstance.get(`/countries`)

// REGISTRA UN PAIS
export const saveCountry = country => axiosInstance.post(`/countries`, country)


// ACTUALIZA UN PAIS
export const updateCountry = country => axiosInstance.put(`/countries`, { country })


// ELIMINA UN PAIS
export const deleteCountry = country_id => axiosInstance.delete(`/countries/${country_id}`)


// MÓDULO DE GESTION DE ESTADOS/PROVINCIAS

// LISTA TODOS LOS ESTADOS/PROVINCIAS
export const getStates = async () => await axiosInstance.get(`/states`)


// LISTA LOS ESTADOS/PROVINCIAS POR PAIS
export const getStatesCountryID = async countryId => await axiosInstance.get(`/states/${countryId}`)


// REGISTRA UN ESTADO/PROVINCIA
export const saveState = state => axiosInstance.post(`/states`, state)


// ACTUALIZA UN ESTADO/PROVINCIA
export const updateState = (country_id, state_id, state) => axiosInstance.put(`/states/${country_id}/${state_id}`, state)

// ELIMINA UN ESTADO/PROVINCIA
export const deleteState = (country_id, state_id) => axiosInstance.delete(`/states/${country_id}/${state_id}`)


// MODULO DE GESTION DE LISTA NEGRA

// LISTA TODOS LOS REGISTROS DE LA LISTA NEGRA
export const getBListAPIList = () => axiosInstance.get(`/blist`)


// LISTA UNA LISTA NEGRA
export const getBList = idBlackWhite => axiosInstance.get(`/blist/id/${idBlackWhite}`)


// REGISTRA UNA LISTA NEGRA
export const createBList = newBlackWhite => axiosInstance.post(`/blist/`, newBlackWhite)


// ACTUALIZA UNA LISTA NEGRA
export const updateBList = BlackWhite => axiosInstance.put(`/blist`, BlackWhite)


// ELIMINA UNA LISTA NEGRA
export const deleteBList = idBlackWhite => axiosInstance.delete(`/blist/${idBlackWhite}`)


// MÓDULO DE GESTION DE CAPACIDADES

// LISTA TODAS LAS CAPACIDADES
export const getCapabilitiesAPIList = () => axiosInstance.get(`/capabilities`)


// LISTA UNA CAPACIDAD
export const getCapabilities = idCapabilitie => axiosInstance.get(`/capabilities/${idCapabilitie}`)


// REGISTRA UNA CAPACIDAD
export const createCapabilities = newCapabilities => axiosInstance.post(`/capabilities/`, newCapabilities)


// ACTUALIZA UNA CAPACIDAD
export const updateCapabilities = (idcapability, capability) => axiosInstance.put(`/capabilities/${idcapability}`, capability)


// ELIMINA UNA CAPACIDAD
export const deleteCapabilities = idCapabilities => axiosInstance.delete(`/capabilities/${idCapabilities}`)


// MODULO DE GESTION DE ADVERTENCIAS

// LISTA TODAS LAS ADVERTENCIAS
export const getCaveatsAPIList = () => axiosInstance.get(`/caveats`)


// LISTA UNA ADVERTENCIA
export const getCaveat = idCaveat => axiosInstance.get(`/caveats/${idCaveat}`)


// REGISTRA UNA ADVERTENCIA
export const createCaveat = newCaveat => axiosInstance.post(`/caveats/`, newCaveat)


// ACTUALIZA UNA ADVERTENCIA
export const updateCaveat = (caveatId, caveat) => axiosInstance.put(`/caveats/${caveatId}`, caveat)


// ELIMINA UNA ADVERTENCIA
export const deleteCaveat = idCaveat => axiosInstance.delete(`/caveats/${idCaveat}`)


// MODULO DE TÉRMINOS LEGALES

// LISTA TODOS LOS TÉRMINOS LEGALES
export const getLegalTerms = () => axiosInstance.get(`/legal`)


export const getLegalTerm = idLegalTerm => axiosInstance.get(`/legal/${idLegalTerm}`)


// REGISTRA UN TERMINO LEGAL
export const createLegalTerm = newLegalTerm => axiosInstance.post(`/legal/`, newLegalTerm)


// ACTUALIZA UN TERMINO LEGAL
export const updateLegalTerm = (legalTermId, LegalTerm) => axiosInstance.put(`/legal/${legalTermId}`, LegalTerm)


// ELIMINA UN TERMINO LEGAL
export const deleteLegalTerm = idLegalTerm => axiosInstance.delete(`/legal/${idLegalTerm}`)


//MÓDULO DE GESTION DE MODULOS

// LISTA TODOS LOS MODULOS
export const getModulesAPIList = () => axiosInstance.get(`/modules`)


//DETALLE UN MODULO
export const getModule = idModule => axiosInstance.get(`/modules/${idModule}`)


// REGISTRA UN MODULO
export const createModule = newModule => axiosInstance.post(`/modules/`, newModule)


// ACTUALIZA UN MODULO
export const updateModule = (idModule, module) => axiosInstance.put(`/modules/${idModule}`, module)


//ELIMINAR UN MODULO
export const deleteModule = idModule => axiosInstance.delete(`/modules/${idModule}`)


//MODULO DE GESTION DE NOTIFICACIONES

// LISTA TODAS LAS NOTIFICACIONES
export const getNotificationsAPIList = () => axiosInstance.get(`/notifications`)


// DETALLE DE UNA NOTIFICACION
export const getNotification = idNotification => axiosInstance.get(`/notifications/${idNotification}`)


// REGISTRA UNA NOTIFICACION
export const createNotification = newNotification => axiosInstance.post(`/notifications/`, newNotification)


// ACTUALIZA UNA NOTIFICACION
export const updateNotification = (notificationId, notification) => axiosInstance.put(`/notifications/${notificationId}`, notification)


// ELIMINA UNA NOTIFICACION
export const deleteNotification = idNotification => axiosInstance.delete(`/notifications/${idNotification}`)

//TYPE NOTIFICATIONS

export const getTypeNotifications = () => axiosInstance.get(`t_notifications`)


export const getTypeNotification = idTypeNotification => axiosInstance.get(`/t_notifications/${idTypeNotification}`)


export const createTypeNotification = newTypeNotification => axiosInstance.post(`/t_notifications/`, newTypeNotification)


export const updateTypeNotification = (idTypeNotification, TypeNotification) => axiosInstance.put(`/t_notifications/${idTypeNotification}`, TypeNotification)


export const deleteTypeNotification = idTypeNotification => axiosInstance.delete(`/t_notifications/${idTypeNotification}`)


// TIPO DE EVENTOS

export const getTypeEvents = () => axiosInstance.get(`/events`)


export const getTypeEvent = idTypeEvents => axiosInstance.get(`/events/${idTypeEvents}`)


export const createTypeEvent = newTypeEvents => axiosInstance.post(`/events/`, newTypeEvents)


export const updateTypeEvent = (eventsTypeId, TypeEvents) => axiosInstance.put(`/events/${eventsTypeId}`, TypeEvents)


export const deleteTypeEvent = idTypeEvents => axiosInstance.delete(`/events/${idTypeEvents}`)


// MODULO TIME TIMEOUT ELAPSED

// LISTA TODOS LOS TIMEOUTS
export const getTimeouts = () => axiosInstance.get(`/times`)

// ELIMINA TIMEOUT POR ID
export const deleteTimeouts = idTime => axiosInstance.delete(`/times/${idTime}`)

// MÓDULO DE GESTION DE IMPUESTOS Y COMISIONES

// LISTA TODOS LOS IMPUESTOS Y COMISIONES
export const getTaxesFeesList = () => axiosInstance.get(`/taxes`)


// LISTA UN IMPUESTO O COMISION
export const getTax = idTax => axiosInstance.get(`/taxes/${idTax}`)


// REGISTRA UN IMPUESTO O COMISIÓN
export const createTax = newTax => axiosInstance.post(`/taxes/`, newTax)

// ACTUALIZA UN IMPUESTO O COMISIÓN
export const updateTax = tax => axiosInstance.put(`/taxes`, tax)



// ELIMINA UN IMPUESTO O COMISIÓN
export const deleteTax = idTax => axiosInstance.delete(`/taxes/${idTax}`)

// MÓDULO DE GESTION DE TICKET DE SOPORTE

// LISTA TODOS LOS TICKET DE SOPORTE
export const getTechnicalSupportList = () => axiosInstance.get(`/modules`)


// LISTA UN TICKET DE SOPORTE
export const getTechnicalSupport = idSupport => axiosInstance.get(`/modules/${idSupport}`)

// REGISTRA UN NUEVO TICKET DE SOPORTE
export const createTechnicalSupport = newSupport => axiosInstance.post(`/support/`, newSupport)

// ACTUALIZA UN TICKET DE SOPORTE
export const updateTechnicalSupport = Support => axiosInstance.put(`/support`, Support)

// ELIMINA UN TICKET DE SOPORTE
export const deleteTechnicalSupport = idSupport => axiosInstance.delete(`/support/${idSupport}`)

// MÓDULO DE GESTIÓN DE INCIDENTES DE SOPORTE TÉCNICO

// LISTA TODOS LOS INCIDENTES DE SOPORTE
export const getIncidentsList = () => axiosInstance.get(`/modules`)

// LISTA UN INCIDENTE DE SOPORTE
export const getIncidentID = idIncident => axiosInstance.get(`/modules/${idIncident}`)

// REGISTRA UN NUEVO INCIDENTE DE SOPORTE
export const createIncident = newIncident => axiosInstance.post(`/incident/`, newIncident)

// ACTUALIZA UN INCIDENTE DE SOPORTE
export const updateIncident = Incident => axiosInstance.put(`/incident`, Incident)

// ELIMINA UN INCIDENTE DE SOPORTE
export const deleteIncident = idIncident => axiosInstance.delete(`/incident/${idIncident}`)

// MÓDULO DE GESTION DE CATEGORIAS DE INCIDENTES DE SOPORTE TÉCNICO

// LISTA TODAS LAS CATEGORÍAS DE INCIDENTES DE SOPORTE
export const getIncidentsCategoryList = () => axiosInstance.get(`/modules`)


// LISTA UNA CATEGORIA DE INCIDENTE DE SOPORTE
export const getIncidentCategoryID = idIncidentCategory => axiosInstance.get(`/modules/${idIncidentCategory}`)


// REGISTRA UN NUEVA CATEGORIA DE INCIDENTE DE SOPORTE
export const createIncidentCategory = newIncidentCategory => axiosInstance.post(`/incident_category/`, newIncidentCategory)


// ACTUALIZA UNA CATEGORIA DE INCIDENTE DE SOPORTE
export const updateIncidentCategory = IncidentCategory => axiosInstance.put(`/incident_category`, IncidentCategory)

// ELIMINA UNA CATEGORIA DE INCIDENTE DE SOPORTE
export const deleteIncidentCategory = idIncidentCategory => axiosInstance.delete(`/incident_category/${idIncidentCategory}`)

// MÓDULO DE GESTION DE AREA RESOLUTORIAS DE SOPORTE TECNICO

// LISTA TODAS LAS AREA RESOLUTORIAS DE SOPORTE
export const getResolutionAreaAPIList = () => axiosInstance.get(`/modules`)

// LISTA UNA AREA RESOLUTORIA DE SOPORTE
export const getResolutionAreaID = idResolutionArea => axiosInstance.get(`/modules/${idResolutionArea}`)

// REGISTRA UNA AREA RESOLUTORIA DE SOPORTE
export const createResolutionArea = newResolutionArea => axiosInstance.post(`/incident/`, newResolutionArea)

// ACTUALIZA UNA AREA RESOLUTORIA DE SOPORTE
export const updateResolutionArea = ResolutionArea => axiosInstance.put(`/incident`, ResolutionArea)

// ELIMINA UNA AREA RESOLUTORIA DE SOPORTE
export const deleteResolutionArea = idResolutionArea => axiosInstance.delete(`/incident/${idResolutionArea}`)

export const faceMatching = async imagesToValidate => {
  console.log('@@@@@@@@@@@@@@@imagesToValidate:', imagesToValidate);
  /*const API_URL = `${Config.REACT_APP_FACE_MATCHING_URL}`
  let data = {
    face1: "https://i.imgur.com/bvT91HG.jpg",
    face2: "https://i.imgur.com/MMcxImK.jpg"
  }
  return axios.post(API_URL, data,
    { headers: { 'content-type': 'application/x-www-form-urlencoded' } }
  );*/
  const data = {
    x: 1,
    arr: [1, 2, 3],
    arr2: [1, [2], 3],
    users: [{name: 'Peter', surname: 'Griffin'}, {name: 'Thomas', surname: 'Anderson'}],
  };
  
  return await axios.post('https://postman-echo.com/post', data,
    {headers: {'content-type': 'application/x-www-form-urlencoded'}}
  );
}
