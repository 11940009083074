// Core Imports
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import millify from 'millify'
//import { useGetCryptosQuery } from '../services/cryptoApi';
import { getRanking } from '../services/APICalls'
////From the Dashboard template
import Accessibility from '@material-ui/icons/Accessibility'
import DateRange from '@material-ui/icons/DateRange'
import LocalOffer from '@material-ui/icons/LocalOffer'
import Update from '@material-ui/icons/Update'
import Store from '@material-ui/icons/Store'
import GridItem from '../components/Grid/GridItem.js'
import GridContainer from '../components/Grid/GridContainer.js'
import Card from '../components/Card/Card.js'
import CardHeader from '../components/Card/CardHeader.js'
import CardIcon from '../components/Card/CardIcon.js'
import 'react-tabs/style/react-tabs.css'
import Loader from './Loader'
import styles from '../assets/jss/material-dashboard-react/views/dashboardStyle.js'
import { useTranslation } from 'react-i18next'
const useStyles = makeStyles(styles)

// TODO: Explanation
const CryptomarketSection = () => {
  const classes = useStyles()
  const [globalStats, setGlobalStats] = useState([])
  const { t } = useTranslation()

  useEffect( () => {
    let isMounted = true

      getRanking().then((data) =>{
        if (isMounted) setGlobalStats(data?.dataValue?.data?.stats)
      })
    
    return () => { isMounted = false }
  }, [])

  // Main Render
  //agregar return
  return (
    <>
      <div className="bg__white ptb--90">
        <section className="htc__product__area shop__page bg__white">
          <div className="container">
            <div className="htc__product__container">
              <div className="row product__list another-product-style">
                <GridContainer>
                  <GridItem xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader color="warning" stats icon>
                        <CardIcon color="warning">
                          <Store />
                        </CardIcon>
                        <p className={classes?.cardCategory}>{ t('markets.totalCryptocurrencies')}</p>
                        <h3 className={classes?.cardTitle}>
                          <p>{globalStats?.total}</p>
                        </h3>
                      </CardHeader>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader color="success" stats icon>
                        <CardIcon color="success">
                          <Update />
                        </CardIcon>
                        <p className={classes?.cardCategory}>{ t('markets.totalExchanges')}</p>
                        <h3 className={classes?.cardTitle}>
                          {/* <p>{millify(globalStats?.totalExchanges)}</p> */}
                        </h3>
                      </CardHeader>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader color="danger" stats icon>
                        <CardIcon color="danger">
                          <LocalOffer />
                        </CardIcon>
                        <p className={classes?.cardCategory}>{ t('markets.totalMarketCap')}</p>
                        <h3 className={classes?.cardTitle}>
                          {/* <p>{millify(globalStats?.totalMarketCap)}</p> */}
                        </h3>
                      </CardHeader>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader color="info" stats icon>
                        <CardIcon color="info">
                          <DateRange />
                        </CardIcon>
                        <p className={classes?.cardCategory}>{ t('markets.totalVolume')}</p>
                        <h3 className={classes?.cardTitle}>
                          {/* <p>{millify(globalStats?.total24hVolume)}</p> */}
                        </h3>
                      </CardHeader>
                    </Card>
                  </GridItem>

                  <GridItem xs={12} sm={6} md={3}>
                    <Card>
                      <CardHeader color="primary" stats icon>
                        <CardIcon color="primary">
                          <Accessibility />
                        </CardIcon>
                        <p className={classes?.cardCategory}>{ t('markets.totalMarkets')}</p>
                        <h3 className={classes?.cardTitle}>
                          {/* <p>{millify(globalStats?.totalMarkets)}</p> */}
                        </h3>
                      </CardHeader>
                    </Card>
                  </GridItem>
                </GridContainer>
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default CryptomarketSection
