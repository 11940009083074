import React, { useState } from 'react';
import Webcam from "react-webcam";


const WebcamComponent = () => <Webcam />;

const videoConstraints = {
    width: 220,
    height: 200,
    facingMode: "user"
};

export const WebcamCapture = ({ update, userId }) => {

    const [image, setImage] = useState('');
    const webcamRef = React.useRef(null);


    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            //console.log('imageSrc.toString(): ', imageSrc.toString())
            var data = imageSrc.toString().replace(/^data:image\/jpeg;base64,/, "");
            //console.log('data to string sin image/jpeg: ', data)
            //let buf = Buffer.from(data, 'base64');
            //const buf_img = JSON.stringify(buf);


            //setImage(imageSrc);
            update(imageSrc);
            //console.log('userId: ', userId);
            //console.log('JSON.stringify({ base64:buf }): ',JSON.stringify({ base64:buf }));
            
            /*return fetch(`http://localhost:5000/api/upload/webcam/${userId}`,
                {
                    method: 'POST',
                    headers:
                    {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ base64: data })
                })
                .then(response => {
                    console.log('RESPONSE:::',response);
                    updateForUrl(response.json())
                    return response.json();
                })
                .catch(err => {
                    console.log(err);
                });*/

        });


    return (
        <div className="overlay">
            <div className="webcam-img">

                {image == '' ? <Webcam
                    audio={false}
                    height={200}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={220}
                    videoConstraints={videoConstraints}
                /> : <img src={image} />}
            </div>
            <div>
                {image != '' ?
                    <button onClick={(e) => {
                        e.preventDefault();
                        setImage('')
                    }}
                        className="btn btn-dark">
                        <i className="zmdi zmdi-refresh zmdi-hc-2x"></i>
                    </button> :
                    <button onClick={(e) => {
                        e.preventDefault();
                        capture();
                    }}
                        className="btn btn-dark">
                        <i className="zmdi zmdi-floppy zmdi-hc-2x"></i>
                    </button>
                }
            </div>
        </div>
    );
};
