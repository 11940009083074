import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'
import Layout from '../../core/Layout'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useForm } from 'react-hook-form'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberStringSigns } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'

// API Impports
import { getTypeEvent, createTypeEvent, updateTypeEvent } from '../../services/APICalls'

const initValues = {
  id: '',
  name: '',
  description: '',
  status: '',
}

const FormTypeEvents = () => {
  // Initial State
  const dispatch = useDispatch()
  const history = useHistory()
  //params que vienen por la url
  const paramsTypeEventForm = useParams({})
  //valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  //manejo de formulario
  const {
    handleSubmit,
    register,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm(defaultValues)

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const getDetailsTypeEvent = () => {
    dispatch(showSpinner('Consultando Lista de Tipos de Eventos'))
    getTypeEvent(paramsTypeEventForm?.idTypeEvent).then(data => {
      if (data?.status >= 200 && data?.status <= 299) {
        const newTypeEvent = {
          id: data.data[0].id,
          name: data.data[0].name,
          description: data.data[0].description,
          active: data.data[0].active,
        }
        setDefaultValues(newTypeEvent)
      } else {
        if (data?.status == 401){
          goToLogin()
          return
        }
        if (data?.status >= 400 || data?.status <= 599) {
          //TODO
          //useEffect(() => {
          //  changeToastInfo({ msg: data.message, severity: 'info' })
          //}, [])
        }
      }
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {
      dispatch(hiddeSpinner())
    })
}

    /*
  const getDetailsTypeEvent = async () => {
    dispatch(showSpinner('Obteniendo Información de Tipo de Evento'))
    try {
      const { 0: type_event } = await getTypeEvent(paramsTypeEventForm?.idTypeEvent)
      const newTypeEvent = {
        id: type_event.id,
        name: type_event.name,
        description: type_event.description,
        active: type_event.active,
      }
      setDefaultValues(newTypeEvent)
      dispatch(hiddeSpinner())
    } catch (error) {      
      console.log(error)
      dispatch(hiddeSpinner())
    }
  }
*/
  
const onSubmitForm = type_event => {
    const updaterTypeEvent = {
      id: type_event.id,
      name: type_event.name,
      description: type_event.description,
      active: type_event.active,
    }
    if (paramsTypeEventForm?.idTypeEvent !== undefined) {
      SendUpdateTypeEvent(updaterTypeEvent)
      goToTypeEventList()
      return
    }
    sendCreateTypeEvent(updaterTypeEvent)
    goToTypeEventList()
    return
  }

  const SendUpdateTypeEvent = async type_event => {
    dispatch(showSpinner('Modificando Información de Tipo de Evento'))
    try {
      await updateTypeEvent(type_event.id, type_event)
      dispatch(hiddeSpinner())
      return
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const sendCreateTypeEvent = async type_event => {
    dispatch(showSpinner('Registrando Información de Tipo de Evento'))
    try {
      await createTypeEvent(type_event)
      dispatch(hiddeSpinner())
    } catch (error) {
      console.error(error)
      dispatch(hiddeSpinner())
    }
  }

  const goToTypeEventList = () => {
    history.push('/admin/type_events')
  }

  useEffect(() => {
    //en caso de que exista un id, se pone en modo editar
    if (paramsTypeEventForm?.idTypeEvent !== undefined) {
      getDetailsTypeEvent()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsTypeEventForm?.idTypeEvent !== undefined ? 'EDICION' : 'REGISTRO'} DE TIPO DE EVENTOS`}
      description={`${
        paramsTypeEventForm?.idTypeEvent !== undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de tipo de eventos`}
      showBreadcrumb={true}
      currentPage={`${paramsTypeEventForm?.idTypeEvent !== undefined ? 'EDICION' : 'REGISTRO'} DE TIPO DE EVENTOS`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToTypeEventList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('name') !== ''}
                {...register('name', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Nombre"
                type="text"
                variant="outlined"
                helperText={errors?.name?.message}
                error={errors?.name?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label="Estatus"
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      status: target.value,
                    })),
                  required: 'Este campo es requerido',
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required: 'Este campo es requerido',
                  pattern: {
                    value: validateOnlyNumberStringSigns(),
                    message: 'Solo permite caracteres válidos',
                  },
                })}
                label="Descripción"
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormTypeEvents