import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import { Button, Box, Grid, IconButton } from '@mui/material'

// Icons Imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// API Imports
import { getCapabilities, createCapabilities, updateCapabilities } from '../../services/APICalls'

// Custom Component Imports
import Layout from '../../core/Layout'
import SelectForm from '../../components/form/SelectForm'
import { validateOnlyNumberString } from '../../utils'
import { hiddeSpinner, showSpinner } from '../../store/slices/uiSlice'

//Login Imports
import { signout } from '../../auth/functions'
import { useTranslation } from 'react-i18next'

//Initial Values
const initValues = {
  id: '',
  description: '',
  active: '',
}

const FormCapabilities = () => {
  // Initial state
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  // URL Parameters
  const paramsCapabilitiesForm = useParams({})
  // Valores por defecto
  const [defaultValues, setDefaultValues] = useState(initValues)
  // Manejo de formulario
  const {
    handleSubmit, //manejador de evento sintetico de submit
    register, // registro de campos y validaciones
    reset, // reseteo de funciones iniciales
    watch, // observa el valor actual de un campo especifico
    getValues, // obtiene el valor de uno o todos los campos
    formState: { errors }, // estado del formulario y desestructuro los errores
  } = useForm(defaultValues) // inicializacion del formulario con los valores por defecto

  //funcion que va a traer los detalles
  const getDetailsCapabilities = async () => {
    dispatch(showSpinner('Obteniendo Información de Capacidad'))
    getCapabilities(paramsCapabilitiesForm?.idCapabilities)
      .then(data => {
        if (data?.status >= 200 && data?.status <= 299) {
          const newCapability = {
            id: data.data[0].id,
            description: data.data[0].description,
            active: data.data[0].active,
          }
          setDefaultValues(newCapability)
        } else {
          if (data?.status == 401) {
            goToLogin()
            return
          }
          if (data?.status >= 400 || data?.status <= 599) {
            //TODO
            //useEffect(() => {
            //  changeToastInfo({ msg: data.message, severity: 'info' })
            //}, [])
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        dispatch(hiddeSpinner())
      })
  }

  const goToLogin = () => {
    localStorage.removeItem('currentUser')
    signout(() => history.push('/user/auth'))
  }

  const onSubmitForm = capability => {
    if (paramsCapabilitiesForm?.idCapabilities !== undefined) {
      SendUpdateCapabilities(capability)
      goToCapabilitiesList()
      return
    }
    sendCreateCapabilities(capability)
    goToCapabilitiesList()
    return
  }

  const SendUpdateCapabilities = async capability => {
    try {
      await updateCapabilities(capability.id, capability)
      return
    } catch (error) {
      console.error(error)
    }
  }

  const sendCreateCapabilities = async capability => {
    try {
      await createCapabilities(capability)
    } catch (error) {
      console.error(error)
    }
  }

  const goToCapabilitiesList = () => {
    history.push('/admin/capabilities')
  }

  useEffect(() => {
    if (paramsCapabilitiesForm?.idCapabilities !== undefined) {
      getDetailsCapabilities()
    }
  }, [])

  useEffect(() => {
    // Si los valores por defecto cambian, es por se recibieron valores nuevos, reseteo los campos
    reset(defaultValues)
  }, [defaultValues])

  return (
    <Layout
      title={`${paramsCapabilitiesForm?.idCapabilities === undefined ? t('capability.capabilityRegisterForm.title') : t('capability.capabilityEditForm.title')}`}
      description={`${
        paramsCapabilitiesForm?.idCapabilities === undefined ? 'Formulario de Edición' : 'Formulario de Registro'
      } de capacidad`}
      showBreadcrumb={true}
      currentPage={`${paramsCapabilitiesForm?.idCapabilities === undefined ? t('capability.capabilityRegisterForm.title') : t('capability.capabilityEditForm.title')}`}>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <IconButton aria-label="volver" onClick={() => goToCapabilitiesList()}>
          <ArrowBackIcon />
        </IconButton>

        <form onSubmit={handleSubmit(onSubmitForm)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} lg={12} sx={{ mt: 2 }}>
              <SelectForm
                defaultValue={getValues('active')?.toString()}
                label={t('capability.form.statusInput.label')}
                name="active"
                options={[
                  { key: 'Activo', value: true },
                  { key: 'Inactivo', value: false },
                ]}
                register={register}
                validations={{
                  onChange: ({ target }) =>
                    setDefaultValues(() => ({
                      ...getValues(),
                      active: target.value,
                    })),
                  required:  t('capability.form.descriptionInput.required'),
                }}
                watch={watch}
                error={errors}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                focused={watch('description') !== ''}
                {...register('description', {
                  required:  t('capability.form.statusInput.required'),
                  pattern: {
                    value: validateOnlyNumberString(),
                    message:  t('capability.form.descriptionInput.patternMessage') ,
                  },
                })}
                label={t('capability.form.descriptionInput.label')}
                multiline
                rows={4}
                helperText={errors?.description?.message}
                error={errors?.description?.message?.length > 0}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} sx={{ mt: 2 }}>
              <Button variant="contained" fullWidth type="submit">
                {t('capability.form.saveButton')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Layout>
  )
}

export default FormCapabilities
