import { OneKPlusOutlined } from '@mui/icons-material';
import Config from '../config';
import { axiosInstance } from '../services/AxiosInstance';

//SIGNUP API REQUEST
export const signup = body => {
    return axiosInstance.post('/signup', body)
    
};

//SIGNIN API REQUEST
export const signin = body => {

    return axiosInstance.post('/signin', body)
};

//SET LOCALSTORAGE JWT
export const authenticate = (data, next) => {

    if (typeof window !== 'undefined')
    {
        if(!data.token)
        {
            data.token = JSON.parse(localStorage.getItem('jwt')).token;
        }
        localStorage.setItem('jwt', JSON.stringify(data));
        next();
    }
}

//GET LOCALSTORAGE
export const isAuthenticated = () => {

    if (typeof window == 'undefined')
    {
        return false;
    }

    if (localStorage.getItem('jwt'))
    {
        
        return JSON.parse(localStorage.getItem('jwt'));
    }
    else
    {
        return false;
    }
};

//UNSET LOCALSTORAGE JWT (LOGOUT)
export const signout = (next) => {

    if (typeof window !== 'undefined')
    {
        localStorage.removeItem('jwt');
        next();
    }
}